<template>
  <b-table
      ref="table"
      id="simple-table"
      :fields="fields"
      :items="items"
      :class="rowClickable ? 'clickable': ''"
      :per-page="customPagination ? 1000: perPage"
      :current-page="currentPage"
      class="simple-table oda-desktop-only"
      borderless
      sticky-header
      show-empty
      @row-clicked="rowClicked"
      @sort-changed="e => $emit('sortChanged', e)"
  >
    <template #head()="data">
      <div
          :class="{'sortable': data.field.sortable}"
          class="simple-table__header"
      >
        <oda-form-group
            v-if="data.field.searchable"
            :placeholder="data.label"
            @input="e => onFilter(e, data.field.key)"
        />
        <span v-else>{{ data.label }}</span>
      </div>
    </template>

    <template #head(id)="data">
      <div class="simple-table__header">
        <div
            v-if="pickable"
            :class="{'active': pickedItems.length === items.length}"
            class="simple-table__pick pickable"
            @click.stop="$emit('pickAll', data.value)"
        >
        </div>
        <div
            v-else
            class="pickable"
        >
          N
        </div>
      </div>
    </template>

    <template #cell(id)="data">
      <div
          class="simple-table__item"
      >
        <div
            v-if="pickable"
            :class="{'active': pickedItems.includes(data.item.group_id) || pickedItems.includes(data.item.id)}"
            class="simple-table__pick"
            @click.stop="$emit('pick', data.item)"
        >
        </div>
        <div v-else>
          {{ ++data.index }}
        </div>
      </div>
    </template>

    <template #cell()="data">
      <div
          :class="(data.field || {}).colored"
          class="simple-table__item"
      >
        <span
            v-if="data.field && data.field.icon"
            :class="data.field.icon"
            class="feather"
        ></span>
        <div>{{ data.value }}</div>
      </div>
    </template>

    // TODO: remove duplicate
    <template #cell(status)="data">
      <div class="simple-table__status">
        <div :class="getStatusClass(data.value)" class="oda-badge">{{ data.value }}</div>
      </div>
    </template>

    // TODO: >>> На рассмотрении || payment.status (?)
    <template #cell(label)="data">
      <div class="simple-table__status">
        <div :class="getStatusClass(data.value)" class="oda-badge">{{ data.value || data.item.rent && data.item.rent.label }}</div>
      </div>
    </template>

    <template #cell(reading_status)="data">
      <div class="simple-table__status">
        <div
            :class="getReadingStatusClass(data.value || (data.item.electricity || {}).reading_status)"
            class="oda-badge"
        >
          {{ getReadingStatus(data.value || (data.item.electricity || {}).reading_status) }}
        </div>
      </div>
    </template>

    <template #cell(created_at)="data">
      <div class="simple-table__item">
        <div>{{ getDateFormat(data.value) }}</div>
      </div>
    </template>

    <template #cell(period)="data">
      <div class="simple-table__item">
        <div>{{ getPaymentPeriod(data.item.start_dt || data.item.pdf_dt, data.item.end_dt) }}</div>
      </div>
    </template>

    <template #cell(contract_name)="data">
      <div class="simple-table__item primary">
        <span class="feather icon-file"></span>
        <div>{{ data.value }}</div>
      </div>
    </template>


    <template #cell(approved_by_employee)="data">
      <div
          :class="{'accepted': data.value}"
          class="simple-table__oda"
      >
        <span class="feather icon-check-square"></span>
        <div>{{ data.value ? 'Проверено': 'В ожидании проверки' }}</div>
      </div>
    </template>

    <template #cell(approved_by_builder)="data">
      <div class="simple-table__os">
        <oda-form-group
            v-model="data.value"
            :disabled="userRole === 'employe'"
            checkbox
            @input="approveOs(data.item)"
        />
      </div>
    </template>

    <template #cell(files_uploaded)="data">
      <div class="simple-table__os">
        <oda-form-group
            :value="hasUploadedDoc(data.item)"
            disabled
            checkbox
        />
      </div>
    </template>

    <template #cell(download_files)="data">
      <div
          :class="(data.field || {}).colored"
          class="simple-table__download"
      >
        <span class="feather icon-download">{{ data.value }}</span>
      </div>
    </template>

    <template #cell(actual_area_usage_act_pdf)="data">
      <div
          v-if="data.value"
          class="simple-table__pdf"
      >
        <a
            :href="data.value"
            class="feather icon-eye"
            target="_blank"
        ></a>
      </div>
      <div v-else class="simple-table__pdf">
        <span class="feather icon-eye"></span>
      </div>
    </template>

    <template #cell(ecp)="data">
      <div class="simple-table__status">
        <div
            :class="data.value ? 'success' : 'danger'"
            class="oda-badge"
        >
          {{ data.value ? 'Подписано' : 'Не подписано' }}
        </div>
      </div>
    </template>

    <template #cell(total_sum)="data">
      <div class="simple-table__item">
        {{ data.value ? prettyPrice(data.value) : prettyPrice(data.item.rent && data.item.rent.total_sum) }}
      </div>
    </template>

    <template #cell(bts_isesf)="data">
      <div class="simple-table__item">
        {{ data.item.awp.bts }}
      </div>
    </template>
    <template #cell(total_sum_isesf)="data">
      <div class="simple-table__item">
        {{ prettyPrice(data.item.awp.total_sum) }}
      </div>
    </template>
    <template #cell(counterparty_isesf)="data">
      <div class="simple-table__item">
        {{ data.item.awp.counterparty }}
      </div>
    </template>

    <template #cell(contract_code)="data">
      <div class="simple-table__item">
        {{ data.item.contract_code || data.item.rent.contract_code }}
      </div>
    </template>

    <template #cell(bts)="data">
      <div class="simple-table__item">
        {{ data.item.bts || data.item.rent && data.item.rent.bts }}
      </div>
    </template>

    <template #cell(average_for_period)="data">
      <div class="simple-table__item">
        {{ prettyPrice(getMediumForPeriod(data.item)) }}
      </div>
    </template>

    <template #table-caption>
      <slot
          v-if="customPagination"
          name="custom_pagination"
      ></slot>
      <div
          v-else-if="items.length"
          class="simple-table__footer"
      >
        <div class="simple-table__footer-perpage">
          <span>Показать</span>
          <b-form-select
              v-model="perPage"
              :options="pageOption"
              @input="$emit('perPageChange', perPage)"
          />
        </div>
        <div class="simple-table__footer-paginator">
          <b-pagination
              v-model="currentPage"
              :total-rows="totalRows || items.length"
              :per-page="perPage"
              aria-controls="simple-table"
              hide-goto-end-buttons
              @change="page => $emit('pageChange', page)"
          >
            <template #prev-text>
              <span class="pagination-prev feather icon-chevron-left"></span>
            </template>
            <template #next-text>
              <span class="pagination-next feather icon-chevron-right"></span>
            </template>
          </b-pagination>
        </div>
      </div>
    </template>

    <template #empty="">
      <div class="simple-table__empty">
        <img src="@/assets/images/empty.svg" alt="no-data">
        <div class="simple-table__empty-text">Ничего не найдено</div>
      </div>
    </template>

  </b-table>
</template>

<script>
import ColumnResizer from 'column-resizer';
import fileDownload from 'js-file-download';
import OdaFormGroup from './OdaFormGroup.vue';

export default {
  name: 'OdaTable',
  components: {
    OdaFormGroup,
  },
  props: {
    fields: Array,
    items: Array,
    minWidth: {
      type: Number,
      default: 100,
    },
    disableResize: Boolean,
    disabledColumns: {
      type: Array,
      default() {
        return [];
      },
    },
    widths: {
      type: Array,
      default() {
        return [];
      },
    },
    caption: Boolean,
    rowClickable: Boolean,
    pickable: Boolean,
    pickedItems: {
      type: Array,
      default() {
        return [];
      },
    },
    totalRows: Number,
    customPagination: Boolean,
    notResizable: Boolean,
  },
  data() {
    return {
      searchValue: {},
      choosenValues: [],
      pageOption: [10, 15, 30, 90],
      perPage: 10,
      currentPage: 1,

      filterTimeoutIds: {},
      fileDownloading: false,
    };
  },
  computed: {},
  mounted() {
    this.$nextTick(() => {
      if (!this.notResizable) this.addResize();
    });
  },
  methods: {
    rowClicked(record) {
      this.$emit('rowClicked', record);
    },
    chooseRow() {
      this.$emit('chooseRow', this.choosenValues);
    },
    addResize() {
      const Resizable = ColumnResizer;
      if (!this.$refs.table) return;
      // eslint-disable-next-line no-new
      new Resizable(this.$refs.table.$el.children[0], {
        liveDrag: true,
        gripInnerHtml: "<div class='grip'></div>",
        draggingClass: 'dragging',
        minWidth: this.minWidth,
        disabledColumns: this.disabledColumns,
        widths: this.widths,
      });
    },
    approveOs(item) {
      if (item.approved_by_builder) return;
      this.$emit('approveOs', item);
    },
    hasUploadedDoc(item) {
      const files = [
        'passport',
        'residents_meeting_minutes',
        'gov_act', 'tech_passport',
        'contract_of_sale',
        'help_encumbrances',
        'director_say_so',
        'state_registration_certificate',
        'lease_contract',
      ];
      let res = false;
      // eslint-disable-next-line consistent-return
      files.forEach((file) => {
        if (item[file] && item[file].length) {
          res = true;
          return res;
        }
      });
      return res;
    },
    onFilter(value, key) {
      clearTimeout(this.filterTimeoutIds[key]);
      this.filterTimeoutIds[key] = setTimeout(() => {
        this.$emit('filter', { key, value });
      }, 1000);
    },
    getStatusClass(status) {
      const classes = {
        warning: ['в обработке', 'на рассмотрении'],
        success: ['оплачен', 'завершено'],
        danger: ['отклонено', 'на доработке'],
        info: ['новые задачи'],
      };
      const color = Object.keys(classes).filter((item) => classes[item].includes((status || '').toLowerCase()));
      return (color && color[0]) || 'warning';
    },
    downloadPdf(url) {
      this.fileDownloading = true;
      this.$axios({
        url,
        method: 'GET',
        responseType: 'blob',
      }).then((res) => {
        fileDownload(res.data, 'file.pdf');
      }).finally(() => {
        this.fileDownloading = false;
      });
    },
  },
};
</script>
