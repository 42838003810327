import axios from "axios";

const MainApiService = {
    get( url, config = {}) {
        return axios.get(url, config);
    },

    post( url, data = {}) {
        return axios.post(url, data);
    },

    put( url, data = {}) {
        return axios.put(url, data);
    },

    delete( url, data = {}) {
        return axios.delete(url, data);
    },
}

export default MainApiService;