<template>
  <section class="oda-section">
    <section-header
        :breadcrumb="breadcrumb"
        section="Платежка"
    />

    <div class="send-registry-back mb-4">
      <button
          class="send-registry__head-button oda-button primary feather icon-chevron-left"
          @click="$router.push('/m/payment')"
      ></button>
      Назад
    </div>

    <div class="send-registry-back mb-4">
      <button
          class="oda-button success"
          @click="downloadPdf(payment.application.signed_pdf)"
      >Скачать PDF</button>
    </div>

    <b-tabs class="oda-tabs">
      <b-tab
          v-for="(item, i) in items"
          :key="`oda-tab-item-${i}`"
          :title="getTabTitle(i)"
          :active="i === 0"
      >
        <b-row>
          <b-col cols="12" md="8">
            <payment-status
                :status="payment.status"
                :date="payment.created_at"
                :decline_message="payment.error_message"
                @resend="resend"
            />

            <payment-form :form="item" />
          </b-col>

          <b-col cols="12" md="4">
            <payment-pdf
                v-if="getPdfLinks(i).length"
                :links="getPdfLinks(i)"
                :counter_photo="payment.counter_photo"
            />
          </b-col>
        </b-row>
      </b-tab>
    </b-tabs>
  </section>
</template>

<script>
import SectionHeader from "../../components/SectionHeader";
import {mapGetters, mapState} from "vuex";
import PaymentPdf from "../../components/PaymentItem/Pdf";
import PaymentForm from "../../components/PaymentItem/Form";
import PaymentStatus from "../../components/PaymentItem/Status";
import fileDownload from 'js-file-download';

export default {
  name: "PaymentItemMobile",
  components: {
    SectionHeader,
    PaymentStatus,
    PaymentForm,
    PaymentPdf,
  },
  data() {
    return {
      breadcrumb: [
        {
          name: 'Электроэнергия',
          link: '/payment'
        },
        {
          name: 'Платежка'
        }
      ],
      fileDownloading: false,
    }
  },

  computed: {
    ...mapGetters({
      payment: 'electricity/clientPayment',
    }),
    ...mapState({
      client_type: state => state.auth.client_type,
    }),
    items() {
      const form = [
        {
          name: 'contract_name',
          value: this.payment.contract_name,
          size: 12,
        },
        {
          name: 'bts',
          value: this.payment.bts,
          size: 12,
        },
        {
          name: 'counterparty',
          value: this.payment.counterparty,
          size: 12,
          label: 'Контрагент',
        },
        {
          name: 'condition_type',
          value: (this.payment.application || {}).condition_type,
          label: 'Вид расхода',
          size: 12,
        },
        {
          name: 'period',
          value: this.getPaymentPeriod(this.payment.start_dt, this.payment.end_dt),
          label: 'Период оплаты',
          size: 12,
        },
        {
          name: 'sf_number',
          value: this.payment.id,
          label: 'Входящий номер АФИП',
          size: 12,
        },
        {
          name: 'income_date',
          value: this.getDateFormat(this.payment.created_at),
          label: 'Входящая дата',
          size: 12,
        },
        {
          name: 'counter_previous_value',
          value: this.payment.counter_previous_value,
          label: 'Предыдущее показание в кВт',
          size: 12,
        },
        {
          name: 'counter_value',
          value: this.payment.counter_value,
          label: 'Текущее показание в кВТ',
          size: 12,
        },
        {
          name: 'used_kwt',
          value: this.payment.counter_value - this.payment.counter_previous_value,
          label: 'Потреблено в кВт',
          size: 12,
        },
        {
          name: 'tariff_rate',
          value: this.payment.tariff_rate,
          label: 'Тариф в тг',
          size: 12,
        },
        {
          name: 'current_payment_amount',
          value: this.prettyPrice(this.payment.total_sum),
          label: 'Сумма в тг',
          size: 12,
        },
        {
          name: 'medium_for_months',
          value: this.prettyPrice(this.getMediumForPeriod(this.payment)),
          label: 'Среднее за месяц',
          size: 12,
        },
      ];

      const items = [];
      const { completion_certificates } = this.payment;
      if ((completion_certificates || []).length) {
        completion_certificates.forEach(item => {
          const form_item = [
            {
              name: 'counterparty_payer_contract',
              value: item.state.condition.counterparty_contract,
              size: 12,
            },
            {
              name: 'bts',
              value: item.state.bts.bts,
              size: 12,
            },
            {
              name: 'counterparty_payer',
              value: item.counterparty,
              size: 12,
              label: 'Контрагент',
            },
            {
              name: 'expense_type',
              value: 'ЭЭ',
              label: 'Вид расхода',
              size: 12,
            },
            {
              name: 'period',
              value: this.getPrettyMonth(item.state.start_dt),
              label: 'Период оплаты',
              size: 12,
            },
            {
              name: 'sf_number',
              value: item.id,
              label: 'Входящий номер АВР',
              size: 12,
            },
            {
              name: 'income_date',
              value: this.getDateFormat(item.created_at),
              label: 'Входящая дата',
              size: 12,
            },
            {
              name: 'counter_previous_value',
              value: item.state.counter_previous_value,
              label: 'Предыдущее показание в кВт',
              size: 12,
            },
            {
              name: 'counter_value',
              value: item.state.counter_value,
              label: 'Текущее показание в кВТ',
              size: 12,
            },
            {
              name: 'used_kwt',
              value: item.state.counter_value - item.state.counter_previous_value,
              label: 'Потреблено в кВт',
              size: 12,
            },
            {
              name: 'tariff_rate',
              value: item.state.tariff_rate,
              label: 'Тариф в тг',
              size: 12,
            },
            {
              name: 'current_payment_amount',
              value: this.prettyPrice(item.state.total_sum),
              label: 'Сумма в тг',
              size: 12,
            },
            {
              name: 'medium_for_months',
              value: this.prettyPrice(this.getMediumForPeriod(this.payment)),
              label: 'Среднее за месяц',
              size: 12,
            },
          ];
          items.push(form_item);
        })
      } else return [form];
      return items;
    },
  },

  async mounted() {
    const { id } = this.$route.params;
    await this.$store.dispatch('electricity/getElectricity', id);
    this.$store.dispatch('electricity/getAverageConsupmtion', this.payment.bts);
  },

  methods: {
    resend() {
      const { payment } = this;
      const { id } = payment || {};
      const params = {
        counter_value: payment.counter_value,
        counter_previous_value: payment.counter_previous_value,
        bts_name: payment.bts,
        period: `${payment.start_dt}/${payment.end_dt}`,
        contract_code: payment.contract_code,
        tariff_rate: payment.tariff_rate,
        id,
        group_id: payment.group_id,
      };
      this.$store.commit('electricity/SET_RESEND_ITEM', params);

      const paymentId = this.$route.params.id;
      const routeQuery = { resend: 'true', id: paymentId };
      this.$router.push({ path: `/send-counter-readings/${this.client_type}`, query: routeQuery });
    },
    getPdfLinks(i) {
      const links = [];
      if (this.payment.completion_certificates && this.payment.completion_certificates[i]) {
        links.push({
          title: 'АВР',
          name: 'AVR',
          link: this.payment.completion_certificates[i].signed_pdf,
        });
      } else if (this.payment.actual_area_usage_act && this.payment.actual_area_usage_act.signed_pdf) {
        links.push({
          title: 'АФИП',
          name: 'AFIP',
          link: (this.payment.actual_area_usage_act || {}).signed_pdf,
        });
      } else if (this.payment.signed_pdf) {
        links.push({
          title: 'АФИП',
          name: 'AFIP',
          link: this.payment.signed_pdf,
        });
      }
      return links;
    },
    getTabTitle(i) {
      if (this.payment.completion_certificates && this.payment.completion_certificates[i])
        return this.getPrettyMonth(this.payment.completion_certificates[i].state.start_dt);
      return "Электроэнергия";
    },
    downloadPdf(url) {
      this.fileDownloading = true;
      this.$axios({
        url,
        method: 'GET',
        responseType: 'blob',
      }).then((res) => {
        fileDownload(res.data, 'file.pdf');
      }).finally(() => {
        this.fileDownloading = false;
      });
    },
  },
}
</script>
