<template>
  <div class="profile-tabs oda-desktop-only">
    <oda-tabs :tabs="tabs">
      <template slot="active">
        <div
            v-if="loading"
            class="profile-tab p-5"
        >
          <div class="profile-tab__loading">
            <span></span>
          </div>
        </div>
        <div
            v-else-if="active_contracts.length > 0"
            class="profile-tab"
        >
          <div
              class="profile-tab__contracts">
            <div
                v-for="(item, i) in active_contracts"
                :key="`active-tab__${i}`"
                :class="{'active': active.code === item.code}"
                class="profile-tab__contracts-item"
                @click="setActive(item)"
            >
              <span class="feather icon-file"></span>
              <div>{{ item.counterparty_contract }}</div>
            </div>
          </div>
          <div
              v-if="active"
              class="profile-contract"
          >
            <div class="profile-contract__header">
              <span class="feather icon-file"></span>
              {{ active.counterparty_contract }}
            </div>
            <div class="profile-contract__row">
              <div class="profile-contract__card">
                <div class="profile-contract__card-item">
                  <div class="profile-contract__card-item-icon feather icon-calendar info"></div>
                  <div class="profile-contract__card-item-key">Дата начала договора</div>
                  <div class="profile-contract__card-item-value">{{ active.start_date &&  active.start_date.slice(0,10) }}</div>
                </div>
                <div class="profile-contract__card-item">
                  <div class="profile-contract__card-item-icon feather icon-calendar danger"></div>
                  <div class="profile-contract__card-item-key">Дата окончания договора</div>
                  <div class="profile-contract__card-item-value">{{ active.end_date && active.end_date.slice(0,10) }}</div>
                </div>
              </div>
              <div class="profile-contract__card">
                <div class="profile-contract__card-item">
                  <div class="profile-contract__card-item-icon feather icon-feather primary"></div>
                  <div class="profile-contract__card-item-key">Возможность пролонгации</div>
                  <div class="profile-contract__card-item-value">Нет</div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </template>
    </oda-tabs>
  </div>
</template>

<script>
import OdaTabs from "@/components/OdaTabs.vue";
import {mapGetters, mapState} from "vuex";

export default {
  name: "ProfileContracts",
  components: {
    OdaTabs,
  },

  data() {
    return {
      active: {},
      fields: [
        { key: 'object', label: 'Объект', icon: 'icon-radio', colored: "primary" },
        { label: 'Местоположение', key: 'location'},
        { label: 'Условия по ЭЭ', key: 'usloviya'},
      ],
      items: [
        {
          object: 'A_ASR013',
          location: 'Акмолинская обл, пос.Жалгызкудык',
          usloviya: 'ЭЭ сч. от арендодателя',
        },
        {
          object: 'A_ASR013',
          location: 'Акмолинская обл, пос.Жалгызкудык',
          usloviya: 'ЭЭ сч. от арендодателя',
        },
        {
          object: 'A_ASR013',
          location: 'Акмолинская обл, пос.Жалгызкудык',
          usloviya: 'ЭЭ сч. от арендодателя',
        },
        {
          object: 'A_ASR013',
          location: 'Акмолинская обл, пос.Жалгызкудык',
          usloviya: 'ЭЭ сч. от арендодателя',
        },
      ],
    }
  },

  computed: {
    ...mapState({
      loading: state => state.profile.loading,
    }),
    ...mapGetters({
      rent_active_contracts: 'profile/rent_active_contracts',
      electricity_active_contracts: 'profile/electricity_active_contracts',
    }),
    active_contracts() {
      if (!this.rent_active_contracts || !this.electricity_active_contracts) return [];
      return [...this.rent_active_contracts, ...this.electricity_active_contracts];
    },
    tabs() {
     return [
       {
         name: 'active',
         title: 'Действующие договоры',
         icon: 'icon-file',
         empty: this.active_contracts && this.active_contracts.length < 1,
         emptyText: 'У вас пока нет действующих договоров',
       },
       {
         name: 'nonactive',
         title: 'Недействующие договоры',
         icon: 'icon-archive',
         empty: true,
         emptyText: 'У вас нет недействующих договоров',
       },
     ]
    },
  },

  mounted() {
    this.$nextTick(() => {
      this.active = (this.active_contracts && this.active_contracts[0]) || {};
    })
  },
  methods: {
    setActive(contract) {
      this.active = contract;
    },
  },
}
</script>
