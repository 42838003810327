<template>
  <section class="oda-section payment-page">
    <section-header
        :breadcrumb="breadcrumb"
        section="Платежка"
    />
    <div
        v-if="isMobile"
        class="send-registry-back mb-4"
    >
      <button
          class="oda-button success"
          @click="downloadPdf(pdf.link)"
      >Скачать PDF</button>
    </div>
    <b-row v-if="item">
      <b-col cols="12" md="8">
        <payment-status
            status="Подписано"
            :date="item.created_at"
            date-value="Дата отправки"
        />
        <payment-form :form="form" />

      </b-col>

      <b-col cols="12" md="4">
        <payment-pdf :links="pdf" />
      </b-col>
    </b-row>
  </section>
</template>

<script>
import SectionHeader from '../../components/SectionHeader.vue';
import PaymentStatus from '../../components/PaymentItem/Status.vue';
import PaymentForm from '../../components/PaymentItem/Form.vue';
import PaymentPdf from '../../components/PaymentItem/Pdf.vue';
import {mapActions, mapState} from "vuex";
import fileDownload from "js-file-download";

export default {
  name: "RentsItem",
  components: {
    SectionHeader,
    PaymentStatus,
    PaymentForm,
    PaymentPdf,
  },

  computed: {
    ...mapState({
      item: state => state.registry.rents_item,
      isMobile: state => state.isMobile,
    }),
    pdf() {
      return [{
        title: 'АВР',
        name: 'AVR',
        link: this.item && this.item.application && this.item.application.signed_pdf,
      }]
    },
    form() {
      const { isMobile } = this;
      return [
        {
          name: 'contract_code',
          value: this.item.contract_code,
          size: isMobile ? 12 : 8,
          label: 'Договор на поставку',
        },
        {
          name: 'bts',
          value: this.item.bts,
          size: isMobile ? 12 : 4,
          label: 'Объект',
        },
        {
          name: 'counterparty_payer',
          value: this.currentUser && this.currentUser.name,
          size: isMobile ? 12 : 8,
          label: 'Контрагент',
        },
        {
          name: 'expense_type',
          value: 'Аренда',
          label: 'Вид расхода',
          size: isMobile ? 12 : 4,
        },
        {
          name: 'payment_period',
          value: this.getPaymentPeriod(this.item.start_dt),
          label: 'Период оплаты',
          size: isMobile ? 12 : 4,
        },
        {
          name: 'nds_type',
          value: 'Без НДС',
          label: 'Ставка НДС',
          size: isMobile ? 12 : 4,
        },
        {
          name: 'total_sum',
          value: this.prettyPrice(Number(this.item.total_sum)),
          label: 'Сумма в тг',
          size: isMobile ? 12 : 4,
        },
      ];
    },
  },

  data() {
    return {
      breadcrumb: [
        {
          name: 'Аренда',
          link: '/rents',
        },
        {
          name: 'Платежка',
        },
      ],
    }
  },

  created() {
    const { id } = this.$route.params;
    this.getRentsItem(id);
  },

  methods: {
    ...mapActions('registry', [
        'getRentsItem',
    ]),
    downloadPdf(url) {
      this.fileDownloading = true;
      this.$axios({
        url,
        method: 'GET',
        responseType: 'blob',
      }).then((res) => {
        fileDownload(res.data, 'file.pdf');
      }).finally(() => {
        this.fileDownloading = false;
      });
    },
  },
}
</script>
