<template>
  <section class="du-auth">
    <div class="du-auth__logo">
      <img
          src="@/assets/images/tele2-logo.svg"
          alt="tele2"
      >
    </div>

    <b-row class="h-100">
      <b-col
          cols="8"
          class="h-100 oda-desktop-only"
      >
        <div class="du-auth__illustration">
          <img
              src="@/assets/images/documents-update.svg"
              alt="documents-update-illustration"
          >
        </div>
      </b-col>

      <b-col
          cols="12"
          md="4"
          class="du-auth__form-wrapper"
      >
        <du-form
            v-if="!error"
            @error="onError"
        />
        <du-error
            v-else
            :error-text="errorText"
            @back="onBack"
        />
      </b-col>
    </b-row>
  </section>
</template>

<script>
import DuForm from "./Auth/form";
import DuError from "./Auth/error";

export default {
  name: "DocumentsUpdate",

  components: {
    DuForm,
    DuError,
  },

  data() {
    return {
      success: false,
      error: false,
      errorText: "",
    }
  },

  methods: {
    onError(text) {
      this.error = true;
      this.errorText = text;
    },
    onBack() {
      this.success = false;
      this.error = false;
    },
  },
}
</script>