import employeeServices from "@/services/employeeServices";
import managerServices from "@/services/managerServices";
import clientServices from "@/services/clientServices";
import errorService from "@/services/errorService";
import router from "../../router";

const state = {
  employee_treaties: [],
  employee_treaties_item: null,

  manager_treaties: [],

  client_contract: null,

  archive: [],

  tssr: null,
  sr: null,
};

const getters = {};

const mutations = {
  SET_EMPLOYEE_TREATIES(state, payload) {
    state.employee_treaties = payload;
  },
  SET_EMPLOYEE_TREATIES_ITEM(state, payload) {
    state.employee_treaties_item = payload;
  },
  SET_MANAGER_TREATIES(state, payload) {
    state.manager_treaties = payload;
  },
  SET_CLIENT_CONTRACT(state, payload) {
    state.client_contract = payload;
  },
  SET_ARCHIVE(state, payload) {
    state.archive = payload;
  },
  SET_SITE_INFO(state, payload) {
    state.sr = payload.s_candidate_recieved || null;
    state.tssr = payload.s_tssr_recieved || null;
  },
};

const actions = {
  async getEmployeeNewTreaties({ commit }, params) {
    try {
      const { data } = await employeeServices.getEmployeeNewTreaties(params);
      commit('SET_EMPLOYEE_TREATIES', data);
    } catch (e) {
      errorService.handleError(e);
    }
  },
  async getNewTreatiesItem({ commit }, id) {
    try {
      const { data } = await employeeServices.getNewTreatiesItem(id);
      commit('SET_EMPLOYEE_TREATIES_ITEM', data);
    } catch (e) {
      errorService.handleError(e);
    }
  },
  async uploadNewTreaty({ commit }, params) {
    try {
      const { data } = await employeeServices.uploadNewTreaty(params);
      commit('SET_EMPLOYEE_TREATIES_ITEM', data);
    } catch (e) {
      errorService.handleError(e);
    }
  },
  viewNewTreatiesItem(_, ciptracker_id) {
    employeeServices.viewNewTreatiesItem({ ciptracker_id });
  },
  async declineNewTreatiesItem(_, params) {
    try {
      await employeeServices.declineNewTreatiesItem(params);
      await router.push('/new-contracts');
      errorService.onSuccess("Задача успешно отклонена");
    } catch (e) {
      errorService.handleError(e);
    }
  },
  async approveNewTreatiesItem(_, params) {
    try {
      await employeeServices.approveNewTreatiesItem(params);
      await router.push('/new-contracts');
      errorService.onSuccess("Задача перешла в раздел подтверждена");
    } catch (e) {
      errorService.handleError(e);
      throw e;
    }
  },
  async addListApproval({ commit }, params) {
    try {
      const { data } = await employeeServices.addListApproval(params);
      commit('SET_EMPLOYEE_TREATIES_ITEM', data);
      errorService.onSuccess("Лист согласования прикреплена");
    } catch (e) {
      errorService.handleError(e);
    }
  },
  async getManagerNewTreaties({ commit }) {
    try {
      const { data } = await managerServices.getManagerNewTreaties();
      commit('SET_MANAGER_TREATIES', data);
    } catch (e) {
      errorService.handleError(e);
    }
  },
  async approveManagerNewTreatiesItem(_, params) {
    try {
      await managerServices.approveManagerNewTreatiesItem(params);
      await router.push('/new-contracts-manager');
      errorService.onSuccess("Задача подтверждена");
    } catch (e) {
      errorService.handleError(e);
    }
  },
  async declineManagerNewTreatiesItem(_, params) {
    try {
      await managerServices.declineManagerNewTreatiesItem(params);
      await router.push('/new-contracts-manager');
      errorService.onSuccess("Задача успешно отклонена");
    } catch (e) {
      errorService.handleError(e);
      throw e;
    }
  },

  async getContractClient({ commit }, params) {
    localStorage.clear(); //removing prev iin & employee jwt (if exists) from localstorage
    try {
      const { data } = await clientServices.getContractClient(params);
      commit('SET_CLIENT_CONTRACT', data);
    } catch (e) {
      errorService.handleError(e);
    }
  },
  async declineNewTreatiesClient({ commit }, params) {
    try {
      const { data } = await clientServices.declineNewTreatiesClient(params);
      commit('SET_CLIENT_CONTRACT', data);
    } catch (e) {
      errorService.handleError(e);
    }
  },
  async approveNewTreatiesClient({ commit }, params) {
    try {
      const { data } = await clientServices.approveNewTreatiesClient(params);
      commit('SET_CLIENT_CONTRACT', data);
    } catch (e) {
      errorService.handleError(e);
    }
  },
  async addCommentNewTreatiesItem({ commit }, params) {
    try {
      const { data } = await employeeServices.addCommentNewTreatiesItem(params);
      commit('SET_EMPLOYEE_TREATIES_ITEM', data);
    } catch (e) {
      errorService.handleError(e);
    }
  },
  async getNewTreatiesArchive({ commit }) {
    try {
      const { data } = await employeeServices.getNewTreatiesArchive();
      commit('SET_ARCHIVE', data);
    } catch (e) {
      errorService.handleError(e);
    }
  },
  async getSiteInfo({ commit }, ciptracker_id) {
    try {
      const { data } = await employeeServices.getSiteInfo({ ciptracker_id });
      commit("SET_SITE_INFO", data.data || null);
    } catch (e) {
      errorService.handleError(e);
    }
  },
};

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions
}
