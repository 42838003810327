<template>
  <section class="oda-section treaties-item">
    <section-header section="Новые договоры" />

    <div class="payments-body">
      <div class="payments-section">
        <div class="payments-section__header">
          <div class="payments-section__header-left">
            <h3>На подписание</h3>
          </div>
        </div>

        <oda-table
            ref="electricity-table"
            :fields="fields"
            :items="items"
            row-clickable
            custom-pagination
            @rowClicked="goToCard"
        />
      </div>
    </div>
  </section>
</template>

<script>
import SectionHeader from "../../../components/SectionHeader";
import OdaTable from "../../../components/OdaTable";
import {mapActions, mapState} from "vuex";

export default {
  name: "NewContractsManager",
  components: {
    SectionHeader,
    OdaTable,
  },

  data() {
    return {
      fields: [
        { key: 'counterparty_name', label: 'Контрагент', sortable: true },
        { key: 'bts', label: 'Объект', sortable: true },
        // { key: 'period', label: 'Период', sortable: true },
        // { key: 'contract_sum', label: 'Сумма в тг', sortable: true },
        // { key: 'created_at', label: 'Дата', sortable: true },
        // { key: 'status', label: 'Статус', sortable: true },
      ],
    }
  },

  computed: {
    ...mapState({
      items: state => state.treaties.manager_treaties,
    })
  },

  mounted() {
    this.getManagerNewTreaties();
  },
  methods: {
    ...mapActions('treaties', [
        'getManagerNewTreaties',
    ]),
    goToCard(card) {
      if (card.status === 'Новые задачи')
        this.viewNewTreatiesItem(card.ciptracker_id);
      this.$router.push(`/new-contracts/${card.id}`);
    },
  },
}
</script>
