<template>
  <section class="oda-section">
    <section-header section="FAQ"/>
    <oda-collapse :items="items"/>
  </section>
</template>

<script>
import SectionHeader from "../components/SectionHeader";
import OdaCollapse from "../components/OdaCollapse";

export default {
  name: "FAQ",
  components: {
    SectionHeader,
    OdaCollapse,
  },
  data() {
    return {
      items: [
        {
          title: 'Как обеспечивается информационная безопасность моих документов при использовании решений?',
          text: 'Мы серьезно относимся к вопросам информационной безопасности данных наших контрагентов.<br><br>' +
              'Мы на постоянной основе проводим мероприятия по повышению информ безопасности на 3 уровнях: инфраструктурный уровень, уровень программного обеспечения и человеческий уровень <br><br>' +
              'При проектировании и создании решений мы руководствовались следующими требованиями: <br>' +
              '- передача данных посредством защищенных каналов связи с поддержкой SSL; применение ЭЦП НУЦ РК при подписании ЭД (контроль целостности) в соответствии с гос. стандартами РК; <br>' +
              '- применение шифрования передаваемых электронных документов с использованием алгоритмов AES, Blowfish или 3DES; <br>' +
              '- обеспечение надежности и безопасности (данных, физической структуры) согласно государственным стандартам РК. <br><br>' +
              'Любая информация в базах данных не хранится в виде простого текста в открытом виде, всегда подвергается шифрованию и не доступна никому, кроме самого контрагента.',
        },
        {
          title: 'Как обеспечивается легитимность подписанных документов в tele2.site?',
          text: 'Легитимность электронных документов обеспечивается следующими законами РК: <br><br>' +
              'Согласно закону РК от 7 января 2003 года N370-II «Об электронном документе и электронной цифровой подписи».' +
              'Пункт 1 статья 7. «Электронный документ, соответствующий требованиям настоящего Закона и удостоверенный посредством' +
              'электронной цифровой подписи лица, имеющего полномочия на его подписание, равнозначен подписанному документу на бумажном носителе». <br><br>' +
              'Согласно закону РК от 24 ноября 2015 года № 418-V ЗРК. <br><br>' +
              'Пункт 2 статья 35. <br><br>' +
              '«Запрос, направленный в форме электронного документа, заверенного электронной цифровой подписью, приравнивается к запросу, ' +
              'направленному на бумажном носителе и подписанному оригинальной подписью лица, направившего запрос. Такие запросы подлежат регистрации в соответствии с правилами делопроизводства, установленными собственником или владельцем информационной системы».',
        },
        {
          title: 'Храним ли мы ЭЦП пользователя и его пароль у себя на сервере?',
          text: 'Мы никогда не храним ЭЦП и пароль клиентов на своих серверах. <br><br>' +
              'При подписании документа в нашей системе, запрашивается путь и пароль к ЭЦП, при этом на сервера данная информация ' +
              'не поступает, так как все подписание происходит на компьютере пользователя в его браузере. Для удобства пользователей ' +
              'ваш браузер может предложить возможность сохранить пароль, при этом вся ответственность за хранение пароля в браузере ' +
              'полностью лежит на пользователе.',
        },
        {
          title: 'Почему я, как контрагент, могу отправлять только показания счетчика? Почему нет другого функционала?',
          text: 'Наша основная миссия, чтобы вы могли решить любой вопрос касаемо оплаты электроэнергии, документооборота и других вопросов быстро, удобно и безопасно. <br><br>' +
              'Мы решили предложить вам небольшой функционал, объем которого мы будем увеличивать постепенно, всегда исходя из вашего мнения. <br><br>' +
              'С появлением новых функций вы всегда будете уведомлены через вам удобный канал коммуникаций.',
        },
      ],
    }
  },
}
</script>
