<template>
  <div class="step2">
    <div class="send-registry__step-header">
      Электроэнергия
    </div>

    <div class="send-registry__footer">
      <router-link
          to="/payment"
          class="oda-button bordered danger oda-desktop-only"
      >
        Отменить отправку
      </router-link>

      <router-link
          to="/m/payment"
          class="oda-button bordered danger oda-mobile-only"
      >
        Отменить отправку
      </router-link>

      <button
          class="oda-button primary"
          @click="$emit('submit')"
      >
        Подписать и отправить
      </button>
    </div>

    <div class="oda-card send-registry__card">
      <div class="send-registry__card-title oda-desktop-only">
        <div class="send-registry__card-left">
          <span class="feather icon-file"></span>
          <h3>Договор на поставку</h3>
        </div>
      </div>
      <b-row>
        <b-col cols="12" md="8" order="2" order-sm="1">
          <div class="send-registry__card-title oda-mobile-only">
            <div class="send-registry__card-left">
              <span class="feather icon-file"></span>
              <h3>Договор на поставку</h3>
            </div>
          </div>
          <b-row>
            <b-col
                v-for="(field) in formStep2"
                :key="field.name"
                :cols="field.size"
            >
              <oda-form-group
                  :value="field.value"
                  :label="field.label"
                  disabled
              />
            </b-col>
          </b-row>
        </b-col>
        <b-col cols="12" md="4" order="1" order-sm="2" class="mb-2">
          <div class="send-registry__card-pdf-wrapper oda-mobile-only">
            <div
                v-for="pdf in pdfs"
                :key="pdf.label"
                :class="{'active': pdf.label === activePdf}"
                class="send-registry__card-pdf"
                @click="activePdf = pdf.label"
            >
              <img src="@/assets/images/pdf-icon.svg" alt="pdf-icon">
              <div>{{ pdf.label }}</div>
            </div>
          </div>
          <embed v-if="pdf_link && pdf_link.length" :src="pdf_link" width="100%" height="100%" class="oda-desktop-only"/>
          <embed v-if="pdf_link && pdf_link.length" :src="pdf_link" width="100%" height="287px" class="oda-mobile-only"/>
        </b-col>
<!--        <b-col cols="12" md="4" order="1" order-sm="2" class="mb-2">-->
<!--          <payment-pdf :link="pdf_link" />-->
<!--        </b-col>-->
      </b-row>
    </div>

    <div class="send-registry__footer">
      <router-link
          to="/payment"
          class="oda-button bordered danger oda-desktop-only"
      >
        Отменить отправку
      </router-link>

      <router-link
          to="/m/payment"
          class="oda-button bordered danger oda-mobile-only"
      >
        Отменить отправку
      </router-link>

      <button
          class="oda-button primary"
          @click="$emit('submit')"
      >
        Подписать и отправить
      </button>
    </div>
  </div>
</template>

<script>
import OdaFormGroup from "../OdaFormGroup";
import PaymentPdf from "../PaymentItem/Pdf";
import {mapGetters, mapState} from "vuex";

export default {
  name: "ElectricityForm",
  components: {
    OdaFormGroup,
    // eslint-disable-next-line vue/no-unused-components
    PaymentPdf,
  },
  props: {
    bts: {
      default() {
        return {}
      }
    },
  },
  data() {
    return {
      isMobile: false,
      activePdf: 'СФ',
      pdfs: [
        {
          label: 'АФИП',
        },
      ],
    }
  },

  computed: {
    ...mapGetters({
      averagePaymentSum: 'registry/clientAveragePaymentSum',
    }),
    ...mapState({
      active_contract: state => state.registry.active_contract,
      pdf_link: state => state.registry.pdf_preview_link,
    }),
    formStep2() {
      const { isMobile } = this;
      const start_period = this.getPrettyMonth(this.bts.start_dt),
          end_period = this.getPrettyMonth(this.bts.end_dt),
          period = start_period === end_period ? start_period : `${start_period} - ${end_period}`;
      return [
        {
          name: 'contract',
          size: isMobile ? 12:8,
          value: this.bts.contract,
        },
        {
          name: 'bts_name',
          size: isMobile ? 12:4,
          value: this.bts.bts_name,
        },
        {
          name: 'client',
          size: isMobile ? 12:8,
          label: 'Контрагент',
          value: this.bts.client,
        },
        {
          name: 'type',
          value: 'Электроэнергия',
          label: 'Вид расхода',
          size: isMobile ? 12:4,
        },
        {
          name: 'period',
          label: 'Период оплаты',
          size: 12,
          value: period,
        },
        // {
        //   name: 'sf_number',
        //   value: '- ',
        //   label: 'Входящий номер документа',
        //   size: isMobile ? 12:4,
        // },
        {
          name: 'counter_previous_value',
          label: 'Предыдущее показание в кВт',
          size: isMobile ? 12:4,
          value: this.bts.counter_previous_value,
        },
        {
          name: 'counter_value',
          label: 'Текущее показание в кВТ',
          size: isMobile ? 12:4,
          value: this.bts.counter_value,
        },
        {
          name: 'used_value',
          label: 'Потреблено в кВт',
          size: isMobile ? 12:4,
          value: this.bts.counter_value - this.bts.counter_previous_value,
        },
        {
          name: 'tariff_rate',
          label: 'Тариф в тг',
          size: isMobile ? 12:4,
          value: this.bts.tariff_rate,
        },
        {
          name: 'sum',
          label: 'Сумма в тг',
          value: this.sum,
        },
        {
          name: 'medium_for_months',
          value: this.averagePaymentSum,
          label: 'Средняя сумма потребления ЭЭ',
          size: isMobile ? 12:4,
        },
      ]
    },
    sum() {
      return (this.bts.tariff_rate * (this.bts.counter_value - this.bts.counter_previous_value)).toFixed(2);
    },
    medium_for_months() {
      if (!this.bts.end_dt) return 0;
      let months = this.bts.end_dt.split('-')[1] - this.bts.start_dt.split('-')[1];
      if (months < 0) months = (12 - this.bts.start_dt.split('-')[1]) + Number(this.bts.end_dt.split('-')[1]);
      return this.sum / (months + 1);
    },
  },
}
</script>
