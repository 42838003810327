<template>
  <section class="oda-section payment-page">
    <section-header
        :breadcrumb="breadcrumb"
        section="Платежка"
        back-link="/archive"
    />

    <b-tabs class="oda-tabs">
      <b-tab
          v-for="(item, i) in items"
          :key="`oda-tab-item-${i}`"
          :title="getTabTitle(i)"
          :active="i === 0"
      >
        <b-row>
          <b-col cols="12" md="8">
            <payment-status
                :status="payment.status"
                :date="payment.created_at"
            />

            <payment-form :form="item" />
          </b-col>

          <b-col cols="12" md="4">
            <payment-user :user="payment.counterparty_payer" />
            <payment-pdf
                v-if="getPdfLinks(i).length"
                :links="getPdfLinks(i)"
                :counter_photo="payment.counter_photo"
            />
          </b-col>
        </b-row>
      </b-tab>
    </b-tabs>
  </section>
</template>

<script>
import SectionHeader from "../../components/SectionHeader";
import PaymentStatus from "../../components/PaymentItem/Status";
import PaymentUser from "../../components/PaymentItem/User";
import PaymentForm from "../../components/PaymentItem/Form";
import PaymentPdf from "../../components/PaymentItem/Pdf";
import { mapState, mapGetters } from "vuex";
import paymentPage from "../../mixins/paymentPage";

export default {
  name: "ArchiveItem",
  components: {
    SectionHeader,
    PaymentStatus,
    PaymentUser,
    PaymentForm,
    PaymentPdf,
  },
  mixins: [paymentPage],
  data() {
    return {
      statusDictionary: {
        success: 'оплачен',
        warning: 'в обработке',
        danger: 'отклонено',
      },
    }
  },

  computed: {
    ...mapGetters({
      payment: 'electricity/payment',
    }),
    ...mapState({
      loading: state => state.electricity.loading,
    }),
    breadcrumb() {
      return [
        {
          name: 'Архив',
          link: '/archive'
        },
        {
          name: 'Платежка'
        }
      ]
    },
  },

  async mounted() {
    const { id } = this.$route.params;
    await this.$store.dispatch('electricity/getPayment', id);
    this.$store.dispatch('electricity/getAverageConsupmtion', this.payment.bts);
    const act_id = (this.payment.electricity || {}).id;
    this.$store.dispatch('electricity/getElectricity', act_id);
  },
}
</script>
