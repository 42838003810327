import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import axios from "@/axios";
import vClickOutside from 'v-click-outside'
import VueSidebarMenu from 'vue-sidebar-menu';
import VueToast from 'vue-toast-notification';
import VueMeta from 'vue-meta';
import VueYandexMetrika from 'vue-yandex-metrika';
import VueMask from 'v-mask';
import VueApexCharts from 'vue-apexcharts';
import {
  LayoutPlugin,
  TablePlugin,
  PaginationPlugin,
  ButtonPlugin,
  DropdownPlugin,
  FormPlugin,
  FormCheckboxPlugin,
  FormGroupPlugin,
  FormInputPlugin,
  FormTextareaPlugin,
  FormSelectPlugin,
  FormFilePlugin,
  FormRadioPlugin,
  InputGroupPlugin,
  ModalPlugin,
  TabsPlugin,
  NavbarPlugin,
  SidebarPlugin,
  CollapsePlugin,
} from "bootstrap-vue"

Vue.use(VueSidebarMenu);
Vue.use(vClickOutside);
Vue.use(LayoutPlugin);
Vue.use(TablePlugin);
Vue.use(PaginationPlugin);
Vue.use(ButtonPlugin);
Vue.use(DropdownPlugin);
Vue.use(FormPlugin);
Vue.use(FormCheckboxPlugin);
Vue.use(FormGroupPlugin);
Vue.use(FormInputPlugin);
Vue.use(FormTextareaPlugin);
Vue.use(FormSelectPlugin);
Vue.use(FormFilePlugin);
Vue.use(FormRadioPlugin);
Vue.use(InputGroupPlugin);
Vue.use(ModalPlugin);
Vue.use(TabsPlugin);
Vue.use(NavbarPlugin);
Vue.use(SidebarPlugin);
Vue.use(CollapsePlugin);
Vue.use(VueToast, {
  position: 'top-right'
});
Vue.use(VueMeta);
Vue.use(VueMask);
Vue.use(VueApexCharts);
Vue.component('apexchart', VueApexCharts)

// Yandex Metrika
Vue.use(VueYandexMetrika, {
  id: 91672880,
  env: 'production',
  options: {
    clickmap:true,
    trackLinks:true,
    accurateTrackBounce:true,
    webvisor:true
  }
})

//css
import 'normalize.css/normalize.css'
import 'vue-toast-notification/dist/theme-sugar.css';

//mixin
import GlobalMixin from "./mixins/global.js";

Vue.mixin(GlobalMixin);


Vue.config.productionTip = false;

Vue.prototype.$axios = axios;

new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')
