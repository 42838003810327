import { mapState } from 'vuex';

export default {
  data() {
    return {
      monthDictionary: {
        1: 'Январь',
        2: 'Февраль',
        3: 'Март',
        4: 'Апрель',
        5: 'Май',
        6: 'Июнь',
        7: 'Июль',
        8: 'Август',
        9: 'Сентябрь',
        10: 'Октябрь',
        11: 'Ноябрь',
        12: 'Декабрь',
      },
    };
  },

  computed: {
    ...mapState({
      token: (state) => state.auth.token,
      currentUser: (state) => state.auth.user,
      userRole: (state) => state.auth.userRole,
    }),
  },

  methods: {
    prettyPrice(x) {
      if (typeof x !== 'number') return x;
      return `${x.toFixed(0).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ' ')}`;
    },
    getDateFormat(date) {
      const d = new Date(date);
      return `${(`0${d.getDate()}`).slice(-2)}.${(`0${d.getMonth() + 1}`).slice(-2)}.${d.getFullYear()}`;
    },
    getHoursNMinutes(date) {
      const d = new Date(date);
      return `${d.getHours()}:${d.getMinutes()}`;
    },
    getPrettyMonth(date) {
      const d = new Date(date);
      const m = d.getMonth() + 1;
      const y = d.getFullYear();
      return `${this.monthDictionary[m]} ${y}`;
    },
    getPaymentPeriod(start, end) {
      const startDt = this.getPrettyMonth(start);
      if (!end) return startDt;
      const endDt = this.getPrettyMonth(end);
      return startDt === endDt ? startDt : `${startDt} - ${endDt}`;
    },
    getMediumForPeriod({ start_dt, end_dt, total_sum, sum_payment }) {
      const sum = total_sum || sum_payment;
      if (!start_dt || !end_dt || !sum) return;
      let months = end_dt.split('-')[1] - start_dt.split('-')[1];
      if (months < 0) months = (12 - start_dt.split('-')[1]) + Number(end_dt.split('-')[1]);
      return sum / (months + 1);
    },
    toastNotification(type, message) {
      this.$toast.open({
        type,
        message,
      });
    },
    getReadingStatus(status) {
      if (!status) return;
      const statuses = {
        first: 'Первое показание',
        less_or_equal: 'Не превышает +10%',
        more: 'Превышает +10%',
      };
      return statuses[status.toLowerCase()] || status;
    },
    getReadingStatusClass(status) {
      if (!status) return;
      const classes = {
        first: 'info',
        less_or_equal: 'success',
        more: 'danger',
      };

      const classesRus = {
        'Первое показание': 'info',
        'Новые задачи': 'info',
        'Не превышает +10%': 'success',
        'Превышает +10%': 'danger',
        'Завершено': 'success',
        'На согласовании менеджером': 'warning',
        'Отклонен менеджером': 'danger',
        'Отклонен сотрудником': 'danger',
        'Не подписано': 'danger',
        'Просмотрено': 'warning',
        'Подписано': 'success',
        'Отклонено': 'danger',
        'В процессе': 'warning',
      }

      return classes[status.toLowerCase()] || classesRus[status];
    },
  },
};
