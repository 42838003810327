<template>
  <section class="oda-section tasks">
    <section-header
        :breadcrumb="breadcrumb"
        section="Платежка"
        back-link="/tasks"
    />

    <b-tabs class="oda-tabs">
      <b-tab
          v-for="(item, i) in items"
          :key="`oda-tab-item-${i}`"
          :title="getTab(i)"
          :active="i === 0"
      >
        <b-row>
          <b-col cols="12" md="8">
            <payment-status
                :status="payment.reading_status || (paymentGroup[i] || {}).reading_status"
                :date="payment.created_at || (paymentGroup[i] || {}).created_at"
            />

            <payment-form
                :form="item"
                :loading="loading"
                :decline-able="payment.label !== 'В процесее'"
                :approve-able="payment.label !== 'В процесее'"
                @decline="decline"
                @approve="approve"
            />
          </b-col>

          <b-col cols="12" md="4">
            <payment-user
                :user="payment.counterparty || (paymentGroup[i] || {}).counterparty"
            />
            <payment-pdf
                v-if="getPdfLinks(i).length"
                :links="getPdfLinks(i)"
                :counter_photo="payment.counter_photo"
            />
          </b-col>
        </b-row>

        <b-row>
          <b-col cols="12">
<!--            <div class="oda-card">-->
              <oda-table
                  :fields="historyFields"
                  :items="clientHistory"
                  custom-pagination
              />
<!--            </div>-->
          </b-col>
        </b-row>
      </b-tab>
    </b-tabs>
  </section>
</template>

<script>
import SectionHeader from "../../components/SectionHeader";
import PaymentStatus from "../../components/PaymentItem/Status";
import PaymentUser from "../../components/PaymentItem/User";
import PaymentForm from "../../components/PaymentItem/Form";
import PaymentPdf from "../../components/PaymentItem/Pdf";
import OdaTable from "../../components/OdaTable";
import {mapActions, mapGetters, mapState} from "vuex";
import paymentPage from "../../mixins/paymentPage";

export default {
  name: "Task",
  components: {
    SectionHeader,
    PaymentStatus,
    PaymentUser,
    PaymentForm,
    PaymentPdf,
    OdaTable,
  },
  mixins: [paymentPage],
  data() {
    return {
      breadcrumb: [
        {
          name: 'Задачи',
          link: '/tasks'
        },
        {
          name: 'Платежка'
        }
      ],
      loading: false,
      hasSeenSent: false,

      historyFields: [
        {
          key: 'bts',
          label: 'БТС',
          sortable: true,
        },
        {
          key: 'period',
          label: 'Период оплаты',
          sortable: true,
        },
        {
          key: 'tariff_rate',
          label: 'Тариф',
          sortable: true,
          searchable: false,
        },
        {
          key: 'usedKWt',
          label: 'Использовано кВт',
          sortable: true,
          searchable: false,
        },
        {
          key: 'total_sum',
          label: 'Сумма в тг',
          sortable: true,
          searchable: false,
        },
        {
          key: 'created_at',
          label: 'Дата платежа',
          sortable: true,
        },
        {
          key: 'label',
          label: 'Статус',
          sortable: true,
        },
      ],
    }
  },

  computed: {
    ...mapState({
      paymentGroup: state => state.tasks.taskGroup,
      clientHistory: state => state.tasks.clientHistory,
    }),
    ...mapGetters({
      payment: 'tasks/currentTask',
    }),
  },

  async mounted() {
    const { id } = this.$route.params;

    if (id.includes('-')) {
      const ids = id.split('-');
      await this.$store.dispatch('tasks/getTaskGroup', ids);
    } else await this.$store.dispatch('tasks/getTask', { id });
    const { bts } = (this.paymentGroup && this.paymentGroup[0]) ||this.payment;
    this.$store.dispatch('electricity/getAverageConsupmtion', bts);
    const { user } = (this.paymentGroup && this.paymentGroup[0]) ||this.payment;
    if (user && user.id) this.getClientHistory({ user_id: user.id });
  },

  beforeDestroy() {
    this.$store.commit('tasks/SET_TASK_GROUP', []);
  },

  methods: {
    ...mapActions('tasks', [
        'getClientHistory',
    ]),
    approve() {
      this.loading = true;
      const group_id = this.payment.group_id || this.paymentGroup[0].group_id;
      this.$store.dispatch('tasks/approveElectricityTask', group_id)
          .finally(() => {
            this.loading = false;
          });
    },
    decline(decline_message) {
      this.loading = true;
      const group_id = this.payment.group_id || (this.paymentGroup[0] && this.paymentGroup[0].group_id);

      this.$store.dispatch('tasks/declineElectricityTask', { group_id, decline_message })
          .finally(() => {
            this.loading = false;
          });
    },
    getTab(i) {
      if (this.paymentGroup.length) {
        return this.getPrettyMonth(this.paymentGroup[i].start_dt);
      } else {
        return this.getPrettyMonth(this.payment.start_dt);
      }
    }
  },
}
</script>
