import managerServices from "@/services/managerServices";
import errorService from "@/services/errorService";

const state = {
  manager_rents: [],
  sidebar_item: {},
  loading: false,
};

const getters = {};

const mutations = {
  SET_MANAGER_RENTS(state, payload) {
    state.manager_rents = payload;
  },
  SET_LOADING(state, payload) {
    state.loading = payload;
  },
  SET_SIDEBAR_ITEM(state, payload) {
    state.sidebar_item = payload;
  },
};

const actions = {
  async getManagerRents({ commit }) {
    try {
      const { data } = await managerServices.getRents();
      commit("SET_MANAGER_RENTS", data);
    } catch (e) {
      errorService.handleError(e);
    }
  },
  async signManagerRent({ commit }, params) {
    try {
      commit("SET_LOADING", true);
      await managerServices.signRents(params);
      errorService.onSuccess("Успешно отправлено на рассмотрение");
    } catch (e) {
      errorService.handleError(e);
    } finally {
      commit("SET_LOADING", false);
    }
  },
};

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions,
}