<template>
  <div class="profile-tabs oda-desktop-only">
    <oda-tabs :tabs="tabs">
      <template slot="counterparties">
        <oda-table
            :fields="fields"
            :items="items"
            row-clickable
            custom-pagination
            @rowClicked="goToCounterparty"
        />
      </template>
      <template slot="create">
        <counterparty-main />
      </template>
    </oda-tabs>
  </div>
</template>

<script>
import {mapActions, mapState} from "vuex";
import OdaTabs from "@/components/OdaTabs.vue";
import OdaTable from "@/components/OdaTable.vue";
import CounterpartyMain from "./CounterpartyMain";

export default {
  name: "EmployeeProfile",
  components: {
    OdaTabs,
    OdaTable,
    CounterpartyMain,
  },
  data() {
    return {
      tabs: [
        {
          name: 'counterparties',
          title: 'Арендодатели',
          icon: 'icon-user',
        },
        {
          name: 'create',
          title: 'Создать АД',
        },
      ],
      fields: [
        { key: 'name', label: 'Контрагент' },
        // { key: 'bts', label: 'Объект' },
        { key: 'email', label: 'Email' },
        { key: 'counterparty_type', label: 'Вид АД' },
        { key: 'phone_number', label: 'Whatsapp' },
      ]
    }
  },
  computed: {
    ...mapState({
      employeeCounterparties: state => state.profile.employeeCounterparties,
    }),
    items() {
      return this.employeeCounterparties.map(item => {
        return {
          name: item.name,
          // bts: 'bts',
          email: item.email,
          counterparty_type: item.is_entity ? 'Юр. Лицо': 'Физ. Лицо',
          expense_type2: `${item.electricity && item.electricity.length && 'ЭЭ'} ${item.rents && !item.rents.length && "Аренда"}`,
          id: item.id,
          phone_number: item.phone_number
        }
      })
    },
  },

  mounted() {
    if (this.currentUser && !this.items.length) {
      this.getCounterpartiesByEmployee(this.currentUser.id);
    }
  },

  methods: {
    ...mapActions('profile', [
      'getCounterpartiesByEmployee',
      'getCounterparty'
    ]),
    async goToCounterparty(counterparty) {
      this.$router.push(`/counterparty/${counterparty.id}`);
      await this.getCounterparty(counterparty.id);
    },
  },
}
</script>
