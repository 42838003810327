<template>
  <div class="registry">

    <div class="payments-body">
      <div class="payments-header">
        <h3>Оплата: Электроэнергия</h3>
      </div>

      <div class="payments-section">
        <div class="payments-section__header">
          <div class="payments-section__header-left">
            <button
                :disabled="groupedItems && !groupedItems.length"
                class="oda-button primary mr-3"
                @click="availablePick"
            >
              Выбрать
            </button>
          </div>

          <div class="payments-section__header-right">
            <div class="payments-section__header-right-item">
              <h4>Первое показание :</h4>
              <span class="info">{{ firstRegCount }}</span>
            </div>
            <div class="payments-section__header-right-item">
              <h4>Превышает 10% :</h4>
              <span class="warning">{{ over10Count }}</span>
            </div>
          </div>
        </div>

        <oda-table
            ref="electricity-table"
            :fields="fields"
            :items="groupedItems"
            :pickable="pickAvailable"
            :picked-items="pickedItems"
            :widths="widths"
            :disabled-columns="disabledColumns"
            custom-pagination
            row-clickable
            @pick="pickItem"
            @pickAll="pickAll"
            @rowClicked="openPayment"
        />
      </div>

      <button
          :class="{'loading': loading}"
          :disabled="!pickAvailable ||loading"
          class="oda-button primary"
          @click="showEcpModal"
      >
        Согласовать реестр
      </button>
    </div>
    <sidebar
        ref="sidebar"
        @hide="pickedItems = []"
    />

    <sign-modal
        ref="ecp-modal"
        :loading="loading"
        @send="sendPayments"
    />
  </div>
</template>

<script>
import {mapActions, mapGetters, mapState} from 'vuex';
import OdaTable from '@/components/OdaTable.vue';
import Sidebar from './sidebar.vue';
import SignModal from '@/components/SignEcpModal.vue'

export default {
  name: 'Electricity',
  components: {
    OdaTable,
    Sidebar,
    SignModal,
  },

  data() {
    return {
      fields: [
        {
          key: 'id',
          label: '',
        },
        {
          key: 'counterparty',
          label: 'Контрагент',
          sortable: false,
          searchable: false,
        },
        {
          key: 'bts',
          label: 'Объект',
          sortable: false,
          searchable: false,
        },
        {
          key: 'period',
          label: 'Период',
          sortable: false,
        },
        {
          key: 'total_sum',
          label: 'Сумма в тг',
          sortable: false,
          searchable: false,
        },
        {
          key: 'average_for_period',
          label: 'Среднее за месяц',
        },
        {
          key: 'reading_status',
          label: 'Статус',
          sortable: false,
        },
      ],
      pickedItems: [],
      pickAvailable: false,
      widths: [65],
      disabledColumns: [0],
    };
  },

  computed: {
    ...mapState({
      loading: (state) => state.electricity.loading,
      paymentGroup: state => state.tasks.taskGroup,
    }),
    ...mapGetters({
      items: 'electricity/managerItems',
      lossesItems: 'electricity/managerLossItems',
    }),
    over10Count() {
      return this.items.filter((item) => (item.electricity || {}).reading_status === 'MORE').length;
    },
    firstRegCount() {
      return this.items.filter((item) => (item.electricity || {}).reading_status === 'FIRST').length;
    },
    groupedItems() {
      if (!this.items.length) return [];
      let grouped = [];
      let group = {
        id: this.items[0].id,
        group_id: this.items[0].electricity.group_id,
        counterparty: this.items[0].electricity.counterparty,
        total_sum: this.items[0].contract_sum,
        reading_status: this.items[0].electricity.reading_status,
        created_at: this.items[0].electricity.created_at,
        bts: this.items[0].electricity.bts,
        start_dt: this.items[0].start_dt,
        group: []
      };
      let group_sum = 0;

      this.items.forEach(item => {
        if (!item.electricity) return;
        if (item.electricity.group_id === group.group_id) {
          group.group.push(item);
          this.$set(group, 'end_dt', item.end_dt);
          group_sum += item.contract_sum;
        } else {
          group.total_sum = group_sum;
          grouped.push(group);
          group = {};
          group_sum = item.contract_sum;
          this.$set(group, 'id', item.id);
          this.$set(group, 'group_id', item.electricity.group_id);
          this.$set(group, 'group', []);
          this.$set(group, 'reading_status', item.electricity.reading_status);
          this.$set(group, 'created_at', item.electricity.created_at);
          this.$set(group, 'bts', item.electricity.bts);
          this.$set(group, 'start_dt', item.start_dt);
          this.$set(group, 'end_dt', item.end_dt);
          this.$set(group, 'counterparty', item.electricity.counterparty);
          group.group.push(item);
        }
      })
      group.total_sum = group_sum;
      grouped.push(group);
      return grouped;

    }
  },

  mounted() {
    this.getManagerPayments();
  },
  methods: {
    ...mapActions('tasks', [
        'getClientHistory',
        'getTaskGroup',
    ]),
    ...mapActions('electricity', [
        'getAverageConsupmtion',
        'sendForPayment',
        'getManagerPayments',
    ]),
    async openPayment(row) {
      if (this.pickAvailable) {
        this.pickItem(row.id);
        return;
      }
      const ids = row.group.map(r => r.electricity.id);
      await this.getTaskGroup(ids);
      this.getAverageConsupmtion(row.bts)
      const { user } = (this.paymentGroup && this.paymentGroup[0]);
      if (user && user.id) await this.getClientHistory({ user_id: user.id });
      this.$refs.sidebar.toggle();
    },
    availablePick() {
      if (!this.pickAvailable) this.pickedItems = [];

      this.pickAvailable = !this.pickAvailable;

      this.fields.reverse();
      if (this.pickAvailable) this.fields.push({ key: 'id', label: '' });
      else this.fields.pop();
      this.fields.reverse();
    },
    pickItem(row) {
      const id = row.group_id;
      if (this.pickedItems.includes(id)) {
        this.pickedItems = this.pickedItems.filter((item) => item !== id);
      } else {
        this.pickedItems.push(`${id}`);
      }
    },
    pickAll() {
      if (this.pickedItems.length === this.groupedItems.length) this.pickedItems = [];
      else this.pickedItems = this.groupedItems.map((item) => item.group_id);
    },
    showEcpModal() {
      const component = this.$refs['ecp-modal'];
      component.$refs['modal'].show();
    },
    hideEcpModal() {
      const component = this.$refs['ecp-modal'];
      component.$refs['modal'].hide();
    },

    async sendPayments(ecp) {
      try {
        const formData = new FormData();
        formData.append('datacert', ecp.file);
        formData.append('password', ecp.password);
        formData.append('group_id', JSON.stringify(this.pickedItems));

        await this.sendForPayment(formData);
        this.$toast.open({
          type: 'success',
          message: 'Успешно отправлено на рассмотрение',
        });
      } catch (e) {
        const message = e.response.data;
        this.$toast.open({
          type: 'error',
          message: message || 'Что-то пошло не так. Попробуйте позже',
        });
      } finally {
        this.pickedItems = [];
        this.hideEcpModal();
        this.$refs.sidebar.hide();
      }
      this.pickAvailable = false;
      this.pickedItems = [];
      this.$refs['electricity-table'].$refs.table.refresh();
      this.getManagerPayments();
    },
  },
};
</script>
