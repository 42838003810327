import MainApiService from '@/services/api';

const ClientApiService = {
  getPaymentHistory(params) {
    return MainApiService.get('electricities/client/mine', params);
  },
  getLossesAllowed() {
    return MainApiService.get('electricity-losses/allowed');
  },
  getPayedMonths() {
    return MainApiService.get('electricities/client/payedMonths');
  },
  getVariables() {
    return MainApiService.get('variables');
  },
  generateAndSavePDF(params) {
    return MainApiService.post('electricities/client/send-counter-readings', params);
  },
  resendCounterReading(params) {
    return MainApiService.post('electricities/client/resend-counter-readings', params);
  },
  signRent(params) {
    return MainApiService.post('rent/client/sign-rent-contract', params);
  },
  getNeedsToSign() {
    return MainApiService.get('rents/client/needs-to-sign');
  },
  getSignedRents() {
    return MainApiService.get('rents/client/signed');
  },

  sendFeedback(params) {
    return MainApiService.post('terms-of-uses/send-feedback', params);
  },

  // new-contracts (/new-treaties) client requests
  getContractClient(params) {
    return MainApiService.get('new-treaties/client/uuid', { params });
  },
  declineNewTreatiesClient(params) {
    return MainApiService.post('new-treaties/client/decline', params);
  },
  approveNewTreatiesClient(params) {
    return MainApiService.post('new-treaties/client/approve', params);
  },
};

export default ClientApiService;
