<template>
  <section class="oda-section send-registry">
    <section-header
        :breadcrumb="breadcrumb"
        section="Платежка"
    />
    <b-form
        ref="rent-form"
        @submit.prevent="signEcpForm"
    >
      <b-row>
        <b-col cols="12" md="8">
          <payment-status
              status="Не подписано"
              :date="new Date()"
              date-value="Дата отправки"
          />
          <payment-form :form="form">
            <template v-slot:formGroup>
              <b-col :cols="isMobile ? 12 : 4">
                <oda-form-group
                    v-model="userDefinedUniqueCompletionCertificateId"
                    label="Номер АВР"
                    placeholder="Номер АВР"
                    required
                    integer
                    number
                />
              </b-col>

              <b-col :cols="isMobile ? 12 : 8">
                <div class="oda-badge danger mt-4">
                  * Если вы не знаете, какой номер указать, то укажите номер месяца.
                  Например: март - 3, декабрь - 12
                </div>
              </b-col>
            </template>
          </payment-form>

          <button
              class="oda-button primary"
              type="submit"
          >
            Подписать с ЭЦП
          </button>
        </b-col>

        <b-col cols="12" md="4">
          <payment-pdf :links="pdf" />
        </b-col>
      </b-row>
    </b-form>

    <b-modal
        ref="ecp-modal"
        :no-close-on-backdrop="loading"
        hide-header
        hide-footer
    >
      <oda-form-group
          v-model="cert_file"
          label="Ключ ЭЦП"
          placeholder="Выберите файл"
          class="file-ecp"
          accept=".p12"
          file
          required
      />
      <oda-form-group
          v-model="cert_password"
          label="Пароль"
          placeholder="Введите пароль"
          type="password"
          required
      />
      <button
          :class="{'loading': loading}"
          :disabled="loading"
          class="oda-button primary mt-4"
          @click="signRent"
      >
        Отправить
      </button>
    </b-modal>
  </section>
</template>

<script>
import SectionHeader from '../../components/SectionHeader.vue';
import PaymentStatus from '../../components/PaymentItem/Status.vue';
import PaymentForm from '../../components/PaymentItem/Form.vue';
import PaymentPdf from '../../components/PaymentItem/Pdf.vue';
import { mapState } from "vuex";
import OdaFormGroup from '@/components/OdaFormGroup.vue';

export default {
  name: 'SendRent',
  components: {
    SectionHeader,
    PaymentStatus,
    PaymentForm,
    PaymentPdf,
    OdaFormGroup,
  },
  data() {
    return {
      loading: false,
      cert_password: '',
      cert_file: null,
      breadcrumb: [
        {
          name: 'Аренда',
          link: '/rents',
        },
        {
          name: 'Платежка',
        },
      ],
      userDefinedUniqueCompletionCertificateId: 0,
    };
  },

  computed: {
    ...mapState ({
      pdf_preview_link: state => state.registry.pdf_preview_link,
      signed_pdf: state => state.registry.signed_rent_pdf,
      isMobile: state => state.isMobile,
    }),
    pdf() {
      return [{
        title: 'АВР',
        name: 'AVR',
        link: this.pdf_preview_link,
      }]
    },
    form() {
      const { query } = this.$route;
      const { isMobile } = this;
      return [
        {
          name: 'contract_code',
          value: query.contract_code,
          size: isMobile ? 12 : 8,
          label: 'Договор на поставку',
        },
        {
          name: 'bts',
          value: query.bts,
          size: isMobile ? 12 : 4,
          label: 'Объект',
        },
        {
          name: 'counterparty_payer',
          value: this.currentUser && this.currentUser.name,
          size: isMobile ? 12 : 8,
          label: 'Контрагент',
        },
        {
          name: 'expense_type',
          value: 'Аренда',
          label: 'Вид расхода',
          size: isMobile ? 12 : 4,
        },
        {
          name: 'payment_period',
          value: this.getPaymentPeriod(query.start_dt),
          label: 'Период оплаты',
          size: isMobile ? 12 : 4,
        },
        {
          name: 'nds_type',
          value: 'Без НДС',
          label: 'Ставка НДС',
          size: isMobile ? 12 : 4,
        },
        {
          name: 'total_sum',
          value: this.prettyPrice(Number(query.contract_sum)),
          label: 'Сумма в тг',
          size: isMobile ? 12 : 4,
        },
      ];
    },
  },

  mounted() {
    const { query } = this.$route;
    const end_dt = this.getLastDay(query.start_dt);

    const url =  'applications/pdf/render';
    const params = {
      contract_code: query.contract_code,
      end_dt,
      start_dt: query.start_dt,
      bts: query.bts,
      condition_type: 'аренда',
      application_name: 'completion_certificate',
    };
    this.$store.dispatch('registry/generatePDF', { url, params });
  },

  methods: {
    getLastDay(date) {
      const year = date.split('-')[0];
      const month = date.split('-')[1];
      const day = new Date(Number(year), month, 0).getDate();
      return `${year}-${month}-${day}`
    },
    signEcpForm() {
      this.$refs['ecp-modal'].show();
    },
    async signRent() {
      this.loading = true;
      const { query } = this.$route;
      const end_dt = this.getLastDay(query.start_dt);

      const formData = new FormData();
      const params = {
        bts: query.bts,
        contract_code: query.contract_code,
        start_dt: query.start_dt,
        end_dt,
        password: this.cert_password,
        userDefinedUniqueCompletionCertificateId: this.userDefinedUniqueCompletionCertificateId,
      };

      Object.values(params).forEach((value,i) => {
        const keys = Object.keys(params);
        formData.append(keys[i], value);
      })
      formData.append('datacert', this.cert_file);

      await this.$store.dispatch('registry/signRent', formData);
      this.loading = false;
    },
  },
};
</script>
