<template>
  <div class="oda-card send-registry__card">
    <div class="send-registry__card-title">
      <div class="send-registry__card-left">
        <span class="feather icon-file"></span>
        <h3 v-if="title">{{ title }}</h3>
        <h3 v-else>Данные по платежу</h3>
      </div>
    </div>
    <b-row>
      <b-col
          v-for="field in form"
          :key="field.name"
          :cols="field.size"
      >
        <oda-form-group
            :value="field.value"
            :label="field.label"
            :disabled="!field.active"
            :textarea="field.textarea"
            :bg-color="field.bgColor"
        />
      </b-col>
      <b-col
          v-if="declineAble"
          cols="12"
      >
        <oda-form-group
            v-model="decline_message"
            :disabled="loading"
            label="Комментарии"
            textarea
        />
      </b-col>
      <slot name="formGroup"></slot>
    </b-row>
    <b-row v-if="declineAble || approveAble">
      <b-col>
        <button
            v-if="declineAble"
            :disabled="loading"
            :class="{'loading': loading}"
            class="oda-button bordered danger"
            @click="decline"
        >
          Отправить на доработку
        </button>
      </b-col>
      <b-col class="justify-content-end d-flex">
        <button
            v-if="approveAble"
            :disabled="loading || getMessage(decline_message)"
            :class="{'loading': loading}"
            class="oda-button primary"
            @click="approve"
        >
          Отправить на согласование
        </button>
      </b-col>
    </b-row>
  </div>
</template>

<script>
import OdaFormGroup from "../OdaFormGroup";

export default {
  name: "Form",
  props: {
    form: Array,
    declineAble: Boolean,
    approveAble: Boolean,
    loading: Boolean,
    title: String,
  },
  components: {
    OdaFormGroup,
  },
  data() {
    return {
      decline_message: "",
    }
  },

  methods: {
    decline() {
      this.$emit('decline', this.decline_message);
    },
    approve() {
      this.$emit('approve');
    },
    getMessage(message) {
      return message.trim().length > 0;
    },
  },
}
</script>
