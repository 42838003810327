<template>
  <section class="oda-section electricity">
    <section-header section="Архив" />

    <div class="payments-body">
      <div class="payments-section">

        <oda-table
            :fields="fields"
            :items="items"
            row-clickable
            custom-pagination
            @rowClicked="openTreatie"
        />
      </div>
    </div>
  </section>
</template>

<script>
import { mapActions, mapState } from "vuex";
import SectionHeader from "@/components/SectionHeader";
import OdaTable from "@/components/OdaTable";

export default {
  name: "NewContractsArchive",
  components: {
    SectionHeader,
    OdaTable,
  },

  data() {
    return {
      fields: [
        { key: 'counterparty_name', label: 'Контрагент', sortable: true },
        { key: 'bts', label: 'Объект', sortable: true },
      ]
    }
  },

  computed: {
    ...mapState({
      items: state => state.treaties.archive
    })
  },

  mounted() {
    this.getNewTreatiesArchive();
  },
  methods: {
    ...mapActions('treaties', [
        'getNewTreatiesArchive',
    ]),
    openTreatie(row) {
      this.$router.push(`/new-contracts/${row.id}`)
    },
  },
}
</script>
