<template>
  <b-row>

    <b-col cols="12" md="4">
      <div class="oda-card new-treaties__docs">
        <h3 class="new-treaties__docs-header">Дополнительные документы:</h3>
        <ul v-if="additional_docs && additional_docs.length">
          <li
              v-for="item in additional_docs"
              :key="item.name"
          >
            <b v-if="item.date">{{ getHoursNMinutes(item.date) }}, {{ getDateFormat(item.date) }} </b>
            <a :href="item.url" target="_blank">{{ item.name }}</a>
          </li>
        </ul>

        <h3 class="new-treaties__docs-header">Договор:</h3>
        <ul v-if="treaty">
          <li>
            <b v-if="treaty.date">{{ getHoursNMinutes(treaty.date) }}, {{ getDateFormat(treaty.date) }} </b>
            <a :href="treaty.url" target="_blank">{{ treaty.name }}</a>
          </li>
        </ul>

      </div>
    </b-col>


    <b-col cols="12" md="4">
      <div class="oda-card new-treaties__docs">
        <div class="new-treaties__docs-head">
          <h3 class="new-treaties__docs-header">Лист согласования:</h3>
          <span v-if="approval_sheet" class="oda-badge success">Загружен</span>
          <span v-else class="oda-badge success">Не загружен</span>
        </div>
        <div class="new-treaties__docs-buttons">
          <button
              v-if="approval_sheet"
              class="oda-button primary"
              @click="downloadApprovalSheet"
          >
            Скачать
          </button>
          <button
              v-if="approvalSheetEnable"
              :disabled="disabled"
              class="oda-button primary"
              @click="$refs['upload-approval-input'].click()"
          >
            Загрузить новый
          </button>
        </div>

        <input
            ref="upload-approval-input"
            v-show="false"
            type="file"
            @input="addList"
        />
      </div>
    </b-col>

    <b-col cols="12" md="4">
      <div class="oda-card new-treaties__docs">
        <h3 class="new-treaties__docs-header">Новый договор:</h3>

        <div>{{ document && document.name || 'Договор не прикреплен' }}</div>
        <div class="new-treaties__docs-buttons">
          <button
              v-if="document"
              class="oda-button primary"
              @click="downloadNewTreaty"
          >
            Скачать
          </button>
          <button
              v-if="userRole === 'employee_new_treaty'"
              :disabled="disabled"
              class="oda-button primary"
              @click="$refs['upload-doc-input'].click()"
          >
            Загрузить новый
          </button>
        </div>

        <input
            ref="upload-doc-input"
            v-show="false"
            type="file"
            @input="uploadDocument"
        />
      </div>
    </b-col>
  </b-row>
</template>

<script>
import fileDownload from "js-file-download";
import {mapActions} from "vuex";

export default {
  name: "IdDocs",
  components: {},
  props: {
    treaty: Object,
    additional_docs: Array,
    document: [String, Object],
    approval_sheet: String,
    ciptracker_id: [String, Number],
    disabled: Boolean,
  },

  computed: {
    approvalSheetEnable() {
      return (this.userRole !== 'manager' && !this.employee_approved);
    },
  },

  methods: {
    ...mapActions('treaties', [
      'addListApproval',
      'uploadNewTreaty'
    ]),

    downloadDocuments() {
      if (this.additional_docs && this.additional_docs.length) {
        this.additional_docs.forEach( item => {
          const { url, name } = item;
          this.loading = true;
          this.$axios({
            url,
            method: 'GET',
            responseType: 'blob',
          }).then((res) => {
            fileDownload(res.data, name);
          }).finally(() => {
            this.loading = false;
          });
        })
      }
    },

    downloadTreaty() {
      const { url, name } = this.treaty;
      this.loading = true;
      this.$axios({
        url,
        method: 'GET',
        responseType: 'blob',
      }).then((res) => {
        fileDownload(res.data, name);
      }).finally(() => {
        this.loading = false;
      });
    },

    downloadNewTreaty() {
      const { url, name } = this.document;
      this.loading = true;
      this.$axios({
        url,
        method: 'GET',
        responseType: 'blob',
      }).then((res) => {
        fileDownload(res.data, name);
      }).finally(() => {
        this.loading = false;
      });
    },

    downloadApprovalSheet() {
      const url = this.approval_sheet;
      this.loading = true;
      this.$axios({
        url,
        method: 'GET',
        responseType: 'blob',
      }).then((res) => {
        fileDownload(res.data, 'approval_sheet');
      }).finally(() => {
        this.loading = false;
      });
    },

    async uploadDocument(e) {
      this.loading = true;
      const file = e.target.files[0];
      const formData = new FormData;
      formData.append('document', file);
      formData.append('ciptracker_id', this.ciptracker_id);
      try {
        await this.uploadNewTreaty(formData);
      } finally {
        this.loading = false;
      }
    },

    async addList(e) {
      this.loading = true;
      const file = e.target.files[0];
      const formData = new FormData;
      formData.append('approval_sheet', file);
      formData.append('ciptracker_id', this.ciptracker_id);
      try {
        await this.addListApproval(formData);
      } finally {
        this.loading = false;
      }
    },
  },
}
</script>
