<template>
  <section class="oda-section payment-page">
    <section-header
        :breadcrumb="breadcrumb"
        section="Платежка"
    />

    <b-tabs class="oda-tabs">
      <b-tab
          v-for="(item, i) in items"
          :key="`oda-tab-item-${i}`"
          :title="getTabTitle(i)"
          :active="i === 0"
      >
        <b-row>
          <b-col cols="12" md="8">
            <payment-status
                :status="payment.status"
                :date="payment.created_at"
                :decline_message="payment.error_message"
                @resend="resend"
            />

            <payment-form :form="item" />
          </b-col>

          <b-col cols="12" md="4">
            <payment-pdf
                v-if="getPdfLinks(i).length"
                :links="getPdfLinks(i)"
                :counter_photo="payment.counter_photo"
            />
          </b-col>
        </b-row>
      </b-tab>
    </b-tabs>
  </section>
</template>

<script>
import { mapGetters, mapState } from 'vuex';
import SectionHeader from '../../components/SectionHeader.vue';
import PaymentPdf from '../../components/PaymentItem/Pdf.vue';
import PaymentForm from '../../components/PaymentItem/Form.vue';
import PaymentStatus from '../../components/PaymentItem/Status.vue';
import paymentPage from '../../mixins/paymentPage';

export default {
  name: 'PaymentItem',
  components: {
    SectionHeader,
    PaymentStatus,
    PaymentForm,
    PaymentPdf,
  },
  mixins: [paymentPage],
  data() {
    return {
      breadcrumb: [
        {
          name: 'Электроэнергия',
          link: '/payment',
        },
        {
          name: 'Платежка',
        },
      ],
    };
  },

  computed: {
    ...mapGetters({
      payment: 'electricity/clientPayment',
    }),
    ...mapState({
      client_type: (state) => state.auth.client_type,
    }),
  },

  async mounted() {
    const { id } = this.$route.params;
    await this.$store.dispatch('electricity/getElectricity', id);
    this.$store.dispatch('electricity/getAverageConsupmtion', this.payment.bts);
  },

  methods: {
    resend() {
      const { payment } = this;
      const { id } = payment || {};
      const params = {
        counter_value: payment.counter_value,
        counter_previous_value: payment.counter_previous_value,
        bts_name: payment.bts,
        period: `${payment.start_dt}/${payment.end_dt}`,
        contract_code: payment.contract_code,
        tariff_rate: payment.tariff_rate,
        id,
        group_id: payment.group_id,
      };
      this.$store.commit('electricity/SET_RESEND_ITEM', params);

      const paymentId = this.$route.params.id;
      const routeQuery = { resend: 'true', id: paymentId };
      this.$router.push({ path: `/send-counter-readings/${this.client_type}`, query: routeQuery });
    },
  },
};
</script>
