import managerServices from "@/services/managerServices";
import errorService from "@/services/errorService";

const state = {
  entity_clients_count: 0,
  individual_clients_count: 0,
  rents_analytics: [],
  electricities_analytics: [],
  users_analytics: {},
};

const getters = {};

const mutations = {
  SET_ENTITY_CLIENTS_COUNT(state, payload) {
    state.entity_clients_count = payload;
  },
  SET_INDIVIDUAL_CLIENTS_COUNT(state, payload) {
    state.individual_clients_count = payload;
  },
  SET_USERS_ANALYTICS(state, payload) {
    state.users_analytics = payload;
  },
  SET_RENTS_ANALYTICS(state, payload) {
    state.rents_analytics = payload.year || payload;
  },
  SET_ELECTRICITIES_ANALYTICS(state, payload) {
    state.electricities_analytics = payload.year || payload;
  },
};

const actions = {
  async getEntityClientsCount({ commit }) {
    try {
      const { data } = await managerServices.getEntityClientsCount();
      commit("SET_ENTITY_CLIENTS_COUNT", data);
    } catch (e) {
      errorService.handleError(e);
    }
  },
  async getIndividualClientsCount({ commit }) {
    try {
      const { data } = await managerServices.getIndividualClientsCount();
      commit("SET_INDIVIDUAL_CLIENTS_COUNT", data);
    } catch (e) {
      errorService.handleError(e);
    }
  },
  async getUsersInvolvementAnalytics({ commit }) {
    try {
      const { data } = await managerServices.getUsersInvolvementAnalytics();
      commit("SET_USERS_ANALYTICS", data);
    } catch (e) {
      errorService.handleError(e);
    }
  },
  async getRentsAnalytics({ commit }) {
    try {
      const { data } = await managerServices.getRentsAnalytics();
      commit("SET_RENTS_ANALYTICS", data);
    } catch (e) {
      errorService.handleError(e);
    }
  },
  async getElectricitiesAnalytics({ commit }) {
    try {
      const { data } = await managerServices.getElectricitiesAnalytics();
      commit("SET_ELECTRICITIES_ANALYTICS", data);
    } catch (e) {
      errorService.handleError(e);
    }
  },
};

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions,
}