<template>
  <div class="mobile-payments">
    <div class="oda-section-mobile__header mb-2">
      Электроэнергия
    </div>
    <button
        class="oda-button primary mb-4"
        :disabled="hasDeclinedCounterReading"
        @click="toSendCounterReading"
    >
      Отправить показания счетчика
    </button>
    <div class="oda-section-mobile__header mb-2">
      Платежи
    </div>

    <div class="payments-section__info oda-card">
      <div
        v-for="item in statusInfo"
        :key="item.name"
        class="payments-section__info-item"
      >
        <div>
          <span
            :class="item.badge"
            class="oda-badge"
          >
            {{ item.name }}
          </span>
        </div>
        <div v-html="item.text"></div>
      </div>
    </div>
<!--    <div class="mobile-payments__filters">-->
<!--      <oda-form-group-->
<!--          v-for="filter in filterFields"-->
<!--          v-model="filters[filter.key]"-->
<!--          :key="`filter-${filter.key}`"-->
<!--          :options="filterOptions(filter.key)"-->
<!--          :placeholder="filter.label"-->
<!--          multiselect-->
<!--          @input="getHistory"-->
<!--      />-->
<!--    </div>-->
    <div
        v-for="item in items"
        :key="item.id"
        class="mobile-payment oda-card"
        @click="openPayment(item)"
    >
      <div
          v-for="field in fieldsMobile"
          :key="field.key"
          class="mobile-payment__item"
      >
        <div class="mobile-payment__key">
          <span
              v-if="field.icon"
              :class="field.icon"
              class="feather"
          ></span>
          {{ field.label }}
        </div>
        <div class="mobile-payment__value">
          <div v-if="field.key === 'period'">{{ getPrettyMonth(item.start_dt) }}</div>
          <div v-else-if="field.key === 'created_at'">{{ getDateFormat(item[field.key]) }}</div>
          <div v-else-if="field.key === 'label'" :class="getStatusClass(item[field.key])" class="oda-badge">{{ item[field.key] }}</div>
          <div v-else-if="field.key === 'total_sum'">{{ prettyPrice(item[field.key]) }}</div>
          <div v-else>{{ item[field.key] }}</div>
        </div>
      </div>
    </div>
    <div
        v-if="!items.length"
        class="mobile-payment__empty"
    >
      Нет доступных платежек
    </div>
  </div>
</template>

<script>
import OdaFormGroup from "../../components/OdaFormGroup";
import {mapActions, mapGetters, mapState} from "vuex";

export default {
  name: "Electricity",
  components: {
    // eslint-disable-next-line vue/no-unused-components
    OdaFormGroup,
  },
  data() {
    return {
      fieldsMobile: [
        { key: 'contract_name', label: 'Договор', icon: 'icon-file', colored: "primary" },
        { key: 'bts', label: 'Объект', icon: 'icon-radio', colored: "primary" },
        { key: 'period', label: 'Период оплаты' },
        { key: 'total_sum', label: 'Сумма в тг' },
        { key: 'created_at', label: 'Дата платежа' },
        { key: 'label', label: 'Статус' },
      ],
      filterFields: [
        { key: 'counterparty_contract', label: 'Договор' },
        { key: 'bts', label: 'Объект' },
      ],
      filters: {},

      statusInfo: [
        {
          name: 'Новые задачи',
          badge: 'warning',
          text: 'Ваше показание поступило к сотруднику',
        },
        {
          name: 'В процессе',
          badge: 'warning',
          text: 'Мы превратили показание в платеж, и теперь наша начальница подпишет его с помощью ЭЦП',
        },
        {
          name: 'Завершено',
          badge: 'success',
          text: 'Деньги у вас - бегите проверять банковский счет. <br> Если деньги не пришли, то напишите своему менеджеру',
        },
        {
          name: 'На доработке',
          badge: 'danger',
          text: 'Что-то пошло не так. Давайте переотправим показание? Просто нажмите на них',
        },
        {
          name: 'Просмотренные',
          badge: 'warning',
          text: 'Сотрудник проверяет ваши киловатты и тенге',
        },
      ],
    }
  },
  computed: {
    ...mapGetters ({
      clientHistory: 'registry/clientHistory',
    }),
    ...mapState({
      client_type: state => state.auth.client_type,
    }),
    items() {
      let items = this.clientHistory;
      Object.keys(this.filters).forEach(key => {
        if (this.filters[key].length) {
          items = items.filter(item => this.filters[key].includes(item[key]))
        }
      });
      return items;
    },
    hasDeclinedCounterReading() {
      const declined = this.clientHistory.filter((item) => item.label === 'На доработке');
      return declined.length > 0;
    },
  },

  mounted() {
    this.getPaymentHistory();
  },

  methods: {
    ...mapActions('registry', [
      'getPaymentHistory',
    ]),
    openPayment(row) {
      this.$router.push(`/m/payment/${row.id}`)
    },
    getStatusClass(status) {
      if (!status) return;
      const classes = {
        info: ['первое показание', 'первое показание'],
        warning: ['превышает +10%', 'в обработке', 'на рассмотрении', 'в процессе'],
        success: ['отличается на <+10%', 'не превышает +10%', 'оплачен', 'завершено'],
        danger: ['отличается на >+10%', 'отклонено', 'на доработке',]
      };
      const color = Object.keys(classes).filter(item => classes[item].includes(status.toLowerCase()));
      return color[0] || 'warning';
    },
    filterOptions(value) {
      const list = [];
      this.clientHistory.forEach(item => {
        if (!list.includes(item[value])) {
          list.push(item[value]);
        }
      });
      return list;
    },
    getHistory() {},
    toSendCounterReading() {
      this.$router.push(`/send-counter-readings/${this.client_type}`)
    }
  },
}
</script>
