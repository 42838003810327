<template>
  <div class="oda-section">
    <b-row
        v-if="contract && contract.document"
        class="mb-3"
    >
      <b-col cols="6">
        <div class="oda-card">
          <div class="new-treaties__client-info">
            ДОКУМЕНТ ПОДПИСАН ЭЛЕКТРОННОЙ ПОДПИСЬЮ
            <br>ЭЦП юридического лица (поставщика)
            <br>Серийный номер сертификата:
            <br>{{ (contract.managerCertificate || {}).serialNumber }}
            <br>Владелец:
            <br>{{ contract.managerCertificate.subject.commonName }}
            <br>Действителен до:
            <br>{{ contract.managerCertificate.validity.notAfter }}
          </div>
        </div>
        <div v-if="contract.clientCertificate" class="oda-card mt-3">
          <div class="new-treaties__client-info">
            ДОКУМЕНТ ПОДПИСАН ЭЛЕКТРОННОЙ ПОДПИСЬЮ
            <br>ЭЦП юридического лица (поставщика)
            <br>Серийный номер сертификата:
            <br>{{ contract.clientCertificate.serialNumber }}
            <br>Владелец:
            <br>{{ contract.clientCertificate.subject.commonName }}
            <br>Действителен до:
            <br>{{ contract.clientCertificate.validity.notAfter }}
          </div>
        </div>
      </b-col>
      <b-col cols="6">
        <div class="oda-card">
          <VueDocPreview :value="contract.document.url" type="office" />
          <button
              class="oda-button primary mt-2 mb-2"
              @click="downloadFile"
          >
            Скачать документ
          </button>
        </div>
      </b-col>
    </b-row>

    <b-row v-if="!actionsDisabled">
      <b-col cols="12">
        <div class="oda-card mb-3">
          <oda-form-group
              v-model="decline_message"
              label="Причина отказа"
              textarea
              required
          />
        </div>
      </b-col>
      <b-col cols="12">
        <div class="oda-card new-treaties-item__footer">
          <button
              :disabled="loading || !decline_message"
              class="oda-button danger"
              @click="decline"
          >
            Отказать в подписании
          </button>
          <button
              :disabled="loading"
              class="oda-button primary"
              @click="approve"
          >
            Подписать договор
          </button>
        </div>
      </b-col>

      <!--    ecp modal-->
      <b-modal
          ref="ecp-modal"
          :no-close-on-backdrop="loading"
          hide-header
          hide-footer
      >
        <b-form @submit.prevent="submit">
          <oda-form-group
              v-model="file"
              label="Ключ ЭЦП"
              placeholder="Выберите файл"
              class="file-ecp"
              accept=".p12"
              file
              required
          />
          <oda-form-group
              v-model="password"
              label="Пароль"
              placeholder="Введите пароль"
              type="password"
              required
          />
          <button
              :class="{'loading': loading}"
              :disabled="loading"
              class="oda-button primary mt-4"
              type="submit"
          >
            Отправить
          </button>
        </b-form>
      </b-modal>
    </b-row>
  </div>
</template>

<script>
import {mapActions, mapState} from "vuex";
import OdaFormGroup from "../../../components/OdaFormGroup";
import VueDocPreview from 'vue-doc-preview';
import fileDownload from "js-file-download";

export default {
  name: "NewContractsClient",
  components: {
    OdaFormGroup,
    VueDocPreview,
  },
  data() {
    return {
      loading: false,
      file: null,
      password: '',
      decline_message: '',
    }
  },
  computed: {
    ...mapState({
      contract: state => state.treaties.client_contract,
    }),
    uuid() {
      return this.$route.params.uuid;
    },
    actionsDisabled() {
      if (!this.contract) return false;
      return this.contract.status === 'Отклонен АД' || this.contract.archived;
    },
  },
  mounted() {
    const params = {
      uuid: this.uuid,
    };
    this.getContractClient(params);
  },
  methods: {
    ...mapActions('treaties', [
        'getContractClient',
        'declineNewTreatiesClient',
        'approveNewTreatiesClient',
    ]),
    downloadFile() {
      const { url, name } = this.contract && this.contract.document;
      this.loading = true;
      this.$axios({
        url,
        method: 'GET',
        responseType: 'blob',
      }).then((res) => {
        fileDownload(res.data, name);
      }).finally(() => {
        this.loading = false;
      });
    },
    async decline() {
      this.loading = true;
      const params = {
        uuid: this.uuid,
        client_message: this.decline_message,
      }
      try {
        await this.declineNewTreatiesClient(params);
      } finally {
        this.loading = false;
      }
    },
    approve() {
      this.$refs['ecp-modal'].show();
    },
    async submit() {
      this.loading = true;
      const formData = new FormData;
      formData.append('datacert', this.file);
      formData.append('password', this.password);
      formData.append('uuid', this.uuid);
      formData.append('client_message', this.decline_message);
      try {
        await this.approveNewTreatiesClient(formData);
        this.$refs['ecp-modal'].hide();
      } finally {
        this.loading = false;
      }
    },
  },
}
</script>
