<template>
  <b-col cols="4">
    <div class="oda-card">
      <h4>Зарегистрированные АД</h4>
      <apexchart
          width="380"
          type="donut"
          :options="clientsOptions"
          :series="clientsSeries"
      ></apexchart>
    </div>
  </b-col>
</template>

<script>
import {mapActions, mapState} from "vuex";

export default {
  name: "ClientsCountChart",
  data() {
    return {
      clientsOptions: {
        labels: ['Физ. лица', 'Юр. лица'],
        dataLabels: {
          enabled: true,
          formatter: function (val, opt) {
            return opt.w.config.series[opt.seriesIndex];
          },
        },
        plotOptions: {
          pie: {
            donut: {
              labels: {
                show: true,
                total: {
                  show: true,
                  label: "Всего"
                }
              }
            }
          }
        }
      },
    }
  },

  computed: {
    ...mapState({
      entity_clients_count: state => state.dashboard.entity_clients_count,
      individual_clients_count: state => state.dashboard.individual_clients_count,
    }),
    total_client_count() {
      return Number(this.entity_clients_count) + Number(this.individual_clients_count);
    },
    clientsSeries() {
      return [Number(this.individual_clients_count), Number(this.entity_clients_count)]
    },
  },

  mounted() {
    this.getEntityClientsCount();
    this.getIndividualClientsCount();
  },

  methods: {
    ...mapActions('dashboard', [
      'getEntityClientsCount',
      'getIndividualClientsCount',
    ])
  },
}
</script>
