import commonRoleServices from "@/services/commonRoleServices";
import employeeServices from "@/services/employeeServices";
import errorService from "@/services/errorService";
import router from "../../router";

const state = {
    electricity_tasks: [],

    currentTask: {},
    taskGroup: [],

    clientHistory: [],
};

const getters = {
    currentTask: state => {
        const { payment } = state.currentTask;
        return {...payment, ...state.currentTask};
    },

    electricityTasksSorted: state => {
        return state.electricity_tasks.sort((a, b) => {
            if (a.id > b.id) {
                return -1;
            }
            if (a.id < b.id) {
                return 1;
            }
            return 0;
        });
    },
};

const mutations = {
    SET_TASKS(state, data) {
        state.electricity_tasks = data;
    },
    SET_CURRENT_TASK(state, payload) {
        state.currentTask = payload;
    },
    SET_TASK_GROUP(state, payload) {
        state.taskGroup = payload;
    },
    SET_CLIENT_HISTORY(state, payload) {
        state.clientHistory = payload;
    },
};

const actions = {
    async getTasks({ commit }, type) {
        try {
            const { data } = await employeeServices.getTasks(type);
            commit('SET_TASKS', data);
        } catch (e) {
            errorService.handleError(e);
        }
    },
    async getTask({ commit }, { id }) {
        try {
            let data = await commonRoleServices.getElectricity(id);
            commit('SET_CURRENT_TASK', data.data);
        } catch (e) {
            errorService.handleError(e);
        }
    },
    async getTaskGroup({ commit }, ids ) {
        try {
            let group = [];
            for (let i = 0; i < ids.length; i++) {
                await commonRoleServices.getElectricity(ids[i]).then(data => {
                    group.push(data.data);
                });
            }
            commit('SET_TASK_GROUP', group);
        } catch (e) {
            errorService.handleError(e);
        }
    },
    async approveElectricityTask({ commit }, id) {
        try {
            const { data } = await employeeServices.approveElectricityTask(id);
            commit('SET_CURRENT_TASK', data);
            await router.push('/tasks');
            errorService.onSuccess("Задача перешла в раздел 'В процессе'");
        } catch (e) {
            errorService.handleError(e);
        }
    },

    async declineElectricityTask(_, params) {
        try {
            await employeeServices.declineElectricityTask(params);
            await router.push('/tasks');
            errorService.onSuccess("Задача успешно отклонена");
        } catch (e) {
            errorService.handleError(e);
        }
    },

    viewElectrictyTask(_, id) {
        employeeServices.viewElectrictyTask(id);
    },

    async getClientHistory({ commit }, params) {
        try {
            const { data } = await employeeServices.getClientHistory(params);
            commit('SET_CLIENT_HISTORY', data);
        } catch (e) {
            errorService.handleError(e);
        }
    }
};

export default {
    namespaced: true,
    state,
    getters,
    mutations,
    actions,
}
