
export default {
    computed: {
        items() {
            const average_consumption = this.$store.state.electricity.average_consumption || {};
            const form = [
                {
                    name: 'contract_name',
                    value: this.payment.contract_name,
                    size: 8,
                },
                {
                    name: 'bts',
                    value: this.payment.bts,
                    size: 4,
                },
                {
                    name: 'counterparty',
                    value: this.payment.counterparty,
                    size: 8,
                    label: 'Контрагент',
                },
                {
                    name: 'expense_type',
                    value: (this.payment.application || {}).condition_type,
                    label: 'Вид расхода',
                    size: 4,
                },
                {
                    name: 'period',
                    value: this.getPaymentPeriod(this.payment.start_dt, this.payment.end_dt),
                    label: 'Период оплаты',
                    size: 4,
                },
                {
                    name: 'sf_number',
                    value: this.payment.id,
                    label: 'Входящий номер АФИП',
                    size: 4,
                },
                {
                    name: 'income_date',
                    value: this.getDateFormat((this.payment.application || {}).pdf_dt),
                    label: 'Входящая дата',
                    size: 4,
                },
                {
                    name: 'counter_previous_value',
                    value: this.payment.counter_previous_value,
                    label: 'Предыдущее показание в кВт',
                    size: 4,
                },
                {
                    name: 'counter_value',
                    value: this.payment.counter_value,
                    label: 'Текущее показание в кВТ',
                    size: 4,
                },
                {
                    name: 'used_kwt',
                    value: this.payment.counter_value - this.payment.counter_previous_value,
                    label: 'Потреблено в кВт',
                    size: 4,
                },
                {
                    name: 'tariff_rate',
                    value: this.payment.tariff_rate,
                    label: 'Тариф в тг',
                    size: 4,
                },
                {
                    name: 'current_payment_amount',
                    value: this.prettyPrice(this.payment.total_sum),
                    label: 'Сумма в тг',
                    size: 4,
                },
                // {
                //     name: 'medium_for_months',
                //     value: this.prettyPrice(this.getMediumForPeriod(this.payment)),
                //     label: 'Среднее за месяц',
                //     size: 4,
                // },
                {
                    name: 'medium_electricty_for_months',
                    value: this.prettyPrice(average_consumption.averageSumPerMonth),
                    label: 'Средняя сумма потребления ЭЭ',
                    size: 4,
                },
            ];

            const items = [];
            if (this.paymentGroup && (this.paymentGroup).length) {
                this.paymentGroup.forEach(item => {
                    const form_item = [
                        {
                            name: 'counterparty_payer_contract',
                            value: item.contract_name,
                            size: 8,
                        },
                        {
                            name: 'bts',
                            value: item.bts,
                            size: 4,
                        },
                        {
                            name: 'counterparty_payer',
                            value: item.counterparty,
                            size: 8,
                            label: 'Контрагент',
                        },
                        {
                            name: 'expense_type',
                            value: 'ЭЭ',
                            label: 'Вид расхода',
                            size: 4,
                        },
                        {
                            name: 'period',
                            value: this.getPrettyMonth(item.start_dt),
                            label: 'Период оплаты',
                            size: 4,
                        },
                        {
                            name: 'sf_number',
                            value: item.uniqueCompletionCertificateId || item.id,
                            label: 'Входящий номер АВР',
                            size: 4,
                        },
                        {
                            name: 'income_date',
                            value: this.getDateFormat((item.application || {}).pdf_dt),
                            label: 'Входящая дата',
                            size: 4,
                        },
                        {
                            name: 'counter_previous_value',
                            value: item.counter_previous_value,
                            label: 'Предыдущее показание в кВт',
                            size: 4,
                        },
                        {
                            name: 'counter_value',
                            value: item.counter_value,
                            label: 'Текущее показание в кВТ',
                            size: 4,
                        },
                        {
                            name: 'used_kwt',
                            value: item.counter_value - item.counter_previous_value,
                            label: 'Потреблено в кВт',
                            size: 4,
                        },
                        {
                            name: 'tariff_rate',
                            value: item.tariff_rate,
                            label: 'Тариф в тг',
                            size: 4,
                        },
                        {
                            name: 'current_payment_amount',
                            value: this.prettyPrice(item.total_sum),
                            label: 'Сумма в тг',
                            size: 4,
                        },
                        {
                            name: 'medium_electricty_for_months',
                            value: this.prettyPrice(average_consumption.averageSumPerMonth),
                            label: 'Средняя сумма потребления ЭЭ',
                            size: 4,
                        },
                    ];
                    items.push(form_item);
                })
            } else if (Object.values(this.payment).length) return [form];
            return items;
        },
    },

    // mounted() {
    //     this.$store.dispatch('electricity/getAverageConsupmtion', this.payment.bts);
    // },

    methods: {
        getPdfLinks(i) {
            const links = [];
            if (this.payment.application && this.payment.application.signed_pdf) {
                links.push({
                    title: this.payment.user.is_entity ? 'АВР' : 'АФИП',
                    name: 'AVR',
                    link: (this.payment.application || {}).signed_pdf,
                });
            } else if (this.payment.signed_pdf) {
                links.push({
                    title: this.currentUser.is_entity ? 'АВР' : 'АФИП',
                    name: 'AFIP',
                    link: this.payment.signed_pdf,
                });
            } else if (this.paymentGroup && ((this.paymentGroup[i] || {}).application || {}).signed_pdf) {
                links.push({
                    title: this.currentUser.is_entity ? 'АВР' : 'АФИП',
                    name: 'AVR',
                    link: this.paymentGroup[i].application.signed_pdf,
                });
            }
            return links;
        },
        getTabTitle(i) {
            if (this.paymentGroup && this.paymentGroup[i])
                return this.getPrettyMonth(this.paymentGroup[i].start_dt);
            return "Электроэнергия";
        },
        getIncomeDate(item, type) {
            if (type === 'Юр. лицо') {
                return this.getDateFormat(item.end_dt || item.state.end_dt);
            } else {
                return this.getDateFormat(item.updated_at || item.created_at);
            }
        }
    },
}