<template>
  <b-modal
      ref="modal"
      :no-close-on-backdrop="loading"
      hide-header
      hide-footer
      @hide="ecp = {}"
  >
    <b-form
        @submit.prevent="send"
    >
      <oda-form-group
          v-model="ecp.file"
          label="Ключ ЭЦП"
          placeholder="Выберите файл"
          class="file-ecp"
          accept=".p12"
          file
          required
      />
      <oda-form-group
          v-model="ecp.password"
          label="Пароль"
          placeholder="Введите пароль"
          type="password"
          required
      />
      <button
          :class="{'loading': loading}"
          :disabled="loading"
          class="oda-button primary mt-4"
          type="submit"
      >
        Отправить
      </button>
    </b-form>
  </b-modal>
</template>

<script>
import OdaFormGroup from "@/components/OdaFormGroup.vue";

export default {
  name: "ElectricitySignModal",
  components: {
    OdaFormGroup,
  },
  props: {
    loading: Boolean,
  },

  data() {
    return {
      ecp: {},
    }
  },

  methods: {
    send() {
      this.$emit('send', this.ecp)
    }
  },
}
</script>
