<template>
  <div class="oda-card mb-3">
    <oda-tabs :tabs="tabs">
      <template slot="electricity">
        <oda-table
            :fields="electricityFields"
            :items="electricity"
            custom-pagination
        />
      </template>
      <template slot="rent">
        <oda-table
            :fields="rentsFields"
            :items="rents"
            custom-pagination
        />
      </template>
    </oda-tabs>
  </div>
</template>

<script>
import OdaTabs from "@/components/OdaTabs";
import OdaTable from "@/components/OdaTable";
import {mapState} from "vuex";

export default {
  name: "CounterpartyHistory",
  components: {
    OdaTabs,
    OdaTable,
  },

  data() {
    return {
      tabs: [
        {
          name: 'electricity',
          title: 'Элетричество',
        },
        {
          name: 'rent',
          title: 'Аренда',
        },
      ],
      electricityFields: [
        {
          key: 'contract_name',
          label: 'Договор',
          sortable: true,
          searchable: false,
        },
        {
          key: 'bts',
          label: 'Объект',
          sortable: true,
          searchable: false,
        },
        {
          key: 'period',
          label: 'Период оплаты',
          sortable: true,
        },
        {
          key: 'total_sum',
          label: 'Сумма в тг',
          sortable: true,
          searchable: false,
        },
        {
          key: 'created_at',
          label: 'Дата платежа',
          sortable: true,
        },
        {
          key: 'label',
          label: 'Статус',
          sortable: true,
        },
      ],
      rentsFields: [
        {
          key: 'contract_code',
          label: 'Договор',
          sortable: true,
          searchable: false,
        },
        {
          key: 'bts',
          label: 'Объект',
          sortable: true,
          searchable: false,
        },
        {
          key: 'period',
          label: 'Месяц',
          sortable: true,
          searchable: false,
        },
        {
          key: 'total_sum',
          label: 'Сумма в тг',
          sortable: true,
          searchable: false,
        },
        {
          key: 'ecp',
          label: 'ЭЦП',
          sortable: true,
        },
      ],
    }
  },

  computed: {
    ...mapState({
      electricity: state => state.tasks.clientHistory,
      rents: state => state.registry.signed_rents,
    })
  },

  mounted() {},
  methods: {},
}
</script>
