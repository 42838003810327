<template>
  <div class="du-header unauthorized">
    <div class="du-header__logo">
      <img src="@/assets/images/tele2-logo.svg" alt="tele2">
    </div>
    <div class="du-header__user">
      <div class="du-header__user-name">
        {{ client_details.counterparty }}
      </div>
      <div class="du-header__user-position">
        {{ client_details.type }}
      </div>
    </div>
  </div>
</template>

<script>
import { mapState } from "vuex";

export default {
  name: "DocumentsUpdateHeader",
  computed: {
    ...mapState({
      client_details: state => state.documentsUpload.client_details,
    }),
  },
}
</script>
