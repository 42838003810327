import Vue from 'vue';
import VueRouter from 'vue-router';
import Electricity from '../views/Registry/Electricity/index.vue';
import Tasks from '../views/Tasks/index.vue';
import Task from '../views/Tasks/id.vue';
import ElectricityTasks from '../views/Tasks/Electricity.vue'
import IsesfTasks from '../views/Tasks/Isesf/index.vue'
import IsesfTask from "../views/Tasks/Isesf/id";
import Auth from '../views/Auth.vue';
import Profile from '../views/Profile/index.vue';
import EmployeeCounterpartyProfile from '../views/Profile/Emplyee/Counterparty.vue';
import Payment from '../views/Payment/index.vue';
import PaymentItem from '../views/Payment/_id.vue';
import NotFound from '../views/NotFound.vue';
import Archive from '../views/Archive/index.vue';
import ArchiveItem from '../views/Archive/_id.vue';
import Mobile from '../views/Mobile/index.vue';
import MobilePayment from '../views/Mobile/Payment.vue';
import MobilePaymentItem from '../views/Mobile/PaymentItem.vue';
import MobileRents from '../views/Mobile/Rents.vue';
import Faq from '../views/Faq.vue';
import TermsOfUse from '../views/TermsOfUse';
import DocumentsUpdate from '../views/DocumentsUpdate/Client/index.vue';
import DocumentsUpdateForm from '../views/DocumentsUpdate/Client/form.vue';
import DocumentsUpdateSuccess from '../views/DocumentsUpdate/Client/success.vue';
import DocumentsUpdateEmployee from '../views/DocumentsUpdate/Employee/index.vue';
import DocumentsUpdateEmployeeItem from '../views/DocumentsUpdate/Employee/_id.vue';
import SendCounterReadingsIndividual from '../views/SendCounterReadings/Individual/index.vue';
import SendCounterReadingsEntrepreneur from '../views/SendCounterReadings/Entrepreneur/index.vue';
import SendRent from '../views/SendRent/index.vue';
import Rents from '../views/Rents/index.vue';
import ManagerRents from '../views/Registry/Rent/index.vue';
import RentsItem from '../views/Rents/_id.vue';
import NewContracts from '../views/NewContracts/index.vue';
import NewContractsArchive from '../views/NewContracts/Archive.vue';
import NewContractsItem from '../views/NewContracts/Id';
import NewContractsManager from '../views/NewContracts/Manager';
import NewContractsClient from '../views/NewContracts/Client/index.vue'
import Isesf from "../views/Registry/Isesf/index.vue";
import Registry from "../views/Registry/index.vue"

import guest from './middleware/guest';
import auth from './middleware/auth';
// import manager from "./middleware/manager";
import client from './middleware/client';
import employe from './middleware/employe';
import middlewarePipeline from './middlewarePipeline';


import store from '../store';

Vue.use(VueRouter);

const routes = [
  {
    path: '/auth',
    name: 'Auth',
    component: Auth,
    meta: {
      middleware: [guest],
    },
  },
  {
    path: '/',
    name: 'Profile',
    component: Profile,
    meta: {
      middleware: [auth],
    },
  },
  {
    path: '/counterparty/:id',
    name: 'EmployeeCounterpartyProfile',
    component: EmployeeCounterpartyProfile,
    meta: {
      middleware: [auth, employe],
    },
  },
  {
    path: '/tasks',
    name: 'Tasks',
    component: Tasks,
    meta: {
      middleware: [auth, employe],
    },
    children: [
      {
        path: '/',
        component: ElectricityTasks,
      },
      {
        path: 'isesf',
        component: IsesfTasks,
      },
    ]
  },
  {
    path: '/tasks/:id',
    name: 'Task',
    component: Task,
    meta: {
      middleware: [auth, employe],
    },
  },
  {
    path: '/tasks/isesf/:id',
    name: 'IsesfTask',
    component: IsesfTask,
    meta: {
      middleware: [auth, employe],
    },
  },
  {
    path: '/archive',
    name: 'Archive',
    component: Archive,
    meta: {
      middleware: [auth],
    },
  },
  {
    path: '/archive/:id',
    name: 'ArchiveItem',
    component: ArchiveItem,
    meta: {
      middleware: [auth],
    },
  },
  {
    path: '/payment',
    name: 'Payment',
    component: Payment,
    meta: {
      middleware: [auth, client],
    },
  },
  {
    path: '/payment/:id',
    name: 'PaymentItem',
    component: PaymentItem,
    meta: {
      middleware: [auth, client],
    },
  },
  {
    path: '/send-counter-readings/individual',
    name: 'SendCounterReadingIndividual',
    component: SendCounterReadingsIndividual,
    meta: {
      middleware: [auth, client],
    },
  },
  {
    path: '/send-counter-readings/entity',
    name: 'SendCounterReadingEntrepreneur',
    component: SendCounterReadingsEntrepreneur,
    meta: {
      middleware: [auth, client],
    },
  },
  {
    path: '/send-rent',
    name: 'SendRent',
    component: SendRent,
    meta: {
      middleware: [auth, client],
    },
  },
  {
    path: '/rents',
    name: 'Rents',
    component: Rents,
    meta: {
      middleware: [auth, client],
    },
  },
  {
    path: '/rents/:id',
    name: 'PaymentItem',
    component: RentsItem,
    meta: {
      middleware: [auth, client],
    },
  },
  {
    path: '/manager/rents',
    name: 'ManagerRents',
    component: ManagerRents,
    meta: {
      middleware: [auth],
    },
  },
  {
    path: '/faq',
    name: 'Faq',
    component: Faq,
    meta: {
      middleware: [auth, client],
    },
  },
  {
    path: '/terms-of-use',
    name: 'TermsOfUse',
    component: TermsOfUse,
    meta: {
      middleware: [auth, client],
    },
  },
  {
    path: '/m',
    name: 'Mobile',
    component: Mobile,
    meta: {
      middleware: [auth, client],
    },
    children: [
      {
        path: 'payment',
        name: 'MobilePayment',
        component: MobilePayment,
      },
      {
        path: 'payment/:id',
        name: 'MobilePaymentItem',
        component: MobilePaymentItem,
      },
      {
        path: 'rents',
        name: 'MobileRents',
        component: MobileRents,
      }
    ],
  },
  {
    path: '/du',
    name: 'DocumentsUpdate',
    component: DocumentsUpdate,
  },
  {
    path: '/du/form',
    name: 'DocumentsUpdateForm',
    component: DocumentsUpdateForm,
  },
  {
    path: '/du/success',
    name: 'DocumentsUpdateSuccess',
    component: DocumentsUpdateSuccess,
  },
  {
    path: '/docs-update',
    name: 'DocumentsUpdateEmployee',
    component: DocumentsUpdateEmployee,
  },
  {
    path: '/new-contracts',
    name: 'NewContracts',
    component: NewContracts,
    meta: {
      middleware: [auth],
    },
  },
  {
    path: '/new-contracts-archive',
    name: 'NewContracts',
    component: NewContractsArchive,
    meta: {
      middleware: [auth],
    },
  },
  {
    path: '/new-contracts/:id',
    name: 'NewContractsItem',
    component: NewContractsItem,
    meta: {
      middleware: [auth],
    },
  },
  {
    path: '/new-contracts-manager',
    name: 'NewContractsManager',
    component: NewContractsManager,
    meta: {
      middleware: [auth],
    },
  },
  {
    path: '/registry',
    name: 'Registry',
    component: Registry,
    meta: {
      middleware: [auth],
    },
    children: [
      {
        path: '/',
        component: Electricity,
      },
      {
        path: 'rents',
        component: ManagerRents,
      },
      {
        path: 'isesf',
        component: Isesf,
      },
    ]
  },
  {
    path: '/client/document/sign/:uuid',
    name: 'NewContractsClient',
    component: NewContractsClient,
  },
  {
    path: '/docs-update/:id',
    name: 'DocumentsUpdateEmployeeItem',
    component: DocumentsUpdateEmployeeItem,
  },
  {
    path: '/isesf',
    name: 'Isesf',
    component: Isesf,
  },
  {
    path: '*',
    name: 'NotFound',
    component: NotFound,
  },
];

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes,
});

router.beforeEach((to, from, next) => {
  if (!to.meta.middleware) {
    return next();
  }
  const { middleware } = to.meta;
  const context = {
    to,
    from,
    next,
    store,
  };
  return middleware[0]({
    ...context,
    next: middlewarePipeline(context, middleware, 1),
  });
});
export default router;
