<template>
  <section class="oda-section profile">

    <section-header section="Мой профиль" />

    <profile-info />

    <profile-contracts v-if="userRole === 'client'"/>

    <dashboard v-else-if="userRole === 'manager'" />

    <employee-profile v-else-if="userRole === 'employee_curator'" />

  </section>
</template>

<script>
import SectionHeader from "@/components/SectionHeader";
import ProfileInfo from "./Info";
import ProfileContracts from "./Contracts";
import Dashboard from "./Dashboard/index";
import EmployeeProfile from "./Emplyee/index"
import {mapActions, mapState} from "vuex";

export default {
  name: "Profile",
  components: {
    SectionHeader,
    ProfileInfo,
    ProfileContracts,
    Dashboard,
    EmployeeProfile,
  },

  data() {
    return {}
  },

  computed: {
    ...mapState({
      contract_type: state => state.profile.contract_type,
      client_type: (state) => state.auth.client_type,
    }),
  },

  async mounted() {
    if (this.userRole === 'client' && !this.contract_type) {
      await this.getContractType();
    }
  },

  methods: {
    ...mapActions('profile', [
        'getNeedsToSign',
        'getContractType',
    ])
  },
}
</script>
