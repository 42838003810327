<template>
  <div
      :class="{ 'toggleable': toggleable }"
      class="oda-card task-pdf"
  >
    <div class="task-pdf__head">
      <div class="task-pdf__head-left">
        <div
            v-if="counter_photo"
            :class="{'active': selected === 'photo'}"
            class="task-pdf__head-item"
            @click="toggleable ? visible = !visible : selected = 'photo'"
        >
          <img src="@/assets/images/jpg-icon.svg" alt="jpg-icon">
          <div class="task-pdf__title">Фото</div>
        </div>
        <div
            v-for="pdf in links"
            :key="`pdf-title-${pdf.name}`"
            :class="{'active': selected === 'pdf'}"
            class="task-pdf__head-item"
            @click="selected = 'pdf'"
        >
          <img src="@/assets/images/pdf-icon.svg" alt="pdf-icon">
          <div class="task-pdf__title">{{ pdf.title }}</div>
        </div>
      </div>
      <span
          v-if="toggleable"
          class="feather icon-chevron-down"
          @click="visible = !visible"
      ></span>
    </div>
    <div
        v-for="pdf in links"
        :key="`pdf-${pdf.name}`"
    >
      <embed
          v-show="selected === 'pdf' && visible && pdf.link"
          :src="pdf.link"
          width="100%"
          height="450px"
      />
    </div>
    <img
        v-show="selected === 'photo' && counter_photo"
        :src="counter_photo"
        alt="photo"
        class="task-pdf__img"
    >
    <div
        v-show="visible && !links"
        class="task-pdf__error"
    >
      <img
          src="@/assets/images/pdf-error.svg"
          alt="sad-bunny"
      >
      <span>Ошибка при загрузке ПДФ</span>
    </div>
  </div>
</template>

<script>
export default {
  name: "Pdf",
  props: {
    links: Array,
    toggleable: Boolean,
    counter_photo: String,

    // temporary! TODO: replace with several pdf links
    entity: Boolean,
  },
  data() {
    return {
      visible: true,
      selected: 'pdf',
    }
  },
  mounted() {
    if (this.toggleable) this.visible = false;
  },
}
</script>
