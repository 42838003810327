<template>
  <div v-if="currentUser" class="profile-info">
    <div class="profile-info__main">
      <div class="profile-info__main-avatar">
        <span>{{ (currentUser.name || currentUser.username)[0].toUpperCase() }}</span>
      </div>
      <div class="profile-info__main-content">
        <div class="profile-info__main-company">
          {{ currentUser.name || currentUser.username }}
        </div>
        <div class="profile-info__main-name">
          {{ currentUser.username }}
        </div>
      </div>
    </div>

<!--    <div class="profile-info__column">-->
<!--      <div class="profile-info__item">-->
<!--        <div class="profile-info__item-icon feather icon-map-pin"></div>-->
<!--        <div class="profile-info__item-key">-->
<!--          Факт. адрес-->
<!--        </div>-->
<!--        <div class="profile-info__item-value">-->
<!--          Актюбинская область, Мартукский район, село Хлебодаровка, ул. Ленина, 57(!)-->
<!--        </div>-->
<!--      </div>-->
<!--      <div class="profile-info__item">-->
<!--        <div class="profile-info__item-icon feather icon-map-pin"></div>-->
<!--        <div class="profile-info__item-key">-->
<!--          Юр. адрес-->
<!--        </div>-->
<!--        <div class="profile-info__item-value">-->
<!--          Актюбинская область, Мартукский район, село Хлебодаровка, ул. Ленина, 57(!)-->
<!--        </div>-->
<!--      </div>-->
<!--    </div>-->

    <div class="profile-info__column">
      <div class="profile-info__item">
        <div class="profile-info__item-icon feather icon-at-sign"></div>
        <div class="profile-info__item-key">
          email
        </div>
        <div class="profile-info__item-value">
          {{ currentUser && currentUser.email }}
        </div>
      </div>
<!--      <div class="profile-info__item">-->
<!--        <div class="profile-info__item-icon feather icon-phone"></div>-->
<!--        <div class="profile-info__item-key">-->
<!--          Телефон-->
<!--        </div>-->
<!--        <div class="profile-info__item-value">-->
<!--          8 (707) 506 13 93(!)-->
<!--        </div>-->
<!--      </div>-->
<!--      <div class="profile-info__item">-->
<!--        <div class="profile-info__item-icon feather icon-phone"></div>-->
<!--        <div class="profile-info__item-key">-->
<!--          Телефон-->
<!--        </div>-->
<!--        <div class="profile-info__item-value">-->
<!--          8 (707) 506 13 93(!)-->
<!--        </div>-->
<!--      </div>-->
    </div>
  </div>
</template>

<script>
export default {
  name: "ProfileInfo"
}
</script>
