<template>
  <div class="oda-section__header">
    <div class="oda-section__header-left oda-desktop-only">
      <button
          v-if="backLink"
          class="send-registry__head-button oda-button primary feather icon-chevron-left"
          @click="back"
      ></button>
      <button
          v-if="closeAble"
          class="send-registry__head-button oda-button primary feather icon-x"
          @click="closeComponent"
      ></button>
      <h2>{{ section }}</h2>

      <div
          v-if="breadcrumb"
          class="oda-section__header-breadcrumb"
      >
        <div
            v-for="item in breadcrumb"
            :key="item.name"
            class="oda-section__header-breadcrumb-item"
        >
          <router-link
              v-if="item.link"
              :to="item.link"
          >
            {{ item.name }}
          </router-link>
          <span v-else>{{ item.name }}</span>
        </div>
      </div>
    </div>
    <div
        v-if="currentUser && !hideUser"
        class="oda-section__header-user oda-desktop-only"
    >
      <div class="oda-section__header-user-info">
        <div class="oda-section__header-user-name">
          {{ currentUser.name || currentUser.username }}
        </div>
        <div class="oda-section__header-user-role">
          {{ currentUser.role && currentUser.role.description }}
        </div>
      </div>
      <div class="oda-section__header-user-avatar">
        <span>{{ (currentUser.name && currentUser.name[0]) || currentUser.username[0].toUpperCase() }}</span>
      </div>
    </div>

    <div class="oda-section-mobile__header oda-mobile-only">
      {{ section }}
    </div>
  </div>
</template>

<script>
export default {
  name: "SectionHeader",
  props: {
    section: String,
    backLink: String,
    breadcrumb: Array,
    closeAble: Boolean,
    hideUser: Boolean,
  },

  methods: {
    back() {
      this.$router.push(this.backLink);
    },
    closeComponent() {
      this.$emit('close');
    },
  },
}
</script>
