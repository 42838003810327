<template>
  <section class="oda-section profile">
    <section-header section="Договоры" />

    <div class="tasks-desk">
      <div
          v-for="list in lists"
          :key="`list-${list.name}`"
          class="tasks-column"
      >
        <div class="tasks-column__head">
          <div class="tasks-column__title">{{ list.name }}</div>
          <div class="tasks-column__count">{{ list.cards && list.cards.length }}</div>
        </div>
        <div
            v-if="list.cards"
            class="tasks-column__list"
        >
          <div
              v-for="card in list.cards"
              :key="`card-${card.id}`"
              class="oda-card tasks-card"
              @click="goToCard(card)"
          >
            <div class="tasks-card__head">
              <div
                  :class="getStatusClass(card.status)"
                  class="oda-badge"
              >
                {{ card.status }}
              </div>
            </div>
            <div class="tasks-card__name">
              <span class="feather icon-briefcase"></span>
              {{ card.counterparty_name }}
            </div>
            <div class="tasks-card__foot">
              <div class="tasks-card__bts">
                <span class="feather icon-radio"></span>
                {{ card.bts }}
              </div>
              <div class="tasks-card__date">{{ getDateFormat(card.created_at) }}</div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import { mapState, mapActions } from "vuex";
import SectionHeader from "../../components/SectionHeader";

export default {
  name: "NewContracts",
  components: {
    SectionHeader,
  },

  data() {
    return {}
  },

  computed: {
    ...mapState({
      employee_treaties: state => state.treaties.employee_treaties,
    }),
    newTasks() {
      return this.employee_treaties.filter(item => item.status === 'Новые задачи');
    },
    hasSeenTasks() {
      return this.employee_treaties.filter(item => {
        return (item.status === 'Просмотрено' || item.manager_approved === false) && !item.employee_approved;
      })
    },
    inProgressTasks() {
      return this.employee_treaties.filter(item => {
        return item.status === 'Отклонен сотрудником';
      })
    },
    lists() {
      return [
        {
          name: 'Новые задачи',
          cards: this.newTasks,
        },
        {
          name: 'Надо проверить/Просмотрено',
          cards: this.hasSeenTasks,
        },
        {
          name: 'Отклоненные задачи',
          cards: this.inProgressTasks,
        },
      ];
    }
  },

  async mounted() {
    await this.getEmployeeNewTreaties({"_limit": -1});
  },
  methods: {
    ...mapActions('treaties', [
        'getEmployeeNewTreaties',
        'viewNewTreatiesItem',
    ]),
    goToCard(card) {
      if (card.status === 'Новые задачи')
        this.viewNewTreatiesItem(card.ciptracker_id);
      this.$router.push(`/new-contracts/${card.id}`);
    },
    getStatusClass(status) {
      const classes = {
        info: ['новые задачи'],
        warning: ['просмотрено'],
        success: ['завершено'],
        danger: ['отклонен сотрудником']
      };
      const color = Object.keys(classes).filter(item => classes[item].includes(status.toLowerCase()));
      return color[0] || 'warning';
    },
  }
}
</script>
