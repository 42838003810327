import MainApiService from "@/services/api";

const CommonRoleServices = {
    getPayment(id) {
        return MainApiService.get(`payments/${id}`);
    },
    getElectricity(id) {
        return MainApiService.get(`electricities/${id}`);
    },
    getRent(id) {
        return MainApiService.get(`rents/${id}`);
    },
    getAverageConsupmtion(params) {
        return MainApiService.get('electricities/shared/average-consumption', { params });
    }
}

export default CommonRoleServices;