<template>
  <section class="du-form__wrap">
    <du-header v-if="client_details" />
    <div class="du-success">
      <div class="du-success__text">
        Благодарим за обновление документов! 🚀
      </div>
      <router-link
          to="/du"
          class="du-success_button oda-button primary"
      >
        Вернуться на начало
      </router-link>
      <img
          class="du-success__img"
          src="@/assets/images/documents-upload-illustration.svg"
          alt="documents-update-illustration"
      >
    </div>
  </section>
</template>

<script>
import DuHeader from "./header";
import { mapState } from "vuex";

export default {
  name: "success",
  components: {
    DuHeader,
  },
  computed: {
    ...mapState({
      client_details: state => state.documentsUpload.client_details,
      iin: state => state.documentsUpload.iin,
    }),
  },
}
</script>
