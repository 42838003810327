<template>
  <b-row>
    <b-col cols="12" md="8">
      <contract-status
          :status="contract.status"
          :date="contract.created_at"
      />
    </b-col>

    <b-col cols="12" md="4">
      <contract-user :user="contract.counterparty_name" />
    </b-col>
  </b-row>
</template>

<script>
import ContractStatus from "../../../components/PaymentItem/Status";
import ContractUser from "../../../components/PaymentItem/User";
export default {
  name: "IdHead",
  components: {
    ContractStatus,
    ContractUser,
  },
  props: {
    contract: Object,
  },
}
</script>
