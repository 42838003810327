import router from "./../../router"
import authService from "@/services/authServices";
import errorService from "@/services/errorService";

const state = {
    user: null,

    token: localStorage.getItem('token'),
    userRole: localStorage.getItem('role'),

    client_type: '',

    auth_errors: [],
};

const getters = {
    isClient: state => {
        return state.user && state.user.role && state.user.role.type === 'client';
    },
};

const mutations = {
    SET_USER(state, payload) {
        state.user = payload;
    },
    SET_TOKEN(state, token) {
        localStorage.setItem('token', token);
        state.token = token;
    },
    RESTORE_TOKEN(state) {
        state.token = null;
    },
    SET_USER_ROLE(state, payload) {
        localStorage.setItem('role', payload);
        state.userRole = payload;
    },
    SET_AUTH_ERRORS(state, payload) {
        state.auth_errors = payload;
    },
    SET_CLIENT_TYPE(state, payload) {
        state.client_type = payload;
    },
};

const actions = {
    async login({ state, commit }, context) {
        try {
            const { data } = await authService.login(context);
            commit('SET_USER', data);
            commit('SET_CLIENT_TYPE', data.is_entity ? 'entity' : 'individual');
            commit('SET_TOKEN', data.jwt);
            commit('SET_USER_ROLE', data.role.type);
            commit('SET_AUTH_ERRORS', []);
        } catch (e) {
            const errors = (e.response.data || {}).message.map(e => e.messages);
            commit('SET_AUTH_ERRORS', errors[0]);
        }

        if (state.userRole === 'client') {
            if (!state.user.terms_of_use) {
                router.push('/terms-of-use');
            } else await router.push('/').catch(() => {});
        } else await router.push('/').catch(() => {});
    },

    async fetchUser({ state, commit, dispatch }) {
        if (!state.token) return;

        try {
            const { data: user } = await authService.getUserInfo();
            commit('SET_USER', user);
            commit('SET_CLIENT_TYPE', user.is_entity ? 'entity' : 'individual');
        } catch (e) {
            if (e.response.status === 401) dispatch('logout');
        }
    },

    logout({ commit }) {
        localStorage.clear();
        commit('SET_USER', {});
        commit('RESTORE_TOKEN');
        commit('SET_USER_ROLE', '');
        commit('SET_CLIENT_TYPE', '');
        router.push('/auth');
    },
    async acceptTermsOfUse() {
        try {
            await authService.acceptTermsOfUse();
            await router.push('/');
        } catch (e) {
            errorService.handleError(e);
        }
    }
};

export default {
    namespaced: true,
    state,
    getters,
    mutations,
    actions
}
