<template>
  <div class="mobile-payments">
    <div class="oda-section-mobile__header mb-2">
      Аренда
    </div>
<!--    <button-->
<!--        class="oda-button primary mb-4"-->
<!--    >-->
<!--      Отправить показания счетчика-->
<!--    </button>-->
    <div class="oda-section-mobile__header mb-2">
      История показаний
    </div>
    <div
        v-for="item in items"
        :key="item.id"
        class="mobile-payment oda-card"
        @click="openPayment(item)"
    >
      <div
          v-for="field in fieldsMobile"
          :key="field.key"
          class="mobile-payment__item"
      >
        <div class="mobile-payment__key">
          <span
              v-if="field.icon"
              :class="field.icon"
              class="feather"
          ></span>
          {{ field.label }}
        </div>
        <div class="mobile-payment__value">
          <div v-if="field.key === 'period'">{{ getPaymentPeriod(item.start_dt, item.end_dt) }}</div>
          <div v-else-if="field.key === 'created_at'">{{ getDateFormat(item[field.key]) }}</div>
          <div
              v-else-if="field.key === 'ecp'"
              :class="`${item[field.key] ? 'success' : 'danger'}`"
              class="oda-badge"
          >
            {{ item[field.key] ? 'Подписано' : 'Не подписано' }}
          </div>
          <div v-else>{{ item[field.key] }}</div>
        </div>
      </div>
    </div>
    <div
        v-if="!items.length"
        class="mobile-payment__empty"
    >
      Нет доступных платежек
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters, mapState } from "vuex";

export default {
  name: "MobileRents",

  data() {
    return {
      fieldsMobile: [
        { key: 'contract_code', label: 'Договор', icon: 'icon-file', colored: "primary" },
        { key: 'bts', label: 'Объект', icon: 'icon-radio', colored: "primary" },
        { key: 'period', label: 'Период оплаты' },
        { key: 'total_sum', label: 'Сумма в тг' },
        { key: 'ecp', label: 'ЭЦП' },
      ],
    }
  },

  computed: {
    ...mapState({
      needs_to_sign: state => state.profile.needs_to_sign,
      rent_contract_sums: state => state.profile.rent_contract_sums,
      signed_rents: state => state.registry.signed_rents,
      contract_type: state => state.profile.contract_type,
    }),
    ...mapGetters({
      rentsToSign: 'profile/rentsToSign',
    }),
    items() {
      return [...this.rentsToSign, ...this.signed_rents];
    }
  },

  async mounted() {
    if (!this.contract_type) await this.getContractType();
    if (this.contract_type.hasRent && !this.contract_type.hasElectricity) this.getNeedsToSign();
    this.getSignedRents();
  },

  methods: {
    ...mapActions('profile', [
      'getNeedsToSign',
      'getContractType',
    ]),
    ...mapActions('registry', [
      'getSignedRents',
    ]),
    openPayment(item) {
      const { id } = item;

      if (item.ecp) {
        this.$router.push({
          path: `/rents/${id}`,
          query
        });
        return;
      }

      const query = {
        contract_code: item.contract_code,
        bts: item.bts,
        contract_sum: item.total_sum,
        start_dt: item.start_dt,
        signed: item.ecp,
        id,
      };
      this.$router.push({
        path: '/send-rent/',
        query
      });
    },
  },
}
</script>
