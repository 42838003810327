import Vue from "vue";

const ErrorService = {
    handleError(error) {
        const { response } = error,
              data = response ? response.data : error.data;

        if (!response && !data) {
            Vue.$toast.open({
                type: 'error',
                message: 'Ошибка с сетью или сервером',
            });
            return Promise.reject(error);
        }

        if (data) {
            Vue.$toast.open({
                type: 'error',
                message: data,
            });
            return Promise.reject(error);

        }

        Vue.$toast.open({
            type: 'error',
            message: 'Что-то пошло не так попробуйте позже',
        });
        return Promise.reject(error);
    },

    onSuccess(message) {
        Vue.$toast.open({
            type: "success",
            message,
        });
    }
}

export default ErrorService;