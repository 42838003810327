<template>
  <section class="oda-section tasks">
    <section-header
        :breadcrumb="breadcrumb"
        section="Платежка"
        back-link="/tasks/isesf"
    />
    <item-header
        v-if="task && task.awp"
        :status="task.status"
        :date="task.created_at"
        :counterparty="task.awp.counterparty"
    />

    <div
        v-if="task && task.labels && task.labels.alarms"
        class="oda-card mb-3"
    >
      <h5 class="mb-3">Предупреждения</h5>
      <span
          v-for="(alarm, i) in task.labels.alarms"
          :key="i"
          class="oda-badge danger"
      >
        {{ alarm }}
      </span>
    </div>

    <item-info v-if="task && task.awp" />


    <b-row
        v-if="task.status !== 'В процессе' && task.status !== 'Отклонено'"
        class="oda-card mt-4"
    >
      <b-col cols="12">
        <oda-form-group
            v-model="decline_message"
            textarea
        />
      </b-col>
      <b-col cols="12">
        <div class="new-treaties-item__footer">
          <button
              :disabled="!decline_message || loading"
              class="oda-button danger"
              @click="decline"
          >
            Отклонить
          </button>
          <button
              :disabled="loading"
              class="oda-button primary"
              @click="approve"
          >
            Принять
          </button>
        </div>
      </b-col>
    </b-row>
  </section>
</template>

<script>
import ItemHeader from "./ItemHeader";
import ItemInfo from "./ItemInfo";
import SectionHeader from "../../../components/SectionHeader";
import OdaFormGroup from "../../../components/OdaFormGroup";
import { mapActions, mapState } from "vuex";

export default {
  name: "IsesfTask",
  components: {
    ItemHeader,
    ItemInfo,
    SectionHeader,
    OdaFormGroup,
  },
  data() {
    return {
      breadcrumb: [
        {
          name: 'Задачи',
          link: '/tasks/isesf'
        },
        {
          name: 'Платежка'
        }
      ],
      loading: false,
      hasSeenSent: false,

      decline_message: '',

      severalMonths: false,
      start_dt: null,
      end_dt: null,
    }
  },

  computed: {
    ...mapState({
      task: state => state.isesf.isesf_task,
      payedMonths: state => state.isesf.isesfPayedMonths,
      period: state => state.isesf.period,
    }),
  },

  async mounted() {
    const { id } = this.$route.params;
    await this.getIsEsfTask(id);
    await this.getIsesfPayedMonths();
    await this.getIsEsfTaskBankAcc({bts: this.task.awp.bts});
  },

  beforeDestroy() {
    this.$store.commit('isesf/SET_ISESF_TASK', {});
  },

  methods: {
    ...mapActions('isesf', [
        'getIsEsfTask',
        'getIsesfPayedMonths',
        'declineIsesfTask',
        'approveIsesfTask',
        'getIsEsfTaskBankAcc',
    ]),
    async approve() {
      try {
        const params = {
          id: this.task.id,
          period: this.period
        };
        this.$store.commit('isesf/SET_PERIOD', []);
        await this.approveIsesfTask(params);
      } catch (e) {
        console.error(e);
      }
    },
    async decline() {
      try {
        const params = {
          employee_message: this.decline_message,
          id: this.task.id,
        };
        this.$store.commit('isesf/SET_PERIOD', []);
        await this.declineIsesfTask(params);
      } catch (e) {
        console.error(e);
      }
    },
  },
}
</script>
