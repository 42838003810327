<template>
  <b-col cols="12">
    <div class="oda-card mt-4">
      <h4>Оплачено Аренды на сумму</h4>
      <b-row>
        <b-col cols="4">
          <oda-form-group
              v-model="range"
              :options="rangeOptions"
              label="Период"
              placeholder="Период"
              select
              select-value="type"
              select-text="text"
              @input="onRangeChange"
          />
        </b-col>

        <b-col cols="4">
          <oda-form-group
              v-model="year"
              :options="yearOptions"
              label="Год"
              placeholder="Год"
              select
          />
        </b-col>

        <b-col cols="4">
          <oda-form-group
              v-model="month"
              :disabled="range === 'year'"
              :options="monthsOptions"
              label="Месяц"
              placeholder="Месяц"
              select
              select-value="value"
              select-text="name"
          />
        </b-col>
      </b-row>

      <apexchart
          type="bar"
          height="350"
          :options="chartOptions"
          :series="series"
      />
    </div>
  </b-col>
</template>

<script>
import {mapState} from "vuex";
import OdaFormGroup from "../../../components/OdaFormGroup";

export default {
  name: "RentsSumChart",
  components: {
    OdaFormGroup,
  },

  data() {
    return {
      month: "",

      year: 2022,
      yearOptions: [2022, 2023],

      range: "year",
      rangeOptions: [
        {
          type: "year",
          text: "Год"
        },
        {
          type: "month",
          text: "Месяц"
        },
      ]
    }
  },

  computed: {
    ...mapState({
      rents_analytics: state => state.dashboard.rents_analytics,
    }),
    monthsOptions() {
      return (this.rents_analytics[this.year] || []).map((month, index) => {
        return {
          name: this.monthDictionary[month.index + 1],
          value: index
        }
      });
    },
    series() {
      const year = this.rents_analytics[this.year] || [];
      let data = [];
      if (this.range === "year") data = year.map(month => month.total_sum.toFixed());
      else data = ((year[this.month] || {}).data).map(day => day.total_sum.toFixed());
      return [
        {
          name: 'Аренда',
          data
        },
      ]
    },
    chartOptions() {
      const year = this.rents_analytics[this.year] || [];
      let categories = [];
      if (this.range === "year") categories = year.map(month => this.monthDictionary[month.index + 1]);
      else categories = ((year[this.month] || {}).data).map(day => day.index);

      return {
        chart: {
          type: 'bar',
          height: 350
        },
        plotOptions: {
          bar: {
            horizontal: false,
            columnWidth: '55%',
            endingShape: 'rounded'
          },
        },
        dataLabels: {
          enabled: false
        },
        stroke: {
          show: true,
          width: 2,
          colors: ['transparent']
        },
        xaxis: {
          categories,
        },
        fill: {
          opacity: 1
        },
        tooltip: {
          y: {
            formatter: function (val) {
              return `${val.toFixed().toString().replace(/\B(?=(\d{3})+(?!\d))/g, ' ')} тг`;
            }
          }
        }
      }
    }
  },
  methods: {
    onRangeChange() {
      this.month = this.monthsOptions[0].value;
    },
  },
}
</script>
