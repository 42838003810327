<template>
  <b-col cols="4">
    <div class="oda-card">
      <h4>Вовлеченность АД</h4>
      <apexchart
          width="380"
          type="donut"
          :options="clientsOptions"
          :series="clientsSeries"
      ></apexchart>
    </div>
  </b-col>
</template>

<script>
import {mapActions, mapState} from "vuex";

export default {
  name: "UsersInvolvementChart",
  data() {
    return {
      clientsOptions: {
        labels: ['Аренда', 'ЭЭ', 'ЭЭ + Аренда'],
        dataLabels: {
          enabled: true,
          formatter: function (val, opt) {
            return opt.w.config.series[opt.seriesIndex];
          },
        },
        plotOptions: {
          pie: {
            donut: {
              labels: {
                show: true,
                total: {
                  show: true,
                  label: "Всего"
                }
              }
            }
          }
        }
      },
    }
  },

  computed: {
    ...mapState({
      users_analytics: state => state.dashboard.users_analytics,
    }),
    clientsSeries() {
      return [Number(this.users_analytics.rent_users), Number(this.users_analytics.electricities_users), Number(this.users_analytics.combined_users)]
    },
  },

  mounted() {
    this.getUsersInvolvementAnalytics();
  },

  methods: {
    ...mapActions('dashboard', [
      'getUsersInvolvementAnalytics',
    ])
  },
}
</script>
