<template>
  <div
      :class="type"
      class="oda-alert"
  >
    <div class="oda-alert__title">{{ title }}</div>
    <div class="oda-alert__text">{{ text }}</div>
  </div>
</template>

<script>
export default {
  name: "OdaAlert",
  props: {
    title: String,
    text: String,
    type: String,
  },
}
</script>
