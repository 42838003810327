<template>
  <section class="oda-section payments">
    <section-header section="Электроэнергия" />

    <div class="payments-body">
      <div class="payments-section">
        <div class="payments-section__header">
          <div class="payments-section__header-left">
            <h3>История показаний</h3>
          </div>
          <button
              class="oda-button primary"
              :disabled="hasDeclinedCounterReading"
              @click="toSendCounterReading"
          >
            Отправить показания счетчика
          </button>
        </div>

        <div class="payments-section__info">
          <div
              v-for="item in statusInfo"
              :key="item.name"
              class="payments-section__info-item"
          >
            <div>
              <span
                  :class="item.badge"
                  class="oda-badge"
              >
                {{ item.name }}
              </span>
            </div>
            <div v-html="item.text"></div>
          </div>
        </div>

        <oda-table
            :fields="fields"
            :items="clientHistory"
            row-clickable
            custom-pagination
            @rowClicked="openPayment"
            @filter="onFilter"
        />
      </div>
    </div>
  </section>
</template>

<script>
import { mapActions, mapGetters, mapState } from 'vuex';
import OdaTable from '@/components/OdaTable.vue';
import SectionHeader from '@/components/SectionHeader.vue';

export default {
  name: 'Payment',
  components: {
    OdaTable,
    SectionHeader,
  },
  data() {
    return {
      fields: [
        {
          key: 'contract_name',
          label: 'Договор',
          sortable: true,
          searchable: false,
        },
        {
          key: 'bts',
          label: 'Объект',
          sortable: true,
          searchable: false,
        },
        {
          key: 'period',
          label: 'Период оплаты',
          sortable: true,
        },
        {
          key: 'total_sum',
          label: 'Сумма в тг',
          sortable: true,
          searchable: false,
        },
        {
          key: 'created_at',
          label: 'Дата платежа',
          sortable: true,
        },
        {
          key: 'label',
          label: 'Статус',
          sortable: true,
        },
        // {
        //   key: 'actual_area_usage_act_pdf',
        //   label: 'Действие',
        // },
      ],
      filter: {},
      statusInfo: [
        {
          name: 'Новые задачи',
          badge: 'warning',
          text: 'Ваше показание поступило к сотруднику',
        },
        {
          name: 'Завершено',
          badge: 'success',
          text: 'Деньги у вас - бегите проверять банковский счет. <br> Если деньги не пришли, то напишите своему менеджеру',
        },
        {
          name: 'В процессе',
          badge: 'warning',
          text: 'Мы превратили показание в платеж, и теперь наша начальница подпишет его с помощью ЭЦП',
        },
        {
          name: 'На доработке',
          badge: 'danger',
          text: 'Что-то пошло не так. Давайте переотправим показание? Просто нажмите на них',
        },
        {
          name: 'Просмотренные',
          badge: 'warning',
          text: 'Сотрудник проверяет ваши киловатты и тенге',
        },
      ],
    };
  },

  computed: {
    ...mapGetters({
      clientHistory: 'registry/clientHistory',
      averagePaymentSum: 'registry/clientAveragePaymentSum',
    }),
    ...mapState({
      client_type: (state) => state.auth.client_type,
    }),
    hasDeclinedCounterReading() {
      const declined = this.clientHistory.filter((item) => item.label === 'На доработке');
      return declined.length > 0;
    },
  },

  created() {
    this.getPaymentHistory();
  },

  methods: {
    ...mapActions('registry', [
      'getPaymentHistory',
    ]),
    openPayment(row) {
      if (!row.id) return;
      this.$router.push(`/payment/${row.id}`);
    },
    refreshTable() {
      this.$store.dispatch('registry/getPaymentHistory');
    },
    getHistory() {
      const filter = Object.entries(this.filter);
      const params = {};
      filter.forEach((item) => {
        params[item[0]] = item[1].join(',');
      });
      this.$store.dispatch('registry/getPaymentHistory', params);
    },
    filterOptions(value) {
      const list = [];
      if (value === 'period') {
        this.clientHistory.forEach((item) => {
          if (!list.includes(this.getPrettyMonth(item[value]))) {
            list.push(this.getPrettyMonth(item[value]));
          }
        });
      } else {
        this.clientHistory.forEach((item) => {
          if (!list.includes(item[value])) {
            list.push(item[value]);
          }
        });
      }
      return list;
    },
    toSendCounterReading() {
      this.$router.push(`/send-counter-readings/${this.client_type}`);
    },
    onFilter({ key, value }) {
      this.$set(this.filter, `${key}_contains`, value);
      this.getPaymentHistory(this.filter);
    },
  },
};
</script>
