<template>
  <div class="simple-table__footer">
    <div class="simple-table__footer-perpage">
      <span>Показать</span>
      <b-form-select
          v-model="mutablePerPage"
          :options="pageOption"
      />
    </div>
    <div class="simple-table__footer-paginator">
      <b-pagination
          v-model="mutableCurrentPage"
          :total-rows="totalRows"
          :per-page="perPage"
          aria-controls="simple-table"
          hide-goto-end-buttons
      >
        <template #prev-text>
          <span class="pagination-prev feather icon-chevron-left"></span>
        </template>
        <template #next-text>
          <span class="pagination-next feather icon-chevron-right"></span>
        </template>
      </b-pagination>
    </div>
  </div>
</template>

<script>
export default {
  name: "OdaTablePagination",
  props: {
    perPage: Number,
    pageOption: {
      type: Array,
      default: () => [10, 15, 30, 90],
    },
    currentPage: {
      type: Number,
      default: 1,
    },
    totalRows: Number,
  },

  data() {
    return {}
  },

  computed: {
    mutableCurrentPage: {
      get() {
        return this.currentPage
      },
      set(page) {
        if (page === this.currentPage) return;
        this.$emit('pageChange', page)
      },
    },
    mutablePerPage: {
      get() {
        return this.perPage;
      },
      set(perPage) {
        if (perPage === this.perPage) return;
        this.$emit('perPageChange', perPage)
      },
    },
  },

  methods: {},
}
</script>
