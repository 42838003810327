<template>
  <section class="oda-section send-registry">
    <section-header section="Аренда" />

    <div class="payments-body">
      <div class="payments-section">
        <div class="payments-section__header">
          <div class="payments-section__header-left">
            <h3>История показаний</h3>
          </div>
        </div>

        <oda-table
            ref="rents-table"
            :fields="fields"
            :items="items"
            custom-pagination
            @rowClicked="signRent"
        />
      </div>
    </div>
  </section>
</template>

<script>
import SectionHeader from '@/components/SectionHeader.vue';
import OdaTable from '@/components/OdaTable.vue';
import {mapActions, mapGetters, mapState} from "vuex";

export default {
  name: 'SendRent',
  components: {
    SectionHeader,
    OdaTable,
  },
  data() {
    return {
      fields: [
        {
          key: 'contract_code',
          label: 'Договор',
          sortable: true,
          searchable: false,
        },
        {
          key: 'bts',
          label: 'Объект',
          sortable: true,
          searchable: false,
        },
        {
          key: 'period',
          label: 'Месяц',
          sortable: true,
          searchable: false,
        },
        {
          key: 'total_sum',
          label: 'Сумма в тг',
          sortable: true,
          searchable: false,
        },
        {
          key: 'ecp',
          label: 'ЭЦП',
          sortable: true,
        },
      ],
    };
  },

  computed: {
    ...mapState({
      needs_to_sign: state => state.profile.needs_to_sign,
      rent_contract_sums: state => state.profile.rent_contract_sums,
      signed_rents: state => state.registry.signed_rents,
      contract_type: state => state.profile.contract_type,
    }),
    ...mapGetters({
      rentsToSign: 'profile/rentsToSign',
    }),
    items() {
      const signed_rents = this.signed_rents.map(item => {
        return {...item, ...{ period: item.start_dt }};
      }); //TODO: remove
      return [...this.rentsToSign, ...signed_rents];
    }
  },

  async mounted() {
    if (!this.contract_type) await this.getContractType();
    if (this.contract_type.hasRent && !this.contract_type.hasElectricity) this.getNeedsToSign();
    this.getSignedRents();
  },

  methods: {
    ...mapActions('profile', [
        'getNeedsToSign',
        'getContractType',
    ]),
    ...mapActions('registry', [
      'getSignedRents',
    ]),
    signRent(row) {
      const { id } = row;

      if (row.ecp) {
        this.$router.push({
          path: `/rents/${id}`,
          query
        });
        return;
      }

      const query = {
        contract_code: row.contract_code,
        bts: row.bts,
        contract_sum: row.total_sum,
        start_dt: row.start_dt,
        signed: row.ecp,
        id,
      };
      this.$router.push({
        path: '/send-rent/',
        query
      });
    },
  },
};
</script>
