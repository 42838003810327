<template>
  <b-sidebar
      v-model="visible"
      id="electricity-sidebar"
      width="952px"
      right
      shadow
      backdrop
      no-header
  >
    <div
        v-if="task && task.id"
        class="oda-sidebar"
    >
      <section-header
          section="Платежка"
          close-able
          hide-user
          @close="visible = false"
      />
      <item-header
          v-if="task && task.awp"
          :status="task.status"
          :date="task.created_at"
          :counterparty="task.awp.counterparty"
      />

      <item-info />
    </div>
    <div v-else>
      <div class="profile-tab__loading">
        <span></span>
      </div>
    </div>
  </b-sidebar>
</template>

<script>
import {mapState} from "vuex";
import ItemHeader from "../../Tasks/Isesf/ItemHeader";
import ItemInfo from "../../Tasks/Isesf/ItemInfo";
import SectionHeader from "../../../components/SectionHeader";

export default {
  name: "sidebar",
  components: {
    ItemHeader,
    ItemInfo,
    SectionHeader,
  },

  data() {
    return {
      visible: false,
    }
  },
  computed: {
    ...mapState({
      task: state => state.isesf.sidebar_item,
    }),
  },

  methods: {
    toggle() {
      this.visible = !this.visible;
    },
    hide() {
      this.visible = false;
      this.$emit('hide');
    },
  },
}
</script>
