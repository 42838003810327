<template>
  <div class="registry">

    <div class="payments-body">
      <div class="payments-section">
        <div class="payments-section__header">
          <div class="payments-section__header-left">
            <button
                :disabled="items && !items.length"
                class="oda-button primary mr-3"
                @click="availablePick"
            >
              Выбрать
            </button>
          </div>
        </div>
        <oda-table
            ref="isesf-table"
            :fields="fields"
            :items="items"
            :pickable="pickAvailable"
            :picked-items="pickedItems"
            :widths="widths"
            :disabled-columns="disabledColumns"
            custom-pagination
            row-clickable
            @pick="pickItem"
            @pickAll="pickAll"
            @rowClicked="openPayment"
        />
      </div>
      <b-row>
        <button
            :class="{'loading': loading}"
            :disabled="!pickAvailable ||loading"
            class="oda-button primary mr-3"
            @click="approveRegistry"
        >
          Согласовать реестр
        </button>
        <button
            :class="{'loading': loading}"
            :disabled="!pickAvailable ||loading"
            class="oda-button danger"
            @click="declineRegistry"
        >
          Отклонить реестр
        </button>
      </b-row>
    </div>
    <sidebar
        ref="sidebar"
        @hide="pickedItems = []"
    />
<!--    <sign-modal-->
<!--        ref="ecp-modal"-->
<!--        :loading="loading"-->
<!--        @send="approveRegistry"-->
<!--    />-->
  </div>
</template>

<script>
import OdaTable from "@/components/OdaTable.vue";
import Sidebar from "./sidebar.vue"
// import SignModal from '@/components/SignEcpModal.vue'
import {mapActions, mapState} from "vuex";

export default {
  name: "Isesf",
  components: {
    OdaTable,
    // SignModal,
    Sidebar,
  },
  data() {
    return {
      loading: false,

      pickAvailable: false,
      widths: [65],
      disabledColumns: [0],
      pickedItems: [],
      fields: [
        {
          key: 'id',
          label: '',
        },
        {
          key: 'counterparty_isesf',
          label: 'Контрагент',
          sortable: false,
          searchable: false,
        },
        {
          key: 'bts_isesf',
          label: 'Site ID',
          sortable: false,
          searchable: false,
        },
        {
          key: 'period',
          label: 'Период',
          sortable: false,
        },
        {
          key: 'total_sum_isesf',
          label: 'Сумма в тг',
          sortable: false,
          searchable: false,
        },
        // {
        //   key: 'average_for_period',
        //   label: 'Среднее за месяц',
        // },
        // {
        //   key: 'reading_status',
        //   label: 'Статус',
        //   sortable: false,
        // },
      ],
    }
  },

  computed: {
    ...mapState({
      items: state => state.isesf.managerItems,
    }),
  },

  mounted() {
    this.getManagerItems();
  },
  methods: {
    ...mapActions('isesf', [
      'getManagerItems',
      'approveManagerItems',
      'declineManagerItems',
      'getIsEsfTask',
    ]),
    availablePick() {
      if (!this.pickAvailable) this.pickedItems = [];

      this.pickAvailable = !this.pickAvailable;

      this.fields.reverse();
      if (this.pickAvailable) this.fields.push({ key: 'id', label: '' });
      else this.fields.pop();
      this.fields.reverse();
    },
    pickItem(row) {
      const id = row.id;
      if (this.pickedItems.includes(id)) {
        this.pickedItems = this.pickedItems.filter((item) => item !== id);
      } else {
        this.pickedItems.push(id);
      }
    },
    pickAll() {
      if (this.pickedItems.length === this.items.length) this.pickedItems = [];
      else this.pickedItems = this.items.map((item) => item.id);
    },

    async openPayment(row) {
      if (this.pickAvailable) {
        this.pickItem(row);
        return;
      }
      this.$store.commit('isesf/SET_SIDEBAR_ITEM', row);
      await this.getIsEsfTask(row.id);
      this.$refs.sidebar.toggle();
    },

    async approveRegistry() {
      try {
        const params = {
          ids: this.pickedItems,
        };

        await this.approveManagerItems(params);
        this.$toast.open({
          type: 'success',
          message: 'Успешно отправлено на рассмотрение',
        });
      } catch (e) {
        const message = e.response.data;
        this.$toast.open({
          type: 'error',
          message: message || 'Что-то пошло не так. Попробуйте позже',
        });
      } finally {
        this.pickedItems = [];
      }
      this.pickAvailable = false;
      this.pickedItems = [];
      this.$refs['isesf-table'].$refs.table.refresh();
      this.getManagerItems();
    },

    async declineRegistry() {
      try {
        const params = {
          ids: this.pickedItems,
        };

        await this.declineManagerItems(params);
        this.$toast.open({
          type: 'success',
          message: 'Успешно отправлено на рассмотрение',
        });
      } catch (e) {
        const message = e.response.data;
        this.$toast.open({
          type: 'error',
          message: message || 'Что-то пошло не так. Попробуйте позже',
        });
      } finally {
        this.pickedItems = [];
      }
      this.pickAvailable = false;
      this.pickedItems = [];
      this.$refs['isesf-table'].$refs.table.refresh();
      this.getManagerItems();
    },

    // showEcpModal() {
    //   const component = this.$refs['ecp-modal'];
    //   component.$refs['modal'].show();
    // },
    // hideEcpModal() {
    //   const component = this.$refs['ecp-modal'];
    //   component.$refs['modal'].hide();
    // },
  },
}
</script>
