<template>
  <b-sidebar
      v-model="visible"
      id="electricity-sidebar"
      width="952px"
      right
      shadow
      backdrop
      no-header
  >
    <div
        v-if="!loading && item && item.id"
        class="oda-sidebar"
    >
      <section-header
          :breadcrumb="breadcrumb"
          section="Платежка"
          close-able
          hide-user
          @close="visible = false"
      />
        <payment-pdf
            :links="pdfLink"
            toggleable
        />

        <payment-status
            :status="(item.rent || {}).label"
            :date="item.created_at"
        />

        <payment-form :form="form" />
    </div>
    <div v-else>
      <div class="profile-tab__loading">
        <span></span>
      </div>
    </div>
  </b-sidebar>
</template>

<script>
import SectionHeader from "@/components/SectionHeader";
import PaymentStatus from "@/components/PaymentItem/Status";
import PaymentForm from "@/components/PaymentItem/Form";
import PaymentPdf from "@/components/PaymentItem/Pdf";
import {mapState} from "vuex";

export default {
  name: "sidebar",
  components: {
    SectionHeader,
    PaymentStatus,
    PaymentForm,
    PaymentPdf,
  },
  data() {
    return {
      visible: false,
      breadcrumb: [
        {
          name: 'Аренда',
        },
        {
          name: 'Платежка'
        }
      ],
    }
  },

  computed: {
    ...mapState({
      loading: state => state.rents.loading,
      item: state => state.rents.sidebar_item,
    }),
    pdfLink() {
      return [{
        title: 'АВР',
        name: 'AVR',
        link: this.item.signed_pdf,
      }]
    },
    form() {
      return [
        {
          name: 'contract_name',
          value: this.item.rent.contract_code,
          size: 8,
        },
        {
          name: 'bts',
          value: this.item.rent.bts,
          size: 4,
        },
        {
          name: 'counterparty',
          value: this.item.counterparty,
          size: 8,
          label: 'Контрагент',
        },
        {
          name: 'expense_type',
          value: 'Аренда',
          label: 'Вид расхода',
          size: 4,
        },
        {
          name: 'period',
          value: this.getPaymentPeriod(this.item.rent.start_dt),
          label: 'Период оплаты',
          size: 4,
        },
        {
          name: 'sf_number',
          value: this.item.id,
          label: 'Входящий номер АФИП',
          size: 4,
        },
        // {
        //   name: 'income_date',
        //   value: this.getDateFormat((this.payment.application || {}).pdf_dt),
        //   label: 'Входящая дата',
        //   size: 4,
        // },
        {
          name: 'current_payment_amount',
          value: this.prettyPrice(this.item.rent.total_sum),
          label: 'Сумма в тг',
          size: 4,
        },
      ]
    },
  },


  methods: {
    toggle() {
      this.visible = !this.visible;
    },
    hide() {
      this.visible = false;
      this.$emit('hide');
    },
  },
}
</script>
