<template>
  <section class="oda-section tasks">
    <section-header section="Задачи" />

    <oda-tabs
        :tabs="tabs"
        :active-tab="activeTab"
    />
    <router-view></router-view>
  </section>
</template>

<script>
import ElectricityTasks from "./Electricity";
import IsesfTasks from "./Isesf/index";
import SectionHeader from "@/components/SectionHeader.vue";
import OdaTabs from "@/components/OdaTabs.vue";

export default {
  name: "Tasks",
  components: {
    OdaTabs,
    SectionHeader,
    // eslint-disable-next-line vue/no-unused-components
    ElectricityTasks,
    // eslint-disable-next-line vue/no-unused-components
    IsesfTasks,
  },

  data() {
    return {
      tabs: [
        {
          name: 'electricity',
          title: 'Электроэнергия',
          link: '/tasks',
        },
        {
          name: 'isesf',
          title: 'ИС ЕСФ',
          link: '/tasks/isesf',
        },
      ]
    }
  },
  computed: {
    activeTab() {
      return this.tabs.findIndex(tab => tab.link === this.$route.path);
    },
  }
}
</script>
