<template>
  <div class="oda-card mb-3">
    <h5>{{ bts }}</h5>
    <b-row>
      <b-col cols="4">
        <oda-form-group
            v-model="percent"
            label="Потери %"
            @input="onPercentChange"
        />
      </b-col>
      <b-col cols="4">
        <oda-form-group
            v-model="constant"
            label="Потери фикса"
            @input="onConstantChange"
        />
      </b-col>
    </b-row>
    <b-row>
      <b-col>
        <button
            class="oda-button success"
            @click="save"
        >Сохранить</button>
      </b-col>
    </b-row>
  </div>
</template>

<script>
import {mapActions, mapState} from "vuex";
import OdaFormGroup from "@/components/OdaFormGroup";

export default {
  name: "CounterpartyBts",
  components: {
    OdaFormGroup,
  },
  props: {
    counterparty: String,
  },

  data() {
    return {
      percent: 0,
      constant: 0,
      is_percent: false,
    }
  },
  computed: {
    ...mapState({
      bts: state => state.profile.counterparty_bts,
      electricity_losses: state => state.profile.counterparty_electricity_losses,
    })
  },

  async mounted() {
    await this.getBtsByCounterparty({counterparty: this.counterparty});
    await this.getElectricityLossesByBts({bts: this.bts});
    if (this.electricity_losses) {
      const { percent, constant, is_percent } = this.electricity_losses;
      if (is_percent) {
        this.percent = percent;
        this.is_percent = true;
      }
      else this.constant = constant;
    }
  },
  methods: {
    ...mapActions('profile', [
        'getBtsByCounterparty',
        'getElectricityLossesByBts',
        'updateElectricityLoss',
        'createElectricityLoss',
    ]),
    save() {
      const params = {
        percent: this.percent,
        constant: this.constant,
        is_percent: this.is_percent,
        counterparty: this.counterparty,
        bts: this.bts,
      };
      if (this.electricity_losses) {
        params.id = this.electricity_losses.id;
        this.updateElectricityLoss(params);
      }
      else {
        this.createElectricityLoss(params);
      }
    },
    onPercentChange() {
      this.constant = 0;
      this.is_percent = true;
    },
    onConstantChange() {
      this.percent = 0;
      this.is_percent = false;
    }
  },
}
</script>
