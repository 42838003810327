import employeeServices from "@/services/employeeServices";
import managerServices from "@/services/managerServices";
import errorService from "@/services/errorService";
import router from "../../router";

const state = {
  isesf_tasks: [],
  isesf_task: {},
  isesf_task_bank_acc: null,
  currentTask: {},
  isesfPayedMonths: [],
  sidebar_item: {},
  period: [],

  //manager state items
  managerItems: [],
};

const getters = {};

const mutations = {
  SET_ISESF_TASKS(state, data) {
    state.isesf_tasks = data;
  },
  SET_ISESF_TASK(state, data) {
    state.isesf_task = data;
  },
  SET_ISESF_TASK_BANK_ACC(state, data) {
    state.isesf_task_bank_acc = data;
  },
  SET_CURRENT_TASK(state, payload) {
    state.currentTask = payload;
  },
  SET_ISESF_PAYED_MONTHS(state, payload) {
    state.isesfPayedMonths = payload;
  },
  SET_MANAGER_ITEMS(state, payload) {
    state.managerItems = payload;
  },
  SET_SIDEBAR_ITEM(state, payload) {
    state.sidebar_item = payload;
  },
  SET_PERIOD(state, payload) {
    state.period = payload;
  },
};

const actions = {
  async getIsEsfTasks({ commit }) {
    try {
      const { data } = await employeeServices.getIsEsfTasks();
      commit('SET_ISESF_TASKS', data);
    } catch (e) {
      errorService.handleError(e);
    }
  },
  async getIsEsfTask({ commit }, id) {
    try {
      const { data } = await employeeServices.getIsEsfTask(id);
      commit('SET_ISESF_TASK', data);
    } catch (e) {
      errorService.handleError(e);
    }
  },
  viewIsesfTask(_, id) {
    employeeServices.viewIsesfTask(id);
  },
  async approveIsesfTask({ commit }, id) {
    try {
      const { data } = await employeeServices.approveIsesfTask(id);
      commit('SET_CURRENT_TASK', data);
      await router.push('/tasks/isesf');
      errorService.onSuccess("Задача перешла в раздел 'В процессе'");
    } catch (e) {
      errorService.handleError(e);
    }
  },
  async declineIsesfTask(_, params) {
    try {
      await employeeServices.declineIsesfTask(params);
      await router.push('/tasks/isesf');
      errorService.onSuccess("Задача успешно отклонена");
    } catch (e) {
      errorService.handleError(e);
    }
  },
  async getIsesfPayedMonths({ commit }) {
    try {
      const { data } = await employeeServices.getIsesfPayedMonths();
      commit('SET_ISESF_PAYED_MONTHS', data);
    } catch (e) {
      errorService.handleError(e);
    }
  },

  async getManagerItems({ commit }) {
    try {
      const { data } = await managerServices.getIsesfItems();
      commit("SET_MANAGER_ITEMS", data);
    } catch (e) {
      errorService.handleError(e);
    }
  },
  async approveManagerItems(_, params) {
    return await managerServices.approveIsesfItems(params);
  },
  async declineManagerItems(_, params) {
    return await managerServices.declineIsesfItems(params);
  },

  async getIsEsfTaskBankAcc({ commit }, params) {
    try {
      const { data } = await employeeServices.getIsEsfTaskBankAcc(params);
      commit('SET_ISESF_TASK_BANK_ACC', data);
    } catch (e) {
      errorService.handleError(e);
    }
  },
};

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions,
}
