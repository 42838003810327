import commonRoleServices from "@/services/commonRoleServices";
import managerServices from "@/services/managerServices";
import employeeServices from "@/services/employeeServices";
import errorService from "@/services/errorService";

const state = {
    items: [],
    item: {},
    act: {},
    loading: false,
    resend_item: {},
    average_consumption: {},
};

const getters = {
    payment: state => {
        return {...((state.act || {}).actual_area_usage_act), ...state.item,
        ...{
            completion_certificates: ((state.act || {}).completion_certificates)
        }};
    },
    clientPayment: state => {
        return {...state.item, ...state.act};
    },
    managerItems: state => {
        const items = state.items.filter(item => !item.approved_by_manager && !item.expense_type.includes('доп') && item.electricity);
        return items.sort((a, b) => {
            if (a.id < b.id) {
                return -1;
            }
            if (a.id > b.id) {
                return 1;
            }
            return 0;
        });
    },
    managerLossItems: state => {
        return state.items.filter(item => item.expense_type.includes('доп'));
    },
};

const mutations = {
    SET_ITEMS(state, payload) {
        state.items = payload;
    },
    SET_ITEM(state, payload) {
        state.item = payload;
    },
    SET_ACT(state, payload) {
        state.act = payload;
    },
    SET_LOADING(state, payload) {
        state.loading = payload;
    },
    SET_RESEND_ITEM(state, payload) {
        state.resend_item = payload;
    },
    SET_AVERAGE_CONSUMPTION(state, payload) {
        state.average_consumption = payload;
    }
};

const actions = {
    async getEmployeePayments({ commit }) {
        const { data } = await employeeServices.getPayments();
        const mappedData = data.map(item => {
            return {...item, ...item.payment}
        });
        commit('SET_ITEMS', mappedData);
    },
    async getManagerPayments({ commit }) {
        try {
            const params = {
                "_limit": -1
            }

            const { data } = await managerServices.getPayments(params);
            const sortedData = data.sort((a, b) => {
                if ((a.electricity || {}).reading_status === 'Первое показание') return -2;
                else if ((b.electricity || {}).reading_status === 'Не превышает +10%') return -1;
                return 0;
            });
            commit('SET_ITEMS', sortedData);
        } catch (e) {
            errorService.handleError(e);
        }
    },
    async getManagerArchivePayments({ commit }, params) {
        const filters = {};
        if (params) {
            Object.entries(params).forEach(param => {
                if (param[1]) filters[param[0]] = param[1];
            })
        }
        filters.archived = true;

        const { data } = await managerServices.getArchive(filters);
        commit('SET_ITEMS', data);
    },
    async getPayment({commit}, id) {
        try {
            commit('SET_LOADING', true);
            const { data } = await commonRoleServices.getPayment(id);
            const fullData = {...data, ...data.electricity};
            commit('SET_ITEM', fullData);
        } catch (e) {
            errorService.handleError(e);
        } finally {
            commit('SET_LOADING', false);
        }
    },

    async getElectricity({commit}, id) {
        try {
            const { data } = await commonRoleServices.getElectricity(id);
            commit('SET_ACT', data);
        } catch (e) {
            errorService.handleError(e);
        }
    },

    async sendForPayment({commit}, params) {
        try {
            commit('SET_LOADING', true);
            await managerServices.sendForPayment(params)
        } finally {
            commit('SET_LOADING', false);
        }
    },
    async getAverageConsupmtion({ commit }, bts) {
        try {
            const { data } = await commonRoleServices.getAverageConsupmtion({bts});
            commit('SET_AVERAGE_CONSUMPTION', data)
        } catch (e) {
            errorService.handleError(e);
        }
    }
};

export default {
    namespaced: true,
    state,
    getters,
    mutations,
    actions
}
