<template>
  <div class="oda-card task-card task-client">
    <div class="task-client__avatar">
      {{ user && user[0] }}
    </div>
    <div class="task-client__name">
      {{ user }}
    </div>
  </div>
</template>

<script>
export default {
  name: "User",
  props: {
    user: String,
  },
}
</script>
