<template>
  <div class="tasks-desk">
    <div
        v-for="list in lists"
        :key="`list-${list.name}`"
        class="tasks-column"
    >
      <div class="tasks-column__head">
        <div class="tasks-column__title">{{ list.name }}</div>
        <div class="tasks-column__count">{{ list.cards && list.cards.length }}</div>
      </div>
      <div class="tasks-column__list">
        <div
            v-for="card in list.cards"
            :key="`card-${card.id}`"
            :class="{'tasks-card-stack': card.group.length > 1}"
            class="oda-card tasks-card"
            @click="goToCard(card)"
        >
          <div class="tasks-card__head">
            <div
              :class="getReadingStatusClass(card.reading_status)"
              class="oda-badge"
            >
              {{ getReadingStatus(card.reading_status) }}
            </div>
            <div
                v-if="card.label === 'В процесее' && card.payments && card.payments[0]"
                class="tasks-card__status"
            >
              <span
                :class="{
                  'active': card.payments[0].approved,
                  'declined': card.payments[0].approved === false
                }"
                class="feather icon-check"
            ></span>
              <div
                :class="{
                  'active': card.payments[0].approved_by_manager,
                  'declined': card.payments[0].approved_by_manager === false
                }"
                class="tasks-card__status-item"
              ></div>
              <div
                :class="{
                  'active': card.payments[0].approved_by_accountant,
                  'declined': card.payments[0].approved_by_accountant === false
                }"
                class="tasks-card__status-item"
              ></div>
              <div
                :class="{
                  'active': card.payments[0].approved_by_treasury,
                  'declined': card.payments[0].approved_by_treasury === false
                }"
                class="tasks-card__status-item"
              ></div>
            </div>
          </div>
          <div class="tasks-card__name">
            <span class="feather icon-briefcase"></span>
            {{ card.counterparty }}
          </div>
          <div class="tasks-card__name">
            <span class="feather icon-calendar"></span>
            {{ getGroupPaymentPeriod(card) }}
          </div>
          <div class="tasks-card__foot">
            <div class="tasks-card__bts">
              <span class="feather icon-radio"></span>
              {{ card.bts }}
            </div>
            <div class="tasks-card__date">{{ getDateFormat(card.created_at) }}</div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';

export default {
  name: 'ElectricityTasks',

  computed: {
    ...mapGetters({
      cards: 'tasks/electricityTasksSorted'
    }),
    lists() {
      if (!this.groupedCards || !this.groupedCards.length) return;
      const names = ['Новые задачи', 'Просмотренные', 'В процесее'];
      const lists = [];
      names.forEach((name) => {
        const item = {
          name,
          cards: this.groupedCards.filter((card) => card.label === name && !card.archived),
        };
        lists.push(item);
      });
      return lists;
    },
    groupedCards() {
      if (!this.cards.length) return;
      let grouped = [];
      let group = {
        id: this.cards[0].id,
        group_id: this.cards[0].group_id,
        group: [],
        created_at: this.cards[0].created_at,
        counterparty: this.cards[0].counterparty,
        bts: this.cards[0].bts,
        label: this.cards[0].label,
        reading_status: this.cards[0].reading_status,
        payments: this.cards[0].payments,
      };
      let group_sum = 0;

      this.cards.forEach(item => {
        if (item.group_id === group.group_id) {
          group.group.push(item);
          group_sum += item.total_sum;
        } else {
          group.total_sum = group_sum;
          grouped.push(group);
          group = {};
          group_sum = item.total_sum;
          this.$set(group, 'id', item.id);
          this.$set(group, 'group_id', item.group_id);
          this.$set(group, 'group', []);
          this.$set(group, 'created_at', item.created_at);
          this.$set(group, 'counterparty', item.counterparty);
          this.$set(group, 'bts', item.bts);
          this.$set(group, 'label', item.label);
          this.$set(group, 'reading_status', item.reading_status);
          this.$set(group, 'payments', item.payments);
          group.group.push(item);
        }
      })
      group.total_sum = group_sum;
      grouped.push(group);
      return grouped;

    }
  },

  mounted() {
    this.$store.dispatch('tasks/getTasks', 'electricities');
  },

  methods: {
    goToCard(card) {
      if (card.label === 'Новые задачи')
        this.$store.dispatch('tasks/viewElectrictyTask', { group_id: card.group_id });
      if (card.group.length > 1) {
        let link = "/tasks/";
        card.group.forEach((item, i) => {
          link += `${item.id}`;
          if (card.group.length !== i + 1) link += '-';
        })
        this.$router.push(link);
        return;
      }
      this.$router.push(`/tasks/${card.id}`);
    },
    getGroupPaymentPeriod(card) {
      if (card.group.length > 1) {
        card.group.sort( (a, b) =>  {
          if (a.start_dt > b.start_dt) {
            return 1;
          }
          if (a.start_dt < b.start_dt) {
            return -1;
          }
          return 0;
        });
      }
      card.group.sort();
      return this.getPaymentPeriod(card.group[0].start_dt, card.group[card.group.length-1].end_dt);
    },
  },
};
</script>
