<template>
  <b-form @submit.prevent="submit">
    <div class="oda-card mb-3">
      <b-row>
        <b-col
            v-for="field in form"
            :cols="field.size"
            :key="field.name"
        >
          <oda-form-group
              v-model="values[field.name]"
              :label="field.label"
              :required="field.required"
              :phone="field.phone"
          />
        </b-col>

        <b-col cols="5">
          <b-form-group label="Тип АД:">
            <b-form-radio v-model="values.is_entity" :value="true">Юр. лицо</b-form-radio>
            <b-form-radio v-model="values.is_entity" :value="false">Физ. лицо</b-form-radio>
          </b-form-group>
        </b-col>
      </b-row>
      <b-row v-if="!values.id">
        <b-col cols="5">
          <button
              class="oda-button success"
              type="submit"
          >
            Создать
          </button>
        </b-col>
      </b-row>
    </div>
    <b-row v-if="values.id">
      <b-col cols="12" class="d-flex">
        <button
            class="oda-button success mr-3"
            type="submit"
        >
          Сохранить
        </button>
<!--        <button class="oda-button danger bordered">Заблокировать</button>-->
      </b-col>
    </b-row>
  </b-form>
</template>

<script>
import OdaFormGroup from "@/components/OdaFormGroup";
import {mapActions, mapState} from "vuex";

export default {
  name: "CounterpartyMain",
  components: {
    OdaFormGroup,
  },
  data() {
    return {
      form: [
        {
          name: 'name',
          size: 5,
          label: 'Наименование',
          required: true,
        },
        {
          name: 'username',
          size: 5,
          label: 'Логин',
          required: true,
        },
        {
          name: 'email',
          size: 5,
          label: 'E-mail',
          type: 'email',
          required: true,
        },
        {
          name: 'password',
          size: 5,
          label: 'Пароль',
        },
        // {
        //   name: 'status',
        //   size: 5,
        //   label: 'Статус',
        //   disabled: true,
        // },
        {
          name: 'phone_number',
          size: 5,
          label: 'Номер WhatsApp',
          required: true,
          phone: true,
        },
      ],
      values: {},
    }
  },
  watch: {
    counterparty() {
      this.form.forEach(field => {
        if (this.counterparty[field.name])
          this.$set(this.values, field.name, this.counterparty[field.name]);
      })
      this.$set(this.values, 'is_entity', this.counterparty.is_entity);
    }
  },
  computed: {
    ...mapState({
      counterparty: state => state.profile.counterparty,
    })
  },
  mounted() {
    const { id } = this.$route.params;
    this.values.id = id;
  },
  methods: {
    ...mapActions('profile', [
        'getCounterparty',
        'updateCounterparty',
        'createCounterparty',
    ]),
    submit() {
      if (this.values.id) {
        this.save();
        return;
      }
      this.create();
    },
    save() {
      this.updateCounterparty(this.values);
    },
    create() {
      this.values.role = 4;
      this.values.curator = 6;
      this.values.employee_curator = this.currentUser.id;
      this.values.confirmed = true;
      this.values.blocked = false;
      this.createCounterparty(this.values);
      this.values = {};
    },
  },
}
</script>
