<template>
  <section class="oda-section tasks">
    <section-header
        section="Профиль АД"
        back-link="/"
    />

    <oda-tabs :tabs="tabs">
      <template slot="main">
        <counterparty-main />
      </template>

      <template slot="bts">
        <counterparty-bts
            v-if="counterparty"
            :counterparty="counterparty.name"
        />
      </template>

      <template slot="history">
        <counterparty-history />
      </template>
    </oda-tabs>
  </section>
</template>

<script>
import SectionHeader from "@/components/SectionHeader";
import OdaTabs from "@/components/OdaTabs.vue";
import CounterpartyMain from "./CounterpartyMain";
import CounterpartyBts from "./CounterpartyBts";
import CounterpartyHistory from "./CounterpartyHistory";
import {mapActions, mapState} from "vuex";

export default {
  name: "EmployeeCounterpartyProfile",
  components: {
    SectionHeader,
    OdaTabs,
    CounterpartyMain,
    CounterpartyBts,
    CounterpartyHistory,
  },

  computed: {
    ...mapState({
      counterparty: state => state.profile.counterparty,
      conditions: state => state.profile.active_conditions,
    })
  },

  data() {
    return {
      tabs: [
        {
          name: 'main',
          title: 'Основные',
        },
        {
          name: 'bts',
          title: 'БТС',
        },
        {
          name: 'history',
          title: 'История платежей',
        }
      ],
    }
  },

  async mounted() {
    const { id } = this.$route.params;
    if (!this.counterparty) await this.getCounterparty(id);

    await this.getActiveConditions({user: this.counterparty.name});
    if (this.conditions.electricity && this.conditions.electricity.length) this.getClientHistory({user_id: id});
    if (this.conditions.rent && this.conditions.rent.length) this.getSignedRents();
  },
  methods: {
    ...mapActions('profile', [
      'getCounterparty',
      'getActiveConditions',
    ]),
    ...mapActions('tasks', [
      'getClientHistory',
    ]),
    ...mapActions('registry', [
      'getSignedRents',
    ]),
  },
}
</script>
