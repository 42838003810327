<template>
  <div class="step1">
    <div class="oda-card send-registry__card">
      <div class="send-registry__card-title">
        <div class="send-registry__card-left">
          <span class="feather icon-file"></span>
          <h3>Договор</h3>
        </div>
      </div>
      <b-row>
        <b-col cols="12" md="9">
          <oda-form-group
              :value="activeContractValue"
              :options="contracts"
              :disabled="contracts_loading"
              :placeholder="contracts_loading ? 'Загружается...' :'Выберите договор'"
              select
              select-value="contract_code"
              select-text="counterparty_contract"
              @input="getBtsByContract"
          />
        </b-col>
      </b-row>
    </div>

    <b-form
        v-if="Object.values(active_contract).length"
        class="oda-card send-registry__card"
        @submit.prevent="submit"
    >
      <div class="send-registry__card-title">
        <div class="send-registry__card-left">
          <span class="feather icon-radio"></span>
          <h3>Объект</h3>
        </div>
      </div>

      <b-row>
        <b-col cols="12" md="9">
          <oda-form-group
              v-model="bts.bts_name"
              placeholder="Выберите объект"
              select
              required
              :options="bts_options"
              @input="onBtsPicked"
          />
        </b-col>
      </b-row>
      <b-row class="align-items-end">
        <b-col cols="12" md="3" class="required">
          <oda-form-group
              :label="severalMonths ? 'Период с': 'Период'"
              empty-form
              required
          >
            <template v-slot:input>
              <multiselect
                  v-model="bts.start_dt"
                  :options="startDtOptions"
                  :show-labels="false"
                  :multiple="false"
                  :clear-on-select="false"
                  :preselect-first="false"
                  placeholder="Выберите период"
                  label="label"
                  class="oda-form-multiselect"
                  @input="setStartDate"
              ></multiselect>
            </template>
          </oda-form-group>
        </b-col>
        <b-col
            v-if="severalMonths"
            cols="12"
            md="3"
            class="required"
        >
          <oda-form-group
              label="Период до"
              empty-form
              required
          >
            <template v-slot:input>
              <multiselect
                  v-model="bts.end_dt"
                  :options="endDtOptions"
                  :show-labels="false"
                  :multiple="false"
                  :clear-on-select="false"
                  :preselect-first="false"
                  :disabled="!endDtOptions.length"
                  placeholder="Выберите период"
                  label="label"
                  class="oda-form-multiselect"
                  @input="setEndDate"
              ></multiselect>
            </template>
          </oda-form-group>
        </b-col>
        <b-col>
          <oda-form-group
              v-model="severalMonths"
              :disabled="!endDtOptions.length"
              placeholder="Несколько месяцев"
              checkbox
          />
        </b-col>
      </b-row>
      <b-row>
        <b-col cols="12" md="3" class="required">
          <oda-form-group
              v-model="bts.counter_previous_value"
              label="Предыдущее показание"
              placeholder="Напишите предыдущее показание"
              number
              required
              integer
          />
        </b-col>
        <b-col cols="12" md="3" class="required">
          <oda-form-group
              v-model="bts.counter_value"
              label="Текущее показание"
              placeholder="Напишите текущее показание"
              number
              required
              integer
          />
        </b-col>
        <b-col cols="12" md="3">
          <oda-form-group
              v-if="lossValue"
              :placeholder="`${lossValue}`"
              disabled
              label="Потери"
          />
        </b-col>
      </b-row>
      <b-row>
        <b-col cols="12" md="3">
          <oda-form-group
              accept="image/*"
              label="Фото показаний счетчика"
              placeholder="Выберите фото"
              file
              @input="onChange"
          />
        </b-col>
        <b-col cols="12" md="3" class="required">
          <oda-form-group
              v-model="bts.tariff_rate"
              label="Тариф"
              placeholder="Выберите тариф"
              number
              required
          />
        </b-col>
        <b-col cols="12" md="3">
          <oda-form-group
              v-model="btsSum"
              placeholder="Сумма"
              label="Сумма"
              disabled
          />
        </b-col>
      </b-row>
      <b-row v-if="client_type === 'entity'">
        <b-col cols="12" md="3" class="required">
          <oda-form-group
              v-model="bts.userDefinedUniqueCompletionCertificateId"
              placeholder="Номер АВР"
              label="Номер АВР"
              number
              integer
              required
          />
        </b-col>
      </b-row>
      <button
          type="submit"
          ref="form-submit"
          class="send-registry__submit"
      ></button>
      <b-row v-if="!isSumOk">
        <b-col cols="12" md="6">
          <div class="oda-alert danger">
            <div class="oda-alert__title">
              Внимание!
            </div>
            Итоговая сумма не может превышать
            <strong>{{ total_sum_limit }}</strong>
          </div>
        </b-col>
      </b-row>
    </b-form>

    <div class="send-registry__footer">
      <router-link
          to="/payment"
          class="oda-button bordered danger oda-desktop-only"
      >
        Отменить отправку
      </router-link>

      <router-link
          to="/m/payment"
          class="oda-button bordered danger oda-mobile-only"
      >
        Отменить отправку
      </router-link>

      <button
          :disabled="!isSumOk || !bts.start_dt || (btsSum < 0) || loading || Number(bts.counter_value) < Number(bts.counter_previous_value)"
          class="oda-button primary"
          @click="next"
      >
        Далее
      </button>
    </div>
  </div>
</template>

<script>
import { mapGetters, mapState } from 'vuex';
import imageCompression from 'browser-image-compression';
import Multiselect from 'vue-multiselect';
import OdaFormGroup from '../OdaFormGroup.vue';

export default {
  name: 'BtsForm',
  components: {
    OdaFormGroup,
    Multiselect,
  },

  data() {
    return {
      severalMonths: false,
      bts: {},
      bts_list: [],
      loading: false,
    };
  },

  watch: {
    // TODO: remove later
    active_contract(value) {
      const contr = this.contracts.find((c) => c.counterparty_contract === value);
      this.getBtsByContract(contr.contract_code).then(() => {
        this.$store.commit('registry/SET_ACTIVE_CONTRACT', this.active_contract);
      });
    },
  },

  computed: {
    ...mapState({
      contracts_loading: (state) => state.profile.loading,
      active_contract: (state) => state.profile.active_contract,
      bts_options: (state) => state.registry.bts_list,
      resend_item: (state) => state.electricity.resend_item,
      losses_allowed: (state) => state.registry.losses_allowed,
      payed_months: (state) => state.registry.payed_months,
      client_type: (state) => state.auth.client_type,
      isMobile: (state) => state.isMobile,
      contract_type: state => state.profile.contract_type,
    }),
    ...mapGetters({
      contracts: 'profile/electricity_active_contracts',
      clientHistory: 'registry/clientHistory',
      payment: 'electricity/payment',
    }),
    isResend() {
      const { query } = this.$route;
      return query.resend && this.resend_item;
    },
    btsSum() {
      const { bts } = this;
      const value = Number(bts.counter_value);
      const prev = Number(bts.counter_previous_value);
      const tariff = Number(bts.tariff_rate);
      const loses = Number(this.lossValue) || 0;
      return Number(((((value - prev) * tariff) + loses * this.monthsCount) || 0).toFixed(2));
    },
    startDtOptions() {
      return this.cp_months;
    },
    endDtOptions() {
      const i = this.cp_months.findIndex((m) => m === this.bts.start_dt) || 0;
      const nextMonthValue = (this.cp_months[i + 1] || {}).$isDisabled;
      if (nextMonthValue) return [];
      const options = this.cp_months.slice(i + 1, this.cp_months.length);
      const j = options.findIndex((m) => m.$isDisabled) || options.length;
      return options.slice(0, j > 0 ? j : options.length);
    },
    cp_months() {
      return this.payed_months.map((m) => ({
        label: `${m.name} ${m.year}`,
        value: `${m.start_date}/${m.end_date}`,
        $isDisabled: m.hasSentCounterReadings,
      }));
    },
    lossInfo() {
      const { bts_name: bts } = this.bts;
      return this.losses_allowed.filter((b) => b.bts === bts).length
          && this.losses_allowed.filter((b) => b.bts === bts)[0];
    },
    monthsCount() {
      if (this.severalMonths && this.bts.start_dt && this.bts.end_dt) {
        const startIndex = Object.values(this.monthDictionary)
            .findIndex((m) => this.bts.start_dt.label.includes(m));
        const endIndex = Object.values(this.monthDictionary)
            .findIndex((m) => this.bts.end_dt.label.includes(m));
        let monthsCount = Object.keys(this.monthDictionary)[endIndex]
            - Object.keys(this.monthDictionary)[startIndex];
        if (monthsCount < 0) monthsCount += 12;
        monthsCount += 1;

        return monthsCount;
      }
      return 1;
    },
    lossValue() {
      if (this.lossInfo && this.lossInfo.is_percent) {
        const value = Number(this.bts.counter_value);
        const prev = Number(this.bts.counter_previous_value);
        const tariff = Number(this.bts.tariff_rate);
        if (value && prev && tariff) {
          const sum = (((value - prev) * tariff) || 0);
          return (sum * (this.lossInfo.percent / 100)).toFixed(2);
        }
        return `${this.lossInfo.percent} %`;
      }
      return this.monthsCount * this.lossInfo.constant;
    },
    total_sum_limit() {
      let limit = this.$store.state.registry.total_sum_limit;
      if (this.severalMonths && this.bts.start_dt && this.bts.end_dt) {
        const startIndex = Object.values(this.monthDictionary)
          .findIndex((m) => this.bts.start_dt.label.includes(m));
        const endIndex = Object.values(this.monthDictionary)
          .findIndex((m) => this.bts.end_dt.label.includes(m));
        let monthsCount = Object.keys(this.monthDictionary)[endIndex]
            - Object.keys(this.monthDictionary)[startIndex];
        if (monthsCount < 0) monthsCount += 12;

        limit *= (monthsCount + 1);
      }

      return limit;
    },
    isSumOk() {
      if (!this.total_sum_limit) return true;
      return this.btsSum < this.total_sum_limit;
    },
    activeContractValue() {
      if (!this.contracts || !this.contracts.length) return;
      const contr = this.contracts.find((c) => c.counterparty_contract === this.active_contract);
      return contr.contract_code;
    },
  },

  mounted() {
    if (this.isResend) {
      if (!this.resend_item.id) {
        const { id } = this.$route.query;
        const route = `${this.isMobile ? '/m/': '/'}payment/${id}`;
        this.$router.push(route);
      }
      this.$set(this, 'bts', this.resend_item);
    }
    if (this.active_contract) {
      // TODO: remove later
      const contr = this.contracts.find((c) => c.counterparty_contract === this.active_contract);
      this.getBtsByContract(contr.contract_code).then(() => {
        this.$store.commit('registry/SET_ACTIVE_CONTRACT', this.active_contract);
      });
    }
    this.$store.dispatch('registry/getPaymentHistory');
    this.$store.dispatch('registry/getLossesAllowed');
    this.$store.dispatch('registry/getVariables');
  },

  methods: {
    async getBtsByContract(contract) {
      try {
        if (!this.isResend) this.bts = {};
        await this.$store.dispatch('registry/getBtsByContract', contract);
        await this.$store.dispatch('registry/getPayedMonths');
        if (this.isResend) {
          const dateIndex = this.cp_months
              .findIndex((item) => item.value.includes(this.resend_item.period));
          if (dateIndex < 0 && this.resend_item.period) {
            const start = this.resend_item.period.split('/')[0];
            const end = this.resend_item.period.split('/')[1];
            const startIndex = this.cp_months
                .findIndex((item) => item.value.includes(start));
            const endIndex = this.cp_months
                .findIndex((item) => item.value.includes(end));
            this.$set(this.bts, 'start_dt', this.cp_months[startIndex]);
            this.$set(this.bts, 'end_dt', this.cp_months[endIndex]);
          } else this.$set(this.bts, 'start_dt', this.cp_months[dateIndex]);
        }
        if (this.bts_options.length === 1) {
          this.$set(this.bts, 'bts_name', this.bts_options[0]);
          this.autocomplete();
        }
      } catch (e) {
        console.error(e);
      }
    },

    submit() {
      if (!this.isSumOk || !this.bts.start_dt || (this.btsSum < 0) || this.loading || Number(this.bts.counter_value) < Number(this.bts.counter_previous_value)) {
        return;
      }
      const contr = this.contracts.find((c) => c.counterparty_contract === this.active_contract);
      this.bts.contract_code = contr.contract_code;
      this.bts.sum = this.btsSum;
      this.loading = true;
      this.$emit('submit', this.bts);
    },
    next() {
      this.$refs['form-submit'].click();
    },
    setStartDate(date) {
      this.$set(this.bts, 'start_dt', date);
    },
    setEndDate(date) {
      this.$set(this.bts, 'end_dt', date);
    },
    async onChange(file) {
      this.uploading = true;
      this.bts.counter_photo = await this.compress(file);
      this.uploading = false;
    },
    async compress(image) {
      try {
        const options = {
          maxSizeMB: 1,
          maxWidthOrHeight: 1920,
          useWebWorker: true,
        };
        const compressedFile = await imageCompression(image, options);
        console.log(
          'compressedFile instanceof Blob',
          compressedFile instanceof Blob,
        ); // true
        console.log(`compressedFile size ${compressedFile.size / 1024 / 1024} MB`); // smaller than maxSizeMB
        return compressedFile;
      } catch (error) {
        console.log('Error while compressing');
        console.log(error);
        return image;
      }
    },

    onBtsPicked() {
      if (this.bts_options.length > 1) this.autocomplete();
    },

    async autocomplete() {
      if (this.isResend || !this.clientHistory.length) return;

      const last = this.clientHistory.find((item) => item.bts === this.bts.bts_name);

      if (last) {
        this.$set(this.bts, 'tariff_rate', last.tariff_rate);
        this.$set(this.bts, 'counter_previous_value', last.counter_value);

        const dateIndex = this.cp_months.findIndex((item) => item.value.includes(last.end_dt));
        const date = this.cp_months[dateIndex + 1];
        if (date) this.$set(this.bts, 'start_dt', date);
        if (this.client_type === 'entity') {
          const step = (this.contract_type || {}).hasRent ? 2 : 1;
          await this.$store.dispatch('electricity/getElectricity', last.id);
          const count = this.payment.completion_certificates.length;
          if (count) {
            const { uniqueCompletionCertificateId: id } = this.payment.completion_certificates[count - 1].state;
            this.bts.userDefinedUniqueCompletionCertificateId = id + step;
            this.$set(this, 'bts', {...this.bts, ...{userDefinedUniqueCompletionCertificateId: id + step}});
          }
        }
      } else {
        this.bts = {};
      }
    },
  },
};
</script>
