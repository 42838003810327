<template>
  <section class="oda-section">
    <section-header section="Обновление документов" />
    <div class="payments-body">
      <div class="payments-section">
        <div class="payments-section__header">
          <div class="payments-section__header-left">
            <h3>Запросы на обновление</h3>
          </div>
          <div class="payments-section__header-right">
            <button
                :disabled="loading"
                class="oda-button primary"
                @click="downloadExel"
            >
              Экспорт в Excel
            </button>
          </div>
        </div>

        <oda-table
            :fields="fields"
            :items="submissions"
            custom-pagination
            @rowClicked="rowClicked"
            @sortChanged="onSorting"
            @filter="onFilter"
        >
          <template slot="custom_pagination">
            <oda-table-pagination
                :per-page="params['_limit']"
                :current-page="currentPage"
                :total-rows="totalRows"
                @pageChange="setPage"
                @perPageChange="setPerPage"
            />
          </template>
        </oda-table>
      </div>
    </div>
  </section>
</template>

<script>
import SectionHeader from '@/components/SectionHeader.vue';
import OdaTable from '@/components/OdaTable.vue';
import OdaTablePagination from '@/components/OdaTablePagination.vue';
import fileDownload from 'js-file-download';
import { mapState } from 'vuex';

export default {
  name: 'DocumentsUpdateEmployee',
  components: {
    SectionHeader,
    OdaTable,
    OdaTablePagination,
  },

  data() {
    return {
      tabs: [
        { name: 'phys', title: 'Физические лица' },
        { name: 'legal', title: 'Юридические лица' },
        { name: 'ksk', title: 'КСК / ОСИ' },
      ],
      fields: [
        {
          key: 'counterparty',
          label: 'Контрагент',
          sortable: true,
          searchable: true,
        },
        {
          key: 'type',
          label: 'Тип',
          sortable: true,
          searchable: true,
        },
        {
          key: 'iin',
          label: 'ИИН/БИН',
          sortable: true,
          searchable: true,
        },
        {
          key: 'approved_by_employee',
          label: 'ОДА',
          sortable: true,
        },
        {
          key: 'approved_by_builder',
          label: 'ОС',
          sortable: true,
        },
        {
          key: 'files_uploaded',
          label: 'Загружено',
          sortable: true,
        },
      ],
      params: {},
      searchValue: null,
      loading: false,
    };
  },

  computed: {
    ...mapState({
      submissions: (state) => state.documentsUpload.doc_submissions,
      totalRows: (state) => state.documentsUpload.doc_submissions_total,
    }),
    currentPage() {
      // eslint-disable-next-line dot-notation
      return (this.params['_start'] / this.params['_limit']) || 1;
    },
  },

  async mounted() {
    try {
      await this.$store.dispatch('documentsUpload/getDocSubmissionsTotal');
      // this.$set(this.params, '_limit', 10);
      this.setParam('_limit', 10);
    } catch (e) {
      console.error(e);
    }
  },

  methods: {
    async getSubmissions() {
      await this.$store.dispatch('documentsUpload/getDocSubmissions', this.params);
    },
    rowClicked(row) {
      this.$router.push(`/docs-update/${row.id}`);
    },
    setParam(key, value) {
      this.$set(this.params, key, value);
      this.getSubmissions();
    },
    setPerPage(perPage) {
      this.setParam('_limit', perPage);
    },
    setPage(page) {
      // eslint-disable-next-line dot-notation
      const perPage = this.params['_limit'];
      if (page === 1) this.$set(this.params, '_start', 0);
      else this.$set(this.params, '_start', page * perPage);
      this.getSubmissions();
    },
    onSorting({ sortBy, sortDesc }) {
      this.setParam('_sort', `${sortBy}:${sortDesc ? 'DESC' : 'ASC'}`);
    },
    downloadExel() {
      this.loading = true;
      this.$axios({
        url: 'mass-document-submissions/download/csv',
        method: 'GET',
        responseType: 'blob',
      }).then((res) => {
        fileDownload(res.data, 'file.csv');
      }).finally(() => {
        this.loading = false;
      });
    },
    onFilter({ key, value }) {
      this.setParam(key, value);
    },
  },
};
</script>
