<template>
  <section class="oda-section send-registry">
    <div class="oda-mobile-only send-registry-back">
      <button
          class="send-registry__head-button oda-button primary feather icon-chevron-left"
          @click="back"
      ></button>
      Назад
    </div>
    <div class="send-registry__head oda-card">
      <div class="send-registry__head-content">
        <button
            class="send-registry__head-button oda-button primary feather icon-chevron-left oda-desktop-only"
            @click="backD"
        ></button>
        <h2>Отправить показания счетчика</h2>
        <div class="oda-badge primary">{{ step }} / 2</div>
      </div>
      <router-link
          to="/payment"
          class="send-registry__head-button oda-button primary feather icon-x oda-desktop-only"
      ></router-link>
    </div>

    <bts-form
        v-if="step === 1"
        @submit="step1Submit"
    />

    <rental-form
        v-if="step === 2"
        :bts="bts"
        electricity
        @submit="signEcp"
    />
    <electricity-form
        v-if="step === 2 && false"
        :bts="bts"
        @submit="signEcp"
    />

    <b-modal
        ref="ecp-modal"
        :no-close-on-backdrop="loading"
        hide-header
        hide-footer
    >
      <b-form @submit.prevent="submit">
        <oda-form-group
            v-model="file"
            label="Ключ ЭЦП"
            placeholder="Выберите файл"
            class="file-ecp"
            accept=".p12"
            file
            required
        />
        <oda-form-group
            v-model="bts.password"
            label="Пароль"
            placeholder="Введите пароль"
            type="password"
            required
        />
        <button
            :class="{'loading': loading}"
            :disabled="loading"
            class="oda-button primary mt-4"
            type="submit"
        >
          Отправить
        </button>
      </b-form>
    </b-modal>
  </section>
</template>

<script>

import BtsForm from "@/components/SendCounterReadings/BtsForm";
import RentalForm from "@/components/SendCounterReadings/RentalForm";
import ElectricityForm from "@/components/SendCounterReadings/ElectricityForm";
import OdaFormGroup from "@/components/OdaFormGroup";
import {mapActions, mapGetters, mapState} from "vuex";

export default {
  name: "Individual",
  components: {
    BtsForm,
    ElectricityForm,
    RentalForm,
    OdaFormGroup,
  },

  data() {
    return {
      step: 1,
      bts: {},
      file: null,
      loading: false,
    }
  },

  computed: {
    ...mapState({
      active_contract: state => state.registry.active_contract,
      resend_item: state => state.electricity.resend_item,
      isMobile: state => state.isMobile,
    }),
    ...mapGetters({
      contracts: 'profile/electricity_active_contracts',
    }),
    isResend() {
      const { query } = this.$route;
      return Object.values(this.resend_item).length && query.resend;
    },
  },

  async mounted() {
    try {
      await this.getContractType();
      if (this.isResend) {
        const contract = this.contracts.filter(item => item.contract_code === this.resend_item.contract_code);
        console.log(this.contracts);
        this.$store.commit('registry/SET_ACTIVE_CONTRACT', contract[0].contract_code);
        this.$store.dispatch('registry/getBtsByContract', contract[0].contract_code);
      }
    } catch(e) {
      console.error(e);
    }
  },

  methods: {
    ...mapActions('profile', [
        'getContractType'
    ]),
    async step1Submit(data) {
      const additional = {
        client: this.currentUser.name,
        contract: this.active_contract,
      };
      this.bts = {...data, ...additional};

      const contract = this.contracts.find(c => c.counterparty_contract === this.active_contract);
      const { bts } = this;

      bts.end_dt = (bts.end_dt || bts.start_dt).value.split('/')[1];
      bts.start_dt = bts.start_dt.value.split('/')[0];

      const params = {
        contract_code: contract.contract_code,
        counter_value: Number(bts.counter_value),
        counter_previous_value: Number(bts.counter_previous_value),
        tariff_rate: Number(bts.tariff_rate),
        end_dt: bts.end_dt,
        start_dt: bts.start_dt,
        bts: bts.bts_name,
        condition_type: 'электроэнергия',
        application_name: 'actual_area_usage_act'
      };
      const payload = {
        url: 'applications/pdf/render',
        params
      };

      await this.$store.dispatch('registry/generateDynamicPDF', payload);

      this.step = 2;
    },
    signEcp() {
      this.$refs['ecp-modal'].show();
    },
    submit() {
      this.loading = true;
      const item = this.bts;
      const contract = this.contracts.find(c => c.counterparty_contract === this.active_contract);
      const params = {
        start_dt: item.start_dt,
        end_dt: item.end_dt,
        contract_code: contract.contract_code,
        counter_value: item.counter_value,
        counter_previous_value: item.counter_previous_value,
        tariff_rate: item.tariff_rate,
        password: item.password,
        bts: item.bts_name,
      }
      const formData = new FormData;
      Object.values(params).forEach((value,i) => {
        const keys = Object.keys(params);
        formData.append(keys[i], value);
      })
      formData.append('datacert', this.file);
      if (item.counter_photo)
        formData.append('counter_photo', item.counter_photo);

      if (this.isResend) {
        formData.append('group_id', this.resend_item.group_id);
        this.$store.dispatch('registry/resendCounterReading', formData)
            .finally(() => {
              this.loading = false;
            });
        return;
      }

      this.$store.dispatch('registry/generateAndSavePDF', formData)
          .finally(() => {
            this.loading = false;
          });
    },
    back() {
      if (this.step === 1) this.$router.push('/m/payment');
      else this.step = 1;
    },
    backD() {
      if (this.step === 1) this.$router.push('/payment');
      else this.step = 1;
    },
  },
}
</script>
