<template>
  <section class="oda-section electricity">
    <section-header section="Архив" />

    <div class="payments-body">
      <div class="payments-section">
        <div class="payments-section__header">
          <div class="payments-section__header-left">
            <h3>История показаний</h3>
          </div>
        </div>

        <oda-table
            ref="electricity-table"
            :fields="userRole === 'manager' ? fieldsManager : fieldsEmployee"
            :items="items"
            row-clickable
            custom-pagination
            @rowClicked="openPayment"
            @filter="onFilter"
        />
      </div>
    </div>
  </section>
</template>

<script>
import SectionHeader from "../../components/SectionHeader";
import OdaTable from "../../components/OdaTable";
import { mapState } from "vuex";

export default {
  name: "Archive",
  components: {
    SectionHeader,
    OdaTable,
  },

  data() {
    return {
      tabs: [
        {name: 'active', title: 'Все платежи'},
        {name: 'paid', title: 'Оплаченные'},
      ],
      fieldsManager: [
        { key: 'counterparty', label: 'Контрагент', sortable: true, searchable: true },
        { key: 'bts', label: 'Объект', sortable: true, searchable: true },
        { key: 'period', label: 'Период', sortable: true, searchable: false },
        { key: 'contract_sum', label: 'Сумма в тг', sortable: true, searchable: true },
        { key: 'created_at', label: 'Дата', sortable: true, searchable: false },
        { key: 'status', label: 'Статус', sortable: true, searchable: true },
      ],
      fieldsEmployee: [
        { key: 'counterparty', label: 'Контрагент', sortable: true, searchable: true },
        { key: 'bts', label: 'Объект', sortable: true, searchable: true },
        { key: 'period', label: 'Период', sortable: true, searchable: false },
        { key: 'total_sum', label: 'Сумма в тг', sortable: true, searchable: true },
        { key: 'created_at', label: 'Дата', sortable: true, searchable: false },
        { key: 'reading_status', label: 'Статус', sortable: true, searchable: true },
      ],
      pickedItems: [],
      pickAvailable: false,
      widths: [65],
      disabledColumns: [0],
      filter: {},
    }
  },

  computed: {
    ...mapState({
      items: state => state.electricity.items,
      loading: state => state.electricity.loading,
      userRole: state => state.auth.userRole,
    }),
    statuses() {
      return this.items.filter(item => item.status && item.status === 'Не оплачено');
    },
  },


  mounted() {
    if (this.userRole === 'employe') this.$store.dispatch( 'electricity/getEmployeePayments');
    else this.$store.dispatch('electricity/getManagerArchivePayments');
  },
  methods: {
    openPayment(row) {
      this.$router.push(`/archive/${row.id}`)
    },
    onFilter({key, value}) {
      this.$set(this.filter, `${key}_contains`, value);
      this.getItems();
    },
    getItems() {
      this.$store.dispatch( 'electricity/getManagerArchivePayments', this.filter);
    },
  },
}
</script>
