<template>
  <div
      v-if="currentUser && currentUser.id"
      class="nav-mobile"
  >
    <div class="nav-mobile__bar">
      <div
          class="nav-mobile__bar-button feather icon-menu"
          @click="active = true"
      ></div>
      <div class="oda-section__header-user">
        <div class="oda-section__header-user-info">
          <div class="oda-section__header-user-name">
            {{ currentUser.name || currentUser.username }}
          </div>
          <div class="oda-section__header-user-role">
            {{ currentUser.role && currentUser.role.description }}
          </div>
        </div>
        <div class="oda-section__header-user-avatar">
          <span>{{ (currentUser.name && currentUser.name[0]) || currentUser.username[0].toUpperCase() }}</span>
        </div>
      </div>
    </div>

    <div
        :class="{'active': active}"
        class="nav-mobile__menu"
    >
      <div class="nav-mobile__menu-head">
        <img src="@/assets/images/tele2-logo.svg" alt="tele2">
        <div
            class="nav-mobile__menu-x feather icon-x"
            @click="active = false"
        ></div>
      </div>

      <div
          v-for="(item, i) in menuFiltered"
          :key="`${i}=${item.title}`"
      >
        <div
            v-if="item.header"
            class="nav-mobile__menu-header"
        >
          {{ item.title }}
        </div>
        <div
            v-else
            :class="{'active': $route.path === item.href}"
            class="nav-mobile__menu-item"
            @click="openLink(item.href)"
        >
          <div :class="item.icon"></div>
          {{ item.title }}
        </div>
      </div>
      <div
          class="nav-mobile__menu-item"
          @click="logout"
      >
        <div class="feather icon-log-out"></div>
        Выход
      </div>
    </div>
  </div>
</template>

<script>
import {mapActions, mapState} from "vuex";

export default {
  name: "OdaNavbarMobile",
  data() {
    return {
      active: false,
      menu: [
        {
          href: '/',
          title: 'Мой профиль',
          icon: 'feather icon-user',
          access: ['electricity', 'rent'],
        },
        {
          header: true,
          title: 'Электроэнергия',
          access: ['electricity']
        },
        {
          href: '/m/payment',
          title: 'Электроэнергия',
          icon: 'feather icon-zap',
          access: ['electricity']
        },
        {
          header: true,
          title: 'Аренда',
          access: ['rent']
        },
        {
          href: '/m/rents',
          title: 'Аренда',
          icon: 'feather icon-home',
          access: ['rent']
        },
      ],
    }
  },

  computed: {
    ...mapState({
      clientContractType: (state) => state.profile.contract_type,
      client_type: (state) => state.auth.client_type,
    }),
    menuFiltered() {
      const { path } = this.$route;
      if (path === '/terms-of-use') return [];
      const { hasRent } = this.clientContractType || {};
      const { hasElectricity } = this.clientContractType || {};
      return this.menu.filter(item => {
        if (item.access.includes('rent')) {
          if (item.access.includes('electricity')) return hasElectricity;
          return hasRent && this.client_type === 'entity';
        }
        if (item.access.includes('electricity')) return hasElectricity;
      })
    },
  },

  mounted() {
    if (!this.clientContractType && this.userRole === 'client') this.getContractType();
  },

  methods: {
    ...mapActions('profile', [
      'getContractType',
    ]),
    logout() {
      this.$store.dispatch('auth/logout');
      this.active = false;
    },
    openLink(href) {
      this.$router.push(href);
      this.active = false;
    },
  },
}
</script>
