<template>
  <div class="oda-collapse">
    <div
        v-for="item in items"
        :key="item.title"
        class="oda-collapse__item"
        @click="setVisible(item.title)"
    >
      <div class="oda-collapse__item-header">
        <div class="oda-collapse__item-header-title">
          {{ item.title }}
        </div>
        <div class="oda-collapse__item-header-arrow">
          <span v-if="visible === item.title" class="feather icon-chevron-up"></span>
          <span v-else class="feather icon-chevron-down"></span>
        </div>
      </div>
      <b-collapse :visible="visible === item.title">
        <div
            v-html="item.text"
            class="oda-collapse__item-body"
        ></div>
      </b-collapse>
    </div>
  </div>
</template>

<script>
export default {
  name: "OdaCollapse",
  props: {
    items: Array,
  },
  data() {
    return {
      visible: '',
    }
  },
  methods: {
    setVisible(title) {
      if (title === this.visible) this.visible = '';
      else this.visible = title;
    },
  },
}
</script>
