import MainApiService from "@/services/api";

const AuthApiService = {
    login(data) {
        return MainApiService.post('auth/local', {identifier: data.username, password: data.password});
    },
    getUserInfo() {
        return MainApiService.get('users/me');
    },
    getContractType() {
        return MainApiService.get('conditions/client/active');
    },
    acceptTermsOfUse() {
        return MainApiService.post('terms-of-uses', { accepted: true });
    }
}

export default AuthApiService;
