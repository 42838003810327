import clientServices from "@/services/clientServices";
import employeeServices from "@/services/employeeServices";
import errorService from "@/services/errorService";
import axios from "axios";
import authServices from "@/services/authServices";

const state = {
    active_contract: '',
    loading: false,
    needs_to_sign: null,
    rent_contract_sums: {},
    contract_type: null,

    employeeCounterparties: [],
    counterparty: null,
    counterparty_bts: '',
    counterparty_electricity_losses: null,
    active_conditions: null,
};

const getters = {
    rentsToSign: state => {
        const array = [];
        if (!state.needs_to_sign) return array;
        state.needs_to_sign.forEach((item) => {
            if (item.hasntSignedYet && item.hasntSignedYet.length) {
                item.hasntSignedYet.forEach((month) => {
                    array.push({
                        bts: item.bts,
                        contract_code: item.contract_code,
                        start_dt: month,
                        total_sum: state.rent_contract_sums[item.contract_code],
                        ecp: false,
                        period: month,
                    });
                })
            }
        })
        return array.reverse();
    },
    rent_active_contracts: state => {
        return  state.contract_type && state.contract_type.rent;
    },
    electricity_active_contracts: state => {
        return state.contract_type && state.contract_type.electricity;
    },
};

const mutations = {
    SET_RENT_ACTIVE_CONTRACTS(state, payload) {
        state.rent_active_contracts = payload;
    },
    SET_ELECTRICITY_ACTIVE_CONTRACTS(state, payload) {
        state.electricity_active_contracts = payload;
    },
    SET_ACTIVE_CONTRACT(state, payload) {
        state.active_contract = payload;
    },
    SET_LOADING(state, payload) {
        state.loading = payload;
    },
    SET_NEEDS_TO_SIGN(state, payload) {
        state.needs_to_sign = payload;
    },
    SET_RENT_CONTRACT_SUM(state, payload) {
        state.rent_contract_sums[payload.contract_code] = payload.sum_1;
    },
    SET_CONTRACT_TYPE(state, payload) {
        if (payload && payload.electricity && payload.electricity.length)
            payload.hasElectricity = true;
        if (payload && payload.rent && payload.rent.length)
            payload.hasRent = true;
        state.contract_type = payload;
    },
    SET_EMPLOYEE_COUNTERPARTIES(state, payload) {
        state.employeeCounterparties = payload;
    },
    SET_COUNTERPARTY(state, payload) {
        state.counterparty = payload;
    },
    SET_COUNTERPARTY_BTS(state, payload) {
        state.counterparty_bts = payload;
    },
    SET_COUNTERPARTY_ELECTRICITY_LOSSES(state, payload) {
        state.counterparty_electricity_losses = payload;
    },
    SET_ACTIVE_CONDITIONS(state, payload) {
        state.active_conditions = payload;
    },
};

const actions = {
    async getNeedsToSign({ dispatch, commit }) {
        try {
            const { data } = await clientServices.getNeedsToSign();
            for (let i = 0; i < data.length; i += 1) {
                await dispatch('getRentalSum', { contract_code: data[i].contract_code})
            }
            commit('SET_NEEDS_TO_SIGN', data);
        } catch (e) {
            errorService.handleError(e);
        }
    },
    async getRentalSum({ commit }, params) {
        try {
            const { data } = await axios.get('rents/client/rent_sum', { params });
            commit('SET_RENT_CONTRACT_SUM', data);
        } catch (e) {
            errorService.handleError(e);
        }
    },

    async getContractType({ commit }) {
        try {
            const { data } = await authServices.getContractType();
            commit('SET_CONTRACT_TYPE', data);
            if (data && data.electricity && data.electricity[0]) {
                commit('SET_ACTIVE_CONTRACT', data.electricity[0].counterparty_contract)
            }
        } catch (e) {
            errorService.handleError(e);
        }
    },

    async sendFeedback(_, params) {
        try {
            await clientServices.sendFeedback(params);
            errorService.onSuccess("Мы приняли вашу обратную связь, скоро свяжемся с результатами.");
        } catch (e) {
            errorService.handleError(e);
        }
    },

    async getCounterpartiesByEmployee({ commit }, employee) {
        try {
            const { data } = await employeeServices.getCounterpartiesByEmployee(employee);
            commit('SET_EMPLOYEE_COUNTERPARTIES', data)
        } catch (e) {
            errorService.handleError(e);
        }
    },
    async getCounterparty({ commit }, id) {
        commit('SET_COUNTERPARTY', null);
        try {
            const { data } = await employeeServices.getCounterparty(id);
            commit('SET_COUNTERPARTY', data);
        } catch (e) {
            errorService.handleError(e);
        }
    },
    async updateCounterparty({ commit }, form) {
        try {
            const { data } = await employeeServices.updateCounterparty(form);
            commit('SET_COUNTERPARTY', data);
            errorService.onSuccess("Изменения сохранены");
        } catch (e) {
            errorService.handleError(e);
        }
    },
    async createCounterparty(_, form) {
        try {
            await employeeServices.createCounterparty(form);
            errorService.onSuccess("Создан новый АД");
        } catch (e) {
            errorService.handleError(e);
        }
    },
    async getBtsByCounterparty({ commit }, params) {
        try {
            const { data } = await employeeServices.getBtsByCounterparty(params);
            commit("SET_COUNTERPARTY_BTS", data);
        } catch (e) {
            errorService.handleError(e);
        }
    },
    async getElectricityLossesByBts({ commit }, params) {
        try {
            const { data } = await employeeServices.getElectricityLossesByBts(params);
            commit("SET_COUNTERPARTY_ELECTRICITY_LOSSES", data);
        } catch (e) {
            commit("SET_COUNTERPARTY_ELECTRICITY_LOSSES", null);
            console.log(e);
        }
    },
    async getActiveConditions({ commit }, params) {
        try {
            const { data } = await employeeServices.getActiveConditions(params);
            commit("SET_ACTIVE_CONDITIONS", data);
        } catch (e) {
            commit("SET_ACTIVE_CONDITIONS", null);
            console.log(e);
        }
    },
    async updateElectricityLoss({ commit }, params) {
        try {
            const { data } = await employeeServices.updateElectricityLoss(params);
            commit("SET_COUNTERPARTY_ELECTRICITY_LOSSES", data);
            errorService.onSuccess("Изменения сохранены");
        } catch (e) {
            errorService.handleError(e);
        }
    },
    async createElectricityLoss({ commit }, params) {
        try {
            const { data } = await employeeServices.createElectricityLoss(params);
            commit("SET_COUNTERPARTY_ELECTRICITY_LOSSES", data);
            errorService.onSuccess("Изменения сохранены");
        } catch (e) {
            errorService.handleError(e);
        }
    }
};

export default {
    namespaced: true,
    state,
    getters,
    mutations,
    actions
}
