<template>
  <div class="du-auth__form">
    <oda-alert
        title="Ошибка!"
        type="danger  "
        :text="errorText"
    />
    <oda-form-group
        :placeholder="iin"
        label="ИИН / БИН"
        disabled
    />
    <div
        class="du-auth__form-link"
        @click="backToAuth"
    >
      <span class="feather icon-chevron-left"></span>
      Заново ввести ИИН / БИН
    </div>
  </div>
</template>

<script>
import OdaFormGroup from "../../../../components/OdaFormGroup";
import OdaAlert from "../../../../components/OdaAlert";
import { mapState } from "vuex";

export default {
  name: "DocumentsUploadAuthError",
  components: {
    OdaFormGroup,
    OdaAlert,
  },
  props: {
    errorText: String,
  },

  computed: {
    ...mapState({
      iin: state => state.documentsUpload.iin,
    })
  },

  methods: {
    backToAuth() {
      this.$emit('back');
    },
  },
}
</script>