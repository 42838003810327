<template>
  <section class="oda-section auth">
    <div class="auth-card">
      <div class="auth-card__logo">
        <img
            src="@/assets/images/tele2-logo.svg"
            alt="tele2"
        >
      </div>

      <form
          class="auth-form"
          @submit.prevent="loginUser"
      >
        <oda-form-group
            v-model="user.username"
            placeholder="johndoe@gmail.com"
            label="Логин"
            required
            test-id="user-identifier"
        />
        <oda-form-group
            v-model="user.password"
            placeholder="⚉ ⚉ ⚉ ⚉ ⚉ ⚉ ⚉ ⚉"
            label="Пароль"
            type="password"
            required
            test-id="user-password"
        />

        <div class="auth-form__errors">
          <div
              v-for="error in errors"
              :key="error.id"
              class="auth-form__errors-item"
          >
            {{ getErrorMessage(error.message) }} <!-- hz! -->
          </div>
        </div>

        <button
            class="oda-button primary auth-form__submit mt-3"
            type="submit"
        >
          Войти
        </button>
      </form>
    </div>
  </section>
</template>

<script>
import OdaFormGroup from '@/components/OdaFormGroup.vue';
import { mapState, mapActions } from 'vuex';

export default {
  name: 'Auth',
  components: {
    OdaFormGroup,
  },
  data() {
    return {
      user: {},
      privacyPolicy: false,
    };
  },
  computed: {
    ...mapState({
      errors: (state) => state.auth.auth_errors,
    }),
  },
  methods: {
    ...mapActions('auth', [
      'login',
    ]),
    loginUser() {
      this.user.username = this.user.username.toLowerCase();
      this.login(this.user);
    },
    getErrorMessage(message) {
      // TODO: translate error messages on back
      if (message === 'Your account has been blocked by an administrator')
        return 'Ваш аккаунт был заблокирован администратором';
      if (message === 'Too many attempts, please try again in a minute.')
        return 'Слишком много попыток, повторите через несколько минут';
      return message === 'Identifier or password invalid.' ? 'Логин или пароль неправильный' : message;
    },
  },
};
</script>
