<template>
  <b-modal
      ref="modal"
      hide-header
      hide-footer
      lazy
  >
    <b-form @submit.prevent="send">
      <oda-form-group
          v-model="phone"
          label="Номер WhastApp"
          phone
          required
      />

      <oda-form-group
          v-model="text"
          label="Описание проблемы"
          textarea
          placeholder="Описание проблемы с которой столкнулись или предложения по улучшению функционала"
          required
      />

      <div class="feedback-form__footer">
        <button
            class="oda-button primary"
            type="submit"
        >
          Отправить
        </button>
        <button
            class="oda-button danger"
            @click="cancel"
        >
          Отменить
        </button>
      </div>
    </b-form>

  </b-modal>
</template>

<script>
import OdaFormGroup from "./OdaFormGroup";
import {mapActions} from "vuex";

export default {
  name: "FeedbackForm",
  components: {
    OdaFormGroup,
  },

  data() {
    return {
      phone: '',
      text: '',
    }
  },

  mounted() {
    if (this.currentUser && this.currentUser.phone_number) {
      this.phone = this.currentUser.phone_number;
    }
  },

  methods: {
    ...mapActions('profile', [
        'sendFeedback',
    ]),
    async send() {
      const params = {
        phone: this.phone.replace(/[^a-zA-Z0-9]/g, ''),
        text: this.text,
      };
      await this.sendFeedback(params);
      this.text = '';
      this.$emit('close');
    },
    cancel() {
      this.text = '';
      this.$emit('close');
    },
  },
}
</script>
