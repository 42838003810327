<template>
  <b-tabs class="oda-tabs">
    <b-tab
        v-for="(item, i) in tabs"
        :key="`oda-tab-item-${item.name}`"
        :title="item.title"
        :active="i === activeTab"
        :lazy="item.lazy"
        :disabled="item.disabled"
        :title-item-class="item.icon"
        @click="onTabClick(item)"
    >
      <slot :name="item.name"></slot>
      <div
          v-if="item.empty"
          class="oda-tabs__empty"
      >
        <img src="@/assets/images/empty.svg" alt="no-data">
        <div class="oda-tabs__empty-text">{{ item.emptyText }}</div>
      </div>
    </b-tab>
  </b-tabs>
</template>

<script>
export default {
  name: "OdaTabs",
  props: {
    tabs: Array,
    activeTab: {
      type: Number,
      default: 0,
    }
  },
  methods: {
    onTabClick(item) {
      const currentLink = this.$route.path;
      if (item.link && item.link !== currentLink) {
        this.$router.push(item.link);
      }
    },
  },
}
</script>
