import axios from 'axios';
import router from '@/router';
import Vue from 'vue';
import clientServices from '@/services/clientServices';
import commonRoleServices from '@/services/commonRoleServices';
import errorService from '@/services/errorService';

const state = {
  bts_list: [],
  active_contract: '',
  payment_history: [],
  pdf_preview_link: '',
  losses_allowed: [],
  payed_months: [],
  total_sum_limit: 0,
  rental_sum: 0,

  pdf_elinks: [],
  pdf_rlinks: [],

  signed_rents: [],

  rents_item: null,
};

const getters = {
  clientHistory: (state) => {
    const history = [];
    state.payment_history.forEach((item) => {
      history.push({...item.actual_area_usage_act, ...item});
    });
    return history.sort((a, b) => {
      if (a.created_at > b.created_at) {
        return -1;
      }
      if (a.created_at < b.created_at) {
        return 1;
      }
      return 0;
    });
  },
  clientAveragePaymentSum: (state) => {
    if (state.payment_history.length < 1) return 0;
    const array = state.payment_history.map((p) => p.total_sum);
    const sum = array.reduce((prev, cur) => prev + cur);
    return (sum / array.length).toFixed(2);
  },
};

const mutations = {
  SET_BTS_LIST(state, payload) {
    state.bts_list = payload;
  },
  SET_ACTIVE_CONTRACT(state, payload) {
    state.active_contract = payload;
  },
  SET_PAYMENT_HISTORY(state, payload) {
    state.payment_history = payload;
  },
  SET_PDF_PREVIEW_LINK(state, payload) {
    state.pdf_preview_link = payload;
  },
  SET_LOSSES_ALLOWED(state, payload) {
    state.losses_allowed = payload;
  },
  SET_PAYED_MOTHS(state, payload) {
    state.payed_months = payload;
  },
  SET_TOTAL_SUM_LIMIT(state, payload) {
    state.total_sum_limit = payload;
  },

  SET_PDF_ELINKS(state, payload) {
    state.pdf_elinks = payload;
  },
  SET_PDF_RLINKS(state, payload) {
    state.pdf_rlinks = payload;
  },
  SET_RENTAL_SUM(state, payload) {
    state.rental_sum = payload.sum_1_payment || payload.sum_2_payment;
  },
  SET_SIGNED_RENTS(state, payload) {
    state.signed_rents = payload;
  },
  SET_RENTS_ITEM(state, payload) {
    state.rents_item = payload;
  },
};

const actions = {
  async getBtsByContract({ commit }, contract) {
    // TODO: move to services
    const params = {
      contract_code: contract,
    };
    const { data } = await axios.get('bts/over-contract', { params });
    commit('SET_BTS_LIST', data);
  },
  async getRentalSum({ commit }, params) {
    try {
      const { data } = await axios.get('rents/client/rent_sum', { params });
      commit('SET_RENTAL_SUM', data);
    } catch (e) {
      errorService.handleError(e);
    }
  },

  async getLossesAllowed({ commit }) {
    try {
      const { data } = await clientServices.getLossesAllowed();
      commit('SET_LOSSES_ALLOWED', data);
    } catch (e) {
      errorService.handleError(e);
    }
  },

  async generateDynamicPDF({ dispatch, commit }, { url, params }) {
    const start = params.start_dt.split('-');
    const end = params.end_dt.split('-');
    const startYear = parseInt(start[0]);
    const endYear = parseInt(end[0]);
    const dates = [];

    for (let i = startYear; i <= endYear; i++) {
      const endMonth = i !== endYear ? 11 : parseInt(end[1]) - 1;
      const startMonth = i === startYear ? parseInt(start[1]) - 1 : 0;
      for (let j = startMonth; j <= endMonth; j = j > 12 ? j % 12 || 11 : j + 1) {
        const month = j + 1;
        const displayMonth = month < 10 ? '0' + month : month;
        dates.push([i, displayMonth, '01'].join('-'));
      }
    }

    const electricityLinks = [];
    const rentalLinks = [];

    // const average_electricity = (Number(params.counter_value) - Number(params.counter_previous_value))/dates.length;
    const kwtUsed = (Number(params.counter_value) - Number(params.counter_previous_value));
    const remainder = kwtUsed % dates.length;
    const base = Math.trunc(kwtUsed / dates.length);


    for (let i = 0; i < dates.length; i++) {
      const date = dates[i];
      const pdfParams = params,
        counter_previous_value = Number(params.counter_previous_value) + i*base;
      const year = date.split('-')[0],
        month = date.split('-')[1],
        last_day = new Date(year, month,0).getDate(),
        end_dt = `${year}-${month}-${last_day}`;
      if (params.userDefinedUniqueCompletionCertificateId) {
        if (params.electricityOnly) {
          let customId = Number(params.userDefinedUniqueCompletionCertificateId) + (i ? 1: 0);
          Vue.set(pdfParams, 'userDefinedUniqueCompletionCertificateId',customId);
        } else {
          const isRental = params.condition_type === 'аренда';
          let customId = i === 0 ? Number(params.userDefinedUniqueCompletionCertificateId) : Number(params.userDefinedUniqueCompletionCertificateId) + 2;
          if (isRental) {
            if (i === 0) customId += 1;
            Vue.set(pdfParams, 'userDefinedUniqueCompletionCertificateId',customId);
          } else {
            Vue.set(pdfParams, 'userDefinedUniqueCompletionCertificateId',customId);
          }
        }
      }
      Vue.set(pdfParams, 'counter_previous_value', counter_previous_value);
      Vue.set(pdfParams, 'counter_value', counter_previous_value + base + (i + 1 === dates.length ? remainder : 0));
      Vue.set(pdfParams, 'start_dt', date);
      Vue.set(pdfParams, 'end_dt', end_dt);

      let electricityOnly = params.electricityOnly;
      delete params.electricityOnly;

      await dispatch('generatePDF', { params: pdfParams, url }).then((link) => {
        if (params.condition_type === 'электроэнергия') electricityLinks[i] = link;
        else rentalLinks.push(link);
      });
      params.electricityOnly = electricityOnly;
    }
    if (params.condition_type === 'электроэнергия') commit('SET_PDF_ELINKS', electricityLinks);
    else commit('SET_PDF_RLINKS', rentalLinks);
  },

  async generatePDF({ commit }, d) {
    try {
      const { data } = await axios({
        url: d.url,
        method: 'GET',
        responseType: 'arraybuffer',
        params: d.params,
      })
      let blob = await new Blob([data], { type: 'application/pdf' })
      let url = '';
      if ( window.webkitURL ) {
        url = window.webkitURL.createObjectURL(blob);
      } else if ( window.URL && window.URL.createObjectURL ) {
        url = window.URL.createObjectURL(blob);
      }
      commit('SET_PDF_PREVIEW_LINK', url);
      return url;
    } catch (e) {
      console.error(e);
    }
  },

  async generateAndSavePDF({ rootState }, params) {
    try {
      await clientServices.generateAndSavePDF(params);
      await router.push(`${rootState.isMobile ? '/m/': '/'}payment`);
      errorService.onSuccess('Показание счетчика успешно отправлено!');
    } catch (e) {
      errorService.handleError(e.response);
    }
  },

  async resendCounterReading({ rootState }, params) {
    try {
      await clientServices.resendCounterReading(params);
      await router.push(`${rootState.isMobile ? '/m/': '/'}payment`);
      errorService.onSuccess('Показание счетчика успешно отправлено!');
    } catch (e) {
      errorService.handleError(e.response);
    }
  },

  async getPaymentHistory({ commit }, params) {
    try {
      const { data } = await clientServices.getPaymentHistory({ params });
      commit('SET_PAYMENT_HISTORY', data);
    } catch (e) {
      errorService.handleError(e);
    }
  },

  async getPayedMonths({ commit }) {
    try {
      const { data } = await clientServices.getPayedMonths();
      // // TODO: remove when api fixed
      // const currentDay = new Date().getDate();
      // if (currentDay < 20) data.pop();
      commit('SET_PAYED_MOTHS', data);
    } catch (e) {
      errorService.handleError(e);
    }
  },

  async getVariables({ commit }) {
    try {
      const { data } = await clientServices.getVariables();
      commit('SET_TOTAL_SUM_LIMIT', data.total_sum_limit);
    } catch (e) {
      errorService.handleError(e);
    }
  },

  async signRent(_, params) {
    try {
      await clientServices.signRent(params);
      await router.push('/rents');
      errorService.onSuccess('Показание аренды успешно подписано!');
    } catch (e) {
      errorService.handleError(e.response);
    }
  },
  async getSignedRents({ commit }) {
    try {
      const { data } = await clientServices.getSignedRents();
      const array = data.map((item) => {
        return {...item, ...{ ecp: true }};
      });
      commit("SET_SIGNED_RENTS", array);
    } catch (e) {
      errorService.handleError(e.response);
    }
  },
  async getRentsItem({ commit }, id) {
    try {
      const { data } = await commonRoleServices.getRent(id);
      commit("SET_RENTS_ITEM", data);
    } catch (e) {
      errorService.handleError(e.response)
    }
  },
};

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions,
};
