<template>
  <section class="du-form__wrap">
    <du-header v-if="client_details" />
    <div class="oda-card">
      <form class="du-form" @submit.prevent>
        <div class="du-form__header">
          {{ formTitle }}
        </div>

        <b-row
            v-for="(input, i) in filteredInputs"
            :key="`form-input__${i}`"
        >
          <b-col cols="12">
            <div class="du-form__input-header">
              {{ ++i }}
              {{ input.title }}
              <span v-if="input.description">
                {{ input.description }}
              </span>
            </div>
          </b-col>

          <b-col
              v-if="form[input.name] && form[input.name][0]"
              cols="12"
              class="mb-3"
          >
            <b-row class="align-items-center">
              <div
                  v-for="uploaded in form[input.name]"
                  :key="uploaded.id"
              >
                <div class="oda-card du-form__image-card">
                  <embed
                      v-if="uploaded.ext === '.pdf'"
                      :src="uploaded.url"
                      width="100%"
                      height="100%"
                      @click="openPhoto(uploaded.url)"
                  >
                  <img
                      v-else
                      :src="uploaded.url"
                      :alt="`uploaded-${uploaded.id}`"
                  >
                  <button
                      :disabled="loading"
                      class="du-form__image-card-delete oda-button primary feather icon-x"
                      @click="deletePhoto(uploaded.id, input.name)"
                  >
                  </button>
                  <button
                      :disabled="loading"
                      class="du-form__image-card-zoom oda-button primary feather icon-search"
                      @click="openPhoto(uploaded.url)"
                  >
                  </button>
                </div>
              </div>
              <div
                  :class="{ loading }"
                  class="du-form__image-add feather icon-plus"
                  @click="addMore(input.name)"
              ></div>
            </b-row>
          </b-col>
          <b-col
              v-if="!form[input.name][0]"
              cols="12"
              md="10"
          >
            <div class="du-form__input">
              <oda-form-group
                  :ref="`file-upload-${input.name}`"
                  :disabled="loading"
                  placeholder="Прикрепить файл"
                  class="file-ecp"
                  file
                  accept="image/*, .pdf"
                  @input="file => sendFiles(file, input.name)"
              />
            </div>
          </b-col>

          <b-col cols="12">
            <div class="du-form__add-button-empty"></div>
          </b-col>
        </b-row>
      </form>
    </div>
    <div class="du-form__buttons">
      <router-link to="/du" class="oda-button primary bordered">
        <span class="feather icon-chevron-left mr-2"></span>
        Заново ввести ИИН / БИН
      </router-link>
      <router-link
          to="/du/success"
          class="oda-button primary"
      >
        Отправить
      </router-link>
    </div>
    <input
        style="display: none"
        type="file"
        ref="add-more-input"
        @input="addMoreSendFile"
    >
  </section>
</template>

<script>
import DuHeader from "./header";
import OdaFormGroup from "../../../components/OdaFormGroup";
import { mapState } from "vuex";
import imageCompression from "browser-image-compression";

export default {
  name: "DocumentsUpdateForm",
  components: {
    DuHeader,
    OdaFormGroup,
  },

  data() {
    return {
      addMoreInput: '',
      form: {},
      type: '',
      formTitle: '',
      loading: false,
      types: [
        {
          name: 'phys',
          description: 'Для Физических лиц',
          items: ['ИП', 'КХ', 'Физ.лицо', 'физ лицо'],
        },
        {
          name: 'legal',
          description: 'Для  Юридических лиц',
          items: ['ПТ', 'АО', 'КГУ', 'НАО', 'ОО', 'ПК', 'РГП', 'ТОО', 'Учреждение'],
        },
        {
          name: 'ksk',
          description: 'Для КСК/ОСИ',
          items: ['Кондоминиум', 'КСК', 'КСП', 'ОСИ'],
        },
      ],
      inputs: [
        {
          name: 'passport',
          title: 'Удостоверение личности',
          description: 'в хорошем качестве, с двух сторон',
          canIncrease: true,
          size: 5,
          amount: 2,
          type: ['phys', 'legal', 'ksk'],
        },
        {
          name: 'residents_meeting_minutes',
          title: 'Протокол собрания жильцов',
          description: 'в хорошем качестве, с двух сторон',
          canIncrease: true,
          size: 10,
          amount: 1,
          type: ['ksk',],
        },
        {
          name: 'gov_act',
          title: 'Гос Акт',
          description: 'в хорошем качестве, с двух сторон',
          canIncrease: true,
          size: 5,
          amount: 2,
          type: ['phys', 'legal'],
        },
        {
          name: 'tech_passport',
          title: 'Техплан/техпаспорт объекта',
          description: 'в хорошем качестве',
          canIncrease: true,
          size: 10,
          amount: 1,
          type: ['ksk',],
        },
        {
          name: 'contract_of_sale',
          title: 'Договор купли продажи либо другой документ на основании которого выдан Госакт',
          description: '',
          canIncrease: true,
          size: 10,
          amount: 1,
          type: ['phys', 'legal'],
        },
        {
          name: 'help_encumbrances',
          title: 'Справка Обременения',
          description: 'свежая, должна быть не более месячной давности, при условии, что месяц должен быть текущий (в ноябре за ноябрь, в декабре за декабрь итд.).',
          canIncrease: true,
          size: 10,
          amount: 1,
          type: ['phys', 'legal'],
        },
        {
          name: 'director_say_so',
          title: 'Приказ на директора',
          description: '',
          canIncrease: true,
          size: 10,
          amount: 1,
          type: ['legal'],
        },
        {
          name: 'state_registration_certificate',
          title: 'Свидетельство о гос. Регистрации',
          description: '',
          canIncrease: true,
          size: 10,
          amount: 1,
          type: ['legal'],
        },
        {
          name: 'lease_contract',
          title: 'Договор аренды',
          description: 'свежую или ДС на продление',
          canIncrease: true,
          size: 10,
          amount: 1,
          type: ['legal', 'ksk'],
        },
      ],
    }
  },
  async mounted() {
    if (!this.client_details)
      await this.$store.dispatch('documentsUpload/authIin', this.iin);

    const { type: client_type } = this.client_details || {};
    const type = this.types.filter(x => x.items.includes(client_type));
    this.type = type[0] && type[0].name;
    this.formTitle = type[0] && type[0].description;

    const filtered = this.inputs.filter(x => x.type.includes(this.type));
    filtered.forEach(f => {
      if (this.client_details[f.name] && this.client_details[f.name].length) {
        this.form[f.name] = this.client_details[f.name];
        return;
      }
      const value = [];
      for (let i = 0; i < f.amount; i++) {
        value.push(null);
      }
      this.form[f.name] = value;
    });
  },

  computed: {
    ...mapState({
      client_details: state => state.documentsUpload.client_details,
      iin: state => state.documentsUpload.iin,
    }),
    filteredInputs() {
      return this.inputs.filter(input => input.type.includes(this.type));
    },
  },

  methods: {
    approveForm() {
      this.$router.push()
      this.$toast.open({
        type: "success",
        message: "Успешно отправлено на рассмотрение",
      })
    },
    addMore(ref) {
      if (this.loading) return;
      this.addMoreInput = ref;
      this.$refs['add-more-input'].click();
    },
    async addMoreSendFile(e) {
      const file = e.target.files[0];
      try {
        await this.sendFiles(file, this.addMoreInput);
        this.addMoreInput = '';
      } catch (e) {
        console.error(e);
      }
    },
    async sendFiles(file, apptype) {
      if (!file) return;
      const formData = new FormData();
      const compressed_item = await this.compress(file);
      formData.append(`applications`, compressed_item);
      formData.append('iin', this.iin);
      const payload = {
        type: apptype,
        formData,
      };

      try {
        this.loading = true;
        await this.$store.dispatch('documentsUpload/sendFiles', payload);
        this.$set(this.form, apptype, this.client_details[apptype]);
        this.reRenderForm();
        // this.clearInput(apptype);
      } catch (e) {
        console.error(e);
      } finally {
        this.loading = false;
      }
    },
    async compress(image) {
      try {
        const options = {
          maxSizeMB: 1,
          maxWidthOrHeight: 1920,
          useWebWorker: true
        };
        if (image.type === 'application/pdf') return image;
        const compressedFile = await imageCompression(image, options);
        console.log(
            "compressedFile instanceof Blob",
            compressedFile instanceof Blob
        ); // true
        // eslint-disable-next-line
        console.log(`compressedFile size ${compressedFile.size / 1024 / 1024} MB`); // smaller than maxSizeMB
        return compressedFile;
      } catch (error) {
        console.log("Error while compressing");
        console.log(error);
        return image;
      }
    },
    openPhoto(url) {
      window.open(url);
    },

    async deletePhoto(id, apptype) {
      const params = {
        id,
        apptype,
        iin: this.iin,
      }
      try {
        this.loading = true;
        await this.$store.dispatch('documentsUpload/deatachFile', params);
        this.$set(this.form, apptype, this.client_details[apptype]);
        this.reRenderForm();
      } catch (e) {
        console.error(e);
      } finally {
        this.loading = false;
      }
    },

    reRenderForm() {
      const inputs = this.inputs;
      inputs.push({});
      inputs.pop();
      this.$set(this, 'inputs', inputs);
    },
    clearInput(apptype) {
      if (!this.$refs[`file-upload-${apptype}`]) return;
      this.$refs[`file-upload-${apptype}`][0].removeFile();
    }
  },
}
</script>
