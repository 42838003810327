<template>
  <b-row>
    <b-col cols="12" md="8">
      <payment-status
          :status="status"
          :date="date"
      />
    </b-col>
    <b-col cols="12" md="4">
      <payment-user :user="counterparty"/>
    </b-col>
  </b-row>
</template>

<script>
import PaymentStatus from "../../../components/PaymentItem/Status";
import PaymentUser from "../../../components/PaymentItem/User";

export default {
  name: "ItemHeader",
  components: {
    PaymentStatus,
    PaymentUser,
  },
  props: {
    status: String,
    date: String,
    counterparty: String,
  },
}
</script>
