<template>
  <section class="oda-section terms-of-use">
    <div class="oda-card">
      <div class="terms-of-use__head">
        <h1 class="terms-of-use__h1">
          Пользовательское соглашение
        </h1>
        <button
            class="oda-button primary"
            @click="acceptTermsOfUse"
        >
          Согласен
        </button>
      </div>
      <p class="terms-of-use__p">
        Настоящее Соглашение заключается между ТОО «Мобайл Телеком-Сервис» (далее — Владелец) и любым лицом,
        становящимся после принятия условий данного соглашения пользователем сайта, расположенного в сети Интернет по
        адресу: https://www.tele2.site(далее — Сайт), в дальнейшем именуемым «Пользователь», вместе по тексту
        Соглашения именуемые «Стороны», а по отдельности — «Сторона».
        В соответствии со статьей 395 Гражданского кодекса РК (ГК РК) настоящее Пользовательское соглашение признается
        офертой.
        В соответствии со статьей 396 Гражданского кодекса РК (ГК РК) безусловным принятием (акцептом) условий
        настоящего Соглашения считается факт регистрации на Сайте и создание Заявки на подключение к системе
        электронного документооборота.
        Настоящее Соглашение, заключаемое путем акцепта настоящей оферты, не требует двустороннего подписания и
        действительно в электронном виде.
      </p>
      <ol>
        <li>
          <h2>Предмет соглашения</h2>
          <ul type="none">
            <li>
              1.1 Владелец Сайта предоставляет Пользователю доступ к Электронной системе, при этом обязательным условием
              предоставления Владельцем Сайта такого доступа в соответствии с настоящим Соглашением является принятие,
              соблюдение Пользователем и применение к отношениям Сторон требований и положений, определенных настоящим
              Соглашением.
            </li>
            <li>
              1.2 Владелец Сайта оставляет за собой право изменять условия настоящего Соглашения и всех его неотъемлемых
              частей без согласования с Пользователем с уведомлением последнего посредством размещения на Сайте новой
              редакции Соглашения или какой-либо его неотъемлемой части, подвергшейся изменениям. Принимая во внимание,
              что информационная рассылка может быть признана Сторонами спамом, Пользователь обязуется не менее одного
              раза в месяц знакомиться с содержанием Соглашения, размещенного на Сайте, в целях своевременного
              ознакомления с его изменениями. Новая редакция Соглашения и/или какой-либо его неотъемлемой части
              вступает в силу с момента опубликования на Сайте, если иной срок вступления изменений в силу не определен
              Владельцем Сайта при их опубликовании. Действующая редакция Соглашения и всех приложений к нему всегда
              находится на Сайте в разделе «Пользовательское соглашение»
            </li>
          </ul>
        </li>
        <li>
          <h2>Права и обязанности Владельца Сайта</h2>
          <ul type="none">
            <li>
              2.1 Владелец Сайта имеет право осуществлять трансграничную передачу и обработку данных (п.п.1 п.3 статьи 16
              Закона РК «О персональных данных и их защите» от 21 мая 2013 года №94-V)
            </li>
          </ul>
        </li>
        <li>
          <h2>Права и обязанности Пользователя</h2>
          <ul type="none">
            <li>
              <h3>3.1 Пользователь обязуется:</h3>
              <ul type="none">
                <li>
                  3.1.1 Полностью ознакомиться с условиями настоящего Соглашения до момента до момента входа на Сайт
                  заключения договора, в соответствие с которым предоставляется доступ к Электронной системе.
                </li>
                <li>
                  3.1.2 Соблюдать все условия настоящего Соглашения.
                </li>
                <li>
                  3.1.3 Не передавать свою учетную информацию третьим лицам.
                </li>
                <li>
                  3.1.4 Не пытаться получить доступ к логину и паролю другого Пользователя, в том числе включая, но не
                  ограничиваясь, обман, взлом профилей других Пользователей и прочее.
                </li>
                <li>
                  3.1.5 Самостоятельно контролировать прохождение электронным документом всех этапов электронного
                  документооборота со своим куратором/представителем Владельца в случае, если этапы не пройдены,
                  самостоятельно передать отчетность и/или иные документы, вплоть до передачи в бумажном виде.
                </li>
              </ul>
            </li>
            <li>
              <h3>3.2 Пользователь имеет право:</h3>
              <ul type="none">
                <li>
                  3.2.2 Круглосуточно получать доступ к серверу, на котором расположен Сайт, за исключением времени
                  проведения профилактических работ.
                </li>
              </ul>
            </li>
          </ul>
        </li>
        <li>
          <h2>Регистрация Пользователя</h2>
          <ul type="none">
            <li>
              4.1 Регистрация пользователя осуществляется представителем Владельца посредством запроса у Пользователя
              электронной почты и далее выдачей логина и пароля. Риск утечки и перехвата информации несет Пользователь.
            </li>
          </ul>
        </li>
        <li>
          <h2>Ответственность Сторон</h2>
          <ul type="none">
            <li>
              5.1 Пользователь самостоятельно определяет перечень организационных и программных (для ЭВМ) средств для
              сохранения в тайне своей учетной информации и обеспечения санкционированного доступа к ней. Владелец
              Сайта не несет ответственность за убытки, причиненные Пользователю в результате разглашения третьим лицам
              учетной информации Пользователя, произошедшего не по вине Владельца Сайта. Если любое лицо, помимо
              Пользователя, авторизуется на Сайте, используя учетную информацию Пользователя, то все действия,
              совершенные таким лицом, будут считаться совершенными этим Пользователем. Пользователь самостоятельно
              несет ответственность за все действия, совершенные им на Сайте, а также за все действия, совершенные на
              Сайте любыми иными лицами с использованием Учетной информации Пользователя.
            </li>
            <li>
              5.2 Владелец Сайта не гарантирует, что программное обеспечение Cайта не содержит ошибок и/или компьютерных
              вирусов или посторонних фрагментов кода. Владелец Сайта предоставляет возможность Пользователю
              пользоваться программным обеспечением Сайта «как оно есть», без каких-либо гарантий со стороны
              Владельца Сайта.
            </li>
            <li>
              5.3 Владелец Сайта не несет ответственности за убытки, причиненные Пользователю в результате сообщения другим
              Пользователем недостоверной информации, а также причиненные действиями (бездействием) другого
              Пользователя. Владелец Сайта не гарантирует, что информация, содержащаяся в Профилях Пользователей,
              является достоверной и полной.
            </li>
            <li>
              5.4 Владелец Сайта прилагает все возможные усилия для обеспечения нормальной работоспособности Сайта, однако
              не несет ответственности за неисполнение или ненадлежащее исполнение обязательств по Соглашению, а также
              возможные убытки, возникшие в том числе, но не ограничиваясь, в результате:
              <ul type="none">
                <li>
                  5.4.1 неправомерных действий Пользователей, направленных на нарушения информационной безопасности или
                  нормального функционирования Сайта;
                </li>
                <li>
                  5.4.2 сбоев в работе Сайта, вызванных ошибками в коде, компьютерными вирусами и иными посторонними
                  фрагментами кода в программном обеспечении Сайта;
                </li>
                <li>
                  5.4.3 отсутствия (невозможности установления, прекращения и пр.) интернет-соединений между сервером
                  Пользователя и сервером Сайта;
                </li>
                <li>
                  5.4.4 проведения государственными и муниципальными органами, а также иными организациями мероприятий в
                  рамках Системы оперативно-разыскных мероприятий;
                </li>
                <li>
                  5.4.5 установления государственного регулирования (или регулирования иными организациями) хозяйственной
                  деятельности коммерческих организаций в сети Интернет и/или установления указанными субъектами
                  разовых ограничений, затрудняющих или делающих невозможным исполнение Соглашения;
                </li>
                <li>
                  5.4.6 других случаев, связанных с действиями (бездействием) Пользователей и/или других субъектов,
                  направленными на ухудшение общей ситуации с использованием сети Интернет и/или компьютерного
                  оборудования, существовавшей на момент заключения Соглашения, а также любых других действий,
                  направленных на Сайт и на третьих лиц.
                </li>
              </ul>
            </li>
            <li>
              5.5 Владелец Сайта имеет право производить профилактические работы в программно-аппаратном комплексе Сайта с
              временным приостановлением работы Сайта по возможности в ночное время и максимально сокращая время
              неработоспособности Сайта, уведомляя об этом Пользователя, если технически это представляется возможным.
            </li>
            <li>
              5.6 В случае наступления форс-мажорных обстоятельств, а также аварий или сбоев в программно-аппаратных
              комплексах третьих лиц, сотрудничающих с Владельцем Сайта, или действий (бездействия) третьих лиц,
              направленных на приостановку или прекращение функционирования Сайта, возможна приостановка работы Сайта
              без предварительного уведомления Пользователя.
            </li>
            <li>
              5.7 Ни одна из Сторон не несет ответственности за полное или частичное неисполнение любой из своих
              обязанностей, если неисполнение является следствием таких обстоятельств, как наводнение, пожар,
              землетрясение, другие стихийные бедствия, война или военные действия и другие обстоятельства
              непреодолимой силы, возникшие после заключения Соглашения и не зависящие от воли Сторон.
            </li>
          </ul>
        </li>
        <li>
          <h2>Порядок разрешения споров и урегулирования претензий</h2>
          <ul type="none">
            <li>
              6.1 В случае возникновения споров между Пользователем и Владельцем Сайта по вопросам, связанным с исполнением
              Соглашения, Стороны примут все меры к разрешению их путем переговоров между собой. Претензионный порядок
              разрешения споров обязателен. Претензии Пользователей принимаются и рассматриваются Владельцем Сайта
              только в письменном виде и в порядке, предусмотренном настоящим Соглашением и действующим
              законодательством РК.
            </li>
            <li>
              6.2 Для разрешения споров, возникших между Пользователем и Владельцем Сайта применяется следующий
              претензионный порядок:
              <ul type="none">
                <li>
                  6.2.1 Пользователь, считающий, что его права нарушены из-за действий Владельца Сайта, направляет последнему
                  претензию, содержащую суть предъявляемого требования, обоснование его предъявления, а также все данные
                  Пользователя. Претензия также направляется Владельцу Сайта в письменном виде посредством отправки по
                  почте или по факсу;
                </li>
                <li>
                  6.2.2 в течение 30 (тридцати) рабочих дней со дня получения претензии Владелец Сайта обязан изложить свою
                  позицию по указанным в ней принципиальным вопросам и направить свой ответ по адресу электронной почты
                  или почтовому адресу, указанному в претензии Пользователя;
                </li>
                <li>
                  6.2.3 в случае недостижения разрешения спора путем претензионного порядка спор подлежит рассмотрению в
                  соответствии с пунктом 6.4 Соглашения;
                </li>
                <li>
                  6.2.4 Владельцем Сайта не рассматриваются анонимные претензии или претензии, не позволяющие
                  идентифицировать Пользователя на основе предоставленных им при регистрации данных, или претензии, не
                  содержащие данных, указанных в настоящем пункте настоящего Соглашения.
                </li>
              </ul>
            </li>
            <li>
              6.3 Для решения технических вопросов при определении вины Пользователя в результате его неправомерных
              действий при пользовании сетью Интернет и Сайтом, в частности, Владелец Сайта вправе самостоятельно
              привлекать компетентные организации в качестве экспертов. В случае установления вины Пользователя
              последний обязан возместить затраты на проведение экспертизы.
            </li>
            <li>
              6.4 При недостижении согласия между Сторонами путем переговоров спор, вытекающий из настоящего Соглашения,
              подлежит рассмотрению в суде общей юрисдикции по месту нахождения Владельца Сайта.
            </li>
          </ul>
        </li>
        <li>
          <h2>Прочие условия</h2>
          <ul type="none">
            <li>
              7.1 Настоящее Соглашение вступает в силу с момента акцепта настоящей оферты Пользователем и заключается на
              срок до момента расторжения Договора Аренды.
            </li>
            <li>
              7.2 Положения настоящего Соглашения устанавливаются, изменяются и отменяются Владельцем Сайта в одностороннем
              порядке без предварительного уведомления. С момента размещения на Сайте новой редакции Соглашения
              предыдущая редакция считается утратившей свою силу. В случае существенного изменения положений настоящего
              Соглашения Владелец Сайта извещает об этом Пользователей путем размещения на Сайте соответствующего
              сообщения.
            </li>
            <li>
              7.3 Пользователь по настоящему Соглашению, гарантирует и обязуется соблюдать законодательство Республики
              Казахстан по вопросам противодействия коррупции, а также информировать Владельца посредством «горячей
              линии» о любых предполагаемых и фактических нарушениях законодательства Республики Казахстан по вопросам
              противодействия коррупции.
            </li>
            <li>
              7.4 Владелец в праве незамедлительно расторгнуть Соглашение в одностороннем порядке в случае выявления
              коррупционных рисков, а также в силу нецелесообразности дальнейшего выполнения Соглашения.
            </li>
            <li>
              7.5 Если Пользователь не согласен с условиями настоящего Соглашения, то он должен уведомить об этом
              представителя Владельца, в противном случае продолжение использования Пользователем Сайта означает,
              что Пользователь согласен с условиями Соглашения.
            </li>
            <li>
              7.6 Вопросы, не урегулированные настоящим Соглашением, подлежат разрешению в соответствии с законодательством
              РК.
            </li>
          </ul>
        </li>
      </ol>

      <div class="terms-of-use__foot">
        <button
            class="oda-button primary"
            @click="acceptTermsOfUse"
        >
          Согласен
        </button>
      </div>
    </div>
  </section>
</template>

<script>
import { mapActions } from "vuex";

export default {
  name: "TermsOfUse",
  methods: {
    ...mapActions('auth', [
      'acceptTermsOfUse',
    ]),
  },
}
</script>
