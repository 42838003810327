<template>
  <div class="oda-section plug">
    <b-row class="justify-content-center align-items-center h-100">
      <b-col cols="12" md="6" class="plug__body">
        <h2 class="plug__title">Технические работы</h2>
        <div class="plug__text">
          Уважаемые Арендодатели, приносим извинения за технические неполадки. Мы работаем над тем, чтобы как можно скорее устранить проблемы на сайте.
          <br><br>
          Просим запланировать ваши действия на 14.10.2022, так как решение может занять более 12 часов.
          <br><br>
          Заранее благодарим вас за терпение.
          <br><br>
          С уважением, команда разработки tele2.site
        </div>
        <div class="plug__bg-image">
          <img src="@/assets/images/plug.svg" alt="plug">
        </div>
      </b-col>
    </b-row>
  </div>
</template>

<script>
export default {
  name: "Plug"
}
</script>
