<template>
  <b-row>
    <b-col cols="12">
      <b-tabs class="oda-tabs">
        <b-tab
            v-if="task && task.awp"
            title="ЭАВР"
        >
          <payment-form :form="getForm('awp')"/>
          <div
              v-for="(service, i) in servicesForm"
              :key="i"
          >
            <payment-form
                :form="service.form"
                :title="service.name"
            >
              <template v-slot:formGroup v-if="task.status !== 'В процессе'">
                  <b-col cols="12" md="3" class="required">
                    <div v-if="period[service.id]">
                      <oda-form-group
                          :label="period[service.id].severalMonths ? 'Период с': 'Период'"
                          empty-form
                          required
                      >
                        <template v-slot:input>
                          <multiselect
                              v-model="period[service.id].start_dt"
                              :options="cp_months"
                              :show-labels="false"
                              :multiple="false"
                              :clear-on-select="false"
                              :preselect-first="false"
                              placeholder="Выберите период"
                              label="label"
                              class="oda-form-multiselect"
                              @input="x => periodChanged(service.id, 'start_dt', x)"
                          ></multiselect>
                        </template>
                      </oda-form-group>
                    </div>
                  </b-col>

                  <b-col
                      v-if="period[service.id] && period[service.id].severalMonths"
                      cols="12"
                      md="3"
                      class="required"
                  >
                    <oda-form-group
                        label="Период до"
                        empty-form
                        required
                    >
                      <template v-slot:input>
                        <multiselect
                            v-model="period[service.id].end_dt"
                            :options="cp_months"
                            :show-labels="false"
                            :multiple="false"
                            :clear-on-select="false"
                            :preselect-first="false"
                            track-by="value"
                            placeholder="Выберите период"
                            label="label"
                            class="oda-form-multiselect"
                            @input="periodChanged()"
                        ></multiselect>
                      </template>
                    </oda-form-group>
                  </b-col>

                  <b-col v-if="period[service.id]" class="align-items-end" style="display: flex">
                    <oda-form-group
                        v-model="period[service.id].severalMonths"
                        placeholder="Несколько месяцев"
                        checkbox
                        @input="x => severalMonthChanged(x, service.id)"
                    />
                  </b-col>
              </template>
            </payment-form>
          </div>
        </b-tab>
        <b-tab
            v-if="task && task.esf"
            title="ЭCФ"
            lazy
        >
          <payment-form :form="getForm('esf')"/>
          <payment-form
              v-for="product in productsForm"
              :form="product.form"
              :key="product.name"
              :title="product.name"
          />
        </b-tab>
      </b-tabs>
    </b-col>
  </b-row>
</template>


<script>
import PaymentForm from "@/components/PaymentItem/Form";
import OdaFormGroup from "@/components/OdaFormGroup";
import Multiselect from 'vue-multiselect';
import {mapState} from "vuex";

export default {
  name: "ItemInfo",
  components: {
    PaymentForm,
    OdaFormGroup,
    Multiselect,
  },

  data() {
    return {
      period: {},
    }
  },

  watch: {
    task(value) {
      const services = value.awp.state['Услуги'];
      services.forEach(service => {
        this.$set(this.period, service.id, {start_dt: null, end_dt: null, severalMonths: false});
      })
    }
  },

  computed: {
    ...mapState({
      task: state => state.isesf.isesf_task,
      bank_acc: state => state.isesf.isesf_task_bank_acc,
      payedMonths: state => state.isesf.isesfPayedMonths,
    }),
    servicesForm() {
      if (!(this.task.awp.state || {})['Услуги']) return [];
      const services = this.task.awp.state['Услуги'];
      const form = [];
      services.forEach(service => {
        const items = [
          {
            name: 'count',
            value: service['Количество'],
            label: 'Количество',
            size: 4,
            bgColor: service['Количество'] ? '' : 'danger',
          },
          {
            name: 'price',
            value: this.prettyPrice(service['Цена']),
            label: 'Цена',
            size: 4,
          },
          {
            name: 'current_payment_amount',
            value: this.prettyPrice(service['СтоимостьСУчетомКосвенныхНалогов']),
            label: 'Итого стоимость с учетом косвенных налогов',
            size: 4,
          },
          {
            name: 'nds_sum',
            value: service['СуммаНДС'],
            label: 'Сумма НДС',
            size: 4,
          },
          {
            name: 'nds_value',
            value: service['СтавкаНДС'],
            label: 'Ставка НДС',
            size: 4,
          },
          {
            name: 'total_sum',
            value: this.prettyPrice(service['РазмерОборота']),
            label: 'Размер оборота',
            size: 4,
          },
        ];
        form.push({ form: items, name: service['УслугаНаименование'], id: service.id })
      })
      return form;
    },
    productsForm() {
      if (!(this.task.esf.state || {})['Товары']) return [];
      const services = this.task.esf.state['Товары'];
      const form = [];
      services.forEach(service => {
        const items = [
          {
            name: 'count',
            value: service['Количество'],
            label: 'Количество',
            size: 4,
          },
          {
            name: 'price',
            value: service['Цена'],
            label: 'Цена',
            size: 4,
          },
          {
            name: 'current_payment_amount',
            value: service['Сумма'],
            label: 'Сумма',
            size: 4,
          },
          {
            name: 'nds_sum',
            value: service['СуммаНДС'],
            label: 'Сумма НДС',
            size: 4,
          },
          {
            name: 'nds_value',
            value: service['СтавкаНДС'],
            label: 'Ставка НДС',
            size: 4,
          },
          {
            name: 'total_sum',
            value: service['ОборотПоРеализации'],
            label: 'Оборот по реализации',
            size: 4,
          },
        ];
        form.push({ form: items, name: service['ТоварНаименование'] })
      })
      return form;
    },
    cp_months() {
      return this.payedMonths.map((m) => ({
        label: `${m.name} ${m.year}`,
        value: `${m.start_date}/${m.end_date}`,
        $isDisabled: m.hasSentCounterReadings,
        key: 0,
      }));
    },
  },

  mounted() {
    this.$nextTick(() => {
      const services = this.task.awp.state['Услуги'];
      services.forEach(service => {
        console.log(service);
        this.$set(this.period, service.id, {start_dt: null, end_dt: null, severalMonths: false});
      })
    })
  },
  methods: {
    severalMonthChanged(value, id) {
      if (!value) this.$set(this.period[id], 'end_dt', null);
      this.$set(this.period[id], 'severalMonths', value);
    },
    periodChanged() {
      const newPeriod = {};
      Object.entries(this.period).forEach(item => {
        const start_dt = item[1].start_dt && item[1].start_dt.value.split("/")[0];
        const end_dt = item[1].end_dt ? item[1].end_dt.value.split("/")[1] : item[1].start_dt && item[1].start_dt.value.split("/")[1];
        newPeriod[item[0]] = {
          start_dt,
          end_dt,
        }
      })
      this.$store.commit('isesf/SET_PERIOD', newPeriod);
    },
    getForm(type = 'awp') {
      const { state, counterparty, bts } = this.task[type];
      if (!state) return [];
      return [
        {
          name: 'counterparty',
          value: counterparty,
          size: 8,
          label: 'Контрагент',
        },
        {
          name: 'bts',
          value: bts,
          size: 4,
          label: 'Site ID',
        },
        {
          name: 'sf_number',
          value: state['Номер'],
          label: `Входящий номер ${type === 'awp' ? 'ЭАВР' : 'ЭСФ' }`,
          size: 4,
        },
        {
          name: 'income_date',
          value: state['ДатаПодписиОтправителя'] || state['ДатаОборота'],
          label: 'Входящая дата',
          size: 4,
        },
        {
          name: 'bin',
          value: this.task[type].bin,
          label: 'БИН',
          size: 4,
        },
        {
          name: 'iik',
          value: this.task[type].state['Поставщики'][0]['ИИК'],
          label: 'Расчетный счет',
          size: 4,
          bgColor: this.bank_acc ? this.bank_acc.includes(this.task[type].state['Поставщики'][0]['ИИК']) ? 'success': 'danger' :'warning',
        },
      ]
    },
  },
}
</script>
