<template>
  <b-row>
    <clients-count-chart />

    <users-involvement-chart />

    <rents-sum-chart />

    <rents-count-chart />

    <electricity-sum-chart />

    <electricity-count-chart />
  </b-row>
</template>

<script>
import ClientsCountChart from "./ClientsCountChart";
import UsersInvolvementChart from "./UsersInvolvementChart";
import ElectricitySumChart from "./ElectricitySumChart";
import ElectricityCountChart from "./ElectricityCountChart";
import RentsSumChart from "./RentsSumChart";
import RentsCountChart from "./RentsCountChart";
import {mapActions} from "vuex";

export default {
  name: "Dashboard",
  components: {
    ClientsCountChart,
    UsersInvolvementChart,
    ElectricitySumChart,
    RentsSumChart,
    RentsCountChart,
    ElectricityCountChart,
  },
  data() {
    return {}
  },

  computed: {},

  async created() {
    if (!this.rents_analytics || !this.rents_analytics.count) await this.getRentsAnalytics();
    if (!this.electricities_analytics || !this.electricities_analytics.count) await this.getElectricitiesAnalytics();
  },
  methods: {
    ...mapActions('dashboard', [
      'getElectricitiesAnalytics',
      'getRentsAnalytics',
    ]),
  },
}
</script>
