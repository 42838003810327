<template>
  <section class="oda-section not-found">
    <div class="not-found__content">
      <div class="not-found__title">Страница не найдена ⚠️</div>
      <div class="not-found__description">️Похоже такой страницы не существует</div>
      <router-link to="/" class="oda-button primary">На главную</router-link>
    </div>
  </section>
</template>

<script>
export default {
  name: "NotFound"
}
</script>
