<template>
  <section
      v-if="contract"
      class="oda-section treaties-item"
  >
    <section-header
        :section="`БТС: ${contract.bts}`"
        back-link="/new-contracts"
    />

    <id-head :contract="contract"/>

    <id-docs
        :additional_docs="contract.additional_docs"
        :document="contract.document"
        :approval_sheet="contract.approval_sheet"
        :ciptracker_id="contract.ciptracker_id"
        :disabled="actionsDisabled"
        :treaty="contract.treaty"
    />

    <b-row class="mb-3">
      <b-col cols="6">
        <div class="oda-card new-treaties__docs">
          <h3 class="new-treaties__docs-header">История изменении:</h3>
          <ul v-if="contract && contract.document_history">
            <li
                v-for="item in contract.document_history"
                :key="item.name"
            >
              <b>{{ getHoursNMinutes(item.date) }}, {{ getDateFormat(item.date) }}</b>
              {{ item.text }}
            </li>
          </ul>
        </div>
      </b-col>
      <b-col cols="6">
        <div class="oda-card new-treaties__docs">
          <h3 class="new-treaties__docs-header">TSSR:</h3>
          <ul v-if="tssrFiles && tssrFiles.length">
            <li
                v-for="item in tssrFiles"
                :key="item.name"
            >
              <a :href="item.link" target="_blank">{{ item.name }}</a>
            </li>
          </ul>

          <h3 class="new-treaties__docs-header">SR:</h3>
          <ul v-if="srFiles && srFiles.length">
            <li
                v-for="item in srFiles"
                :key="item.name"
            >
              <a :href="item.link" target="_blank">{{ item.name }}</a>
            </li>
          </ul>

        </div>
      </b-col>
    </b-row>

    <b-row>
      <b-col cols="6">
        <oda-form-group
            :value="contract.subcon_comment"
            label="Комментарий от ГПО"
            textarea
            disabled
        />
      </b-col>

      <b-col cols="6">
        <oda-form-group
            v-model="rent_sum"
            :disabled="contract.status !== 'Просмотрено' && contract.status !== 'Новые задачи'"
            label="Сумма аренды"
            integer
            number
        />
      </b-col>

      <b-col cols="12">
        <div class="oda-card new-treaties-item__comment">
          <oda-form-group
              v-model="comment"
              :disabled="contract.status === 'Завершено'"
              label="Комментарий от меня"
              textarea
              required
          />
          <button
              :disabled="loading || contract.status === 'Завершено'"
              class="oda-button primary"
              @click="addComment"
          >
            Добавить комментарий
          </button>
        </div>
      </b-col>
      <b-col cols="12">
        <div class="oda-card new-treaties-item__footer">
          <button
              :disabled="actionsDisabled"
              class="oda-button danger"
              @click="showModal('decline')"
          >
            Отправить на доработку
          </button>
          <button
              :disabled="actionsDisabled"
              class="oda-button success"
              @click="showModal('approve')"
          >
            Отправить на рассмотрение
          </button>
        </div>
      </b-col>
    </b-row>

    <!--    ecp modal-->
    <b-modal
        ref="approve-modal"
        :no-close-on-backdrop="loading"
        hide-header
        hide-footer
    >
      <b-form @submit.prevent="submit">
        <oda-form-group
            v-model="decline_message"
            label="Комментарий"
            placeholder="Комментарий"
            required
            textarea
        />
        <button
            :class="actionType === 'approve' ? 'success' : 'danger'"
            :disabled="loading"
            class="oda-button mt-4"
            type="submit"
        >
          Отправить
        </button>
      </b-form>
    </b-modal>
  </section>
</template>

<script>
import SectionHeader from "../../../components/SectionHeader";
import OdaFormGroup from "../../../components/OdaFormGroup";
import IdHead from "./IdHead";
import IdDocs from "./IdDocs";
import {mapActions, mapState} from "vuex";

export default {
  name: "NewContractsItem",
  components: {
    SectionHeader,
    IdHead,
    IdDocs,
    OdaFormGroup,
  },

  data() {
    return {
      loading: false,
      decline_message: '',
      comment: '',

      approvalList: null,
      rent_sum: null,

      actionType: null,
    }
  },

  computed: {
    ...mapState({
      contract: state => state.treaties.employee_treaties_item,
      tssr: state => state.treaties.tssr,
      sr: state => state.treaties.sr,
    }),
    actionsDisabled() {
      return this.loading || this.contract.status === 'Завершено' || this.contract.status === 'Отклонен сотрудником';
    },
    tssrFiles() {
      if (this.tssr && this.tssr.esigns) {
        return this.tssr.esigns.map(item => {
          return {
            name: item.file_name,
            link: `https://${process.env.NODE_ENV === 'development' ? 'dev-': ''}ciptracker.tele2.kz/api${item.doc_link}`
          };
        })
      }
      return [];
    },
    srFiles() {
      if (this.sr && this.sr.esigns) {
        return this.sr.esigns.map(item => {
          return {
            name: item.file_name,
            link: `https://${process.env.NODE_ENV === 'development' ? 'dev-': ''}ciptracker.tele2.kz/api${item.doc_link}`
          };
        })
      }
      return [];
    },
    qq() {
      return process.env.CIP_TRACKER_URL
    }
  },

  async mounted() {
    const { id } = this.$route.params;
    await this.getNewTreatiesItem(id);

    if (this.contract && this.contract.sum)
      this.rent_sum = this.contract.sum;
    this.getSiteInfo(this.contract.ciptracker_id);
  },

  methods: {
    ...mapActions('treaties', [
        'getNewTreatiesItem',
        'declineNewTreatiesItem',
        'approveNewTreatiesItem',
        'addCommentNewTreatiesItem',
        'getSiteInfo',
    ]),
    showModal(type) {
      this.actionType = type;
      this.$refs['approve-modal'].show();
    },

    async addComment() {
      const params = {
        ciptracker_id: this.contract.ciptracker_id,
        comment: this.comment,
      };
      await this.addCommentNewTreatiesItem(params);
      this.comment = "";
    },

    async submit() {
      this.loading = true;
      const params = {
        ciptracker_id: this.contract.ciptracker_id,
        employee_message: this.decline_message,
      }
      if (this.actionType === 'approve') params.sum = this.rent_sum;

      try {
        if (this.actionType === 'approve') await this.approveNewTreatiesItem(params);
        else if (this.actionType === 'decline') await this.declineNewTreatiesItem(params);
        this.decline_message = '';
        this.actionType = null;
      } finally {
        this.loading = false;
      }
    },
  },
}
</script>
