import axios from "axios";

const state = {
    client_details: null,
    iin: localStorage.getItem('iin'),

    //employee data
    doc_submissions: [],
    doc_submissions_total: 0,
    doc_submission: null,
};

const mutations = {
    SET_IIN(state, payload) {
        localStorage.setItem('iin', payload);
        state.iin = payload;
    },
    SET_CLIENT_DETAILS(state, payload) {
        state.client_details = payload;
    },
    SET_DOC_SUBMISSIONS(state, payload) {
        state.doc_submissions = payload;
    },
    SET_DOC_SUBMISSIONS_TOTAL(state, payload) {
        state.doc_submissions_total = payload;
    },
    SET_DOC_SUBMISSION(state, payload) {
        state.doc_submission = payload;
    },
};

const actions = {
    async authIin({ commit }, iin) {
        localStorage.clear(); //removing prev iin & employee jwt (if exists) from localstorage
        commit("SET_IIN", iin);
        const params = {
            iin
        };
        const { data } = await axios.get('mass-document-submissions/iin/details', { params });
        commit("SET_CLIENT_DETAILS", data);
    },
    async sendFiles({ commit }, { formData, type }) {
        const { data } = await axios.put(`https://dev.tele2.site/api/mass-document-submissions/upload/${type}`, formData);
        commit('SET_CLIENT_DETAILS', data);
        commit('SET_DOC_SUBMISSION', data);
    },

    async getDocSubmissions({ commit }, params) {
        const { data } = await axios.get('mass-document-submissions/', { params });
        commit('SET_DOC_SUBMISSIONS', data);
    },
    async getDocSubmissionsTotal({ commit }) {
        const { data } = await axios.get('mass-document-submissions/count');
        commit('SET_DOC_SUBMISSIONS_TOTAL', data);
    },
    async getDocSubmission({ commit }, id) {
        const { data } = await axios.get(`mass-document-submissions/${id}`);
        commit('SET_DOC_SUBMISSION', data);
    },
    async deatachFile({ commit },{ id, apptype, iin }) {
        const { data } = await axios.delete(`/mass-document-submissions/deattach/${iin}/${apptype}/${id}`);
        commit('SET_CLIENT_DETAILS', data);
        commit('SET_DOC_SUBMISSION', data);
    },
    async builderApprove({ commit }, params) {
        const { data } = await axios.post('mass-document-submissions/builder/approve', params);
        commit('SET_DOC_SUBMISSION', data);
    },
    async approveSubmission({ commit }, iin) {
        const { data } = await axios.post('mass-document-submissions/employee/approve', { iin });
        commit('SET_DOC_SUBMISSION', data);
    },
    async rollbackSubmissionEmployee({ commit }, iin) {
        const { data } = await axios.post('mass-document-submissions/employee/approve/rollback', { iin });
        commit('SET_DOC_SUBMISSION', data);
    },
    async rollbackSubmissionBuilder({ commit }, iin) {
        const { data } = await axios.post('mass-document-submissions/builder/approve/rollback', { iin });
        commit('SET_DOC_SUBMISSION', data);
    },
};

export default {
    namespaced: true,
    state,
    mutations,
    actions,
}