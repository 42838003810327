var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"tasks-desk"},_vm._l((_vm.lists),function(list){return _c('div',{key:("list-" + (list.name)),staticClass:"tasks-column"},[_c('div',{staticClass:"tasks-column__head"},[_c('div',{staticClass:"tasks-column__title"},[_vm._v(_vm._s(list.name))]),_c('div',{staticClass:"tasks-column__count"},[_vm._v(_vm._s(list.cards && list.cards.length))])]),_c('div',{staticClass:"tasks-column__list"},_vm._l((list.cards),function(card){return _c('div',{key:("card-" + (card.id)),staticClass:"oda-card tasks-card",class:{'tasks-card-stack': card.group.length > 1},on:{"click":function($event){return _vm.goToCard(card)}}},[_c('div',{staticClass:"tasks-card__head"},[_c('div',{staticClass:"oda-badge",class:_vm.getReadingStatusClass(card.reading_status)},[_vm._v(" "+_vm._s(_vm.getReadingStatus(card.reading_status))+" ")]),(card.label === 'В процесее' && card.payments && card.payments[0])?_c('div',{staticClass:"tasks-card__status"},[_c('span',{staticClass:"feather icon-check",class:{
                'active': card.payments[0].approved,
                'declined': card.payments[0].approved === false
              }}),_c('div',{staticClass:"tasks-card__status-item",class:{
                'active': card.payments[0].approved_by_manager,
                'declined': card.payments[0].approved_by_manager === false
              }}),_c('div',{staticClass:"tasks-card__status-item",class:{
                'active': card.payments[0].approved_by_accountant,
                'declined': card.payments[0].approved_by_accountant === false
              }}),_c('div',{staticClass:"tasks-card__status-item",class:{
                'active': card.payments[0].approved_by_treasury,
                'declined': card.payments[0].approved_by_treasury === false
              }})]):_vm._e()]),_c('div',{staticClass:"tasks-card__name"},[_c('span',{staticClass:"feather icon-briefcase"}),_vm._v(" "+_vm._s(card.counterparty)+" ")]),_c('div',{staticClass:"tasks-card__name"},[_c('span',{staticClass:"feather icon-calendar"}),_vm._v(" "+_vm._s(_vm.getGroupPaymentPeriod(card))+" ")]),_c('div',{staticClass:"tasks-card__foot"},[_c('div',{staticClass:"tasks-card__bts"},[_c('span',{staticClass:"feather icon-radio"}),_vm._v(" "+_vm._s(card.bts)+" ")]),_c('div',{staticClass:"tasks-card__date"},[_vm._v(_vm._s(_vm.getDateFormat(card.created_at)))])])])}),0)])}),0)}
var staticRenderFns = []

export { render, staticRenderFns }