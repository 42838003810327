<template>
  <b-sidebar
      v-model="visible"
      id="electricity-sidebar"
      width="952px"
      right
      shadow
      backdrop
      no-header
  >
    <div v-if="!loading" class="oda-sidebar">
      <section-header
          :breadcrumb="breadcrumb"
          section="Платежка"
          close-able
          hide-user
          @close="visible = false"
      />

      <b-tabs class="oda-tabs">
        <b-tab
            v-for="(item, i) in items"
            :key="`oda-tab-item-${i}`"
            :title="getTabTitle(i)"
            :active="i === 0"
        >
          <payment-pdf
              v-if="getPdfLinks(i).length"
              :links="genPdfLink(i)"
              :counter_photo="payment.counter_photo"
              toggleable
          />

          <payment-status
              :status="paymentGroup[i].reading_status"
              :date="paymentGroup[i].created_at"
          />

          <payment-form :form="item" />
        </b-tab>
      </b-tabs>
      <oda-table
          :fields="historyFields"
          :items="clientHistory"
          custom-pagination
      />
    </div>
    <div v-else>
      <div class="profile-tab__loading">
        <span></span>
      </div>
    </div>
  </b-sidebar>
</template>

<script>
import SectionHeader from "@/components/SectionHeader";
import PaymentStatus from "@/components/PaymentItem/Status";
import PaymentForm from "@/components/PaymentItem/Form";
import PaymentPdf from "@/components/PaymentItem/Pdf";
import {mapState} from "vuex";
import paymentPage from "@/mixins/paymentPage";
import OdaTable from "@/components/OdaTable";

export default {
  name: "sidebar",
  components: {
    SectionHeader,
    PaymentStatus,
    PaymentForm,
    PaymentPdf,
    OdaTable,
  },
  mixins: [paymentPage],
  data() {
    return {
      visible: false,
      breadcrumb: [
        {
          name: 'Электроэнергия',
        },
        {
          name: 'Платежка'
        }
      ],
      historyFields: [
        {
          key: 'period',
          label: 'Период оплаты',
          sortable: true,
        },
        {
          key: 'total_sum',
          label: 'Сумма в тг',
          sortable: true,
          searchable: false,
        },
        {
          key: 'created_at',
          label: 'Дата платежа',
          sortable: true,
        },
        {
          key: 'label',
          label: 'Статус',
          sortable: true,
        },
      ]
    }
  },

  computed: {
    ...mapState({
      loading: state => state.electricity.loading,
      payment: state => state.electricity.act,
      paymentGroup: state => state.tasks.taskGroup,
      clientHistory: state => state.tasks.clientHistory,
    }),
    pdfLink() {
      return [{
        title: 'АВР',
        name: 'AVR',
        link: this.item.signed_pdf,
      }]
    },
  },

  mounted() {
    if (this.payment && this.payment.bts)
      this.$store.dispatch('electricity/getAverageConsupmtion', this.payment.bts);
  },

  methods: {
    toggle() {
      this.visible = !this.visible;
    },
    hide() {
      this.visible = false;
      this.$emit('hide');
    },
    genPdfLink(i) {
      console.log(this.paymentGroup[i])
      return [{
        title: this.paymentGroup[i].user.is_entity ? 'АВР' : 'АФИП',
        name: 'AVR',
        link: this.paymentGroup[i].application.signed_pdf,
      }]
    },
  },
}
</script>
