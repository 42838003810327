import MainApiService from "@/services/api";

const EmployeeServices = {
    getPayments() {
        return MainApiService.get('electricities/employee/archived');
    },
    // tasks endpoints
    getTasks(type) {
        return MainApiService.get(`${type}/employee/active`);
    },
    approveElectricityTask(group_id) {
        return MainApiService.post('electricities/employee/approve', { group_id });
    },
    declineElectricityTask(params) {
        return MainApiService.post('electricities/employee/decline', params);
    },
    viewElectrictyTask(id) {
        return MainApiService.post('electricities/employee/has_seen', id);
    },
    getClientHistory(params) {
        return MainApiService.get('electricities/client/history', { params });
    },

    // new treaties endpoints
    getEmployeeNewTreaties(params) {
        return MainApiService.get('new-treaties/employee/active', { params });
    },
    getNewTreatiesItem(id) {
        return MainApiService.get(`new-treaties/${id}`);
    },
    viewNewTreatiesItem(params) {
        return MainApiService.get('new-treaties/employee/has_seen', { params });
    },
    declineNewTreatiesItem(params) {
        return MainApiService.post('new-treaties/employee/decline', params);
    },
    approveNewTreatiesItem(params) {
        return MainApiService.post('new-treaties/employee/approve', params);
    },
    addListApproval(params) {
        return MainApiService.post('new-treaties/employee/add_list_approval', params);
    },
    uploadNewTreaty(params) {
        return MainApiService.post('new-treaties/employee/replaceDocument', params);
    },
    addCommentNewTreatiesItem(params) {
        return MainApiService.post('new-treaties/employee/add_comment', params);
    },
    getNewTreatiesArchive() {
        return MainApiService.get('new-treaties?archived=true');
    },

    getSiteInfo(params) {
        return MainApiService.get('new-treaties/employee/get_site', { params });
    },

    //isesf endpoints
    getIsEsfTasks() {
        return MainApiService.get('isesfs/employee/active');
    },
    getIsEsfTask(id) {
        return MainApiService.get(`isesfs/${id}`);
    },
    viewIsesfTask(id) {
        return MainApiService.post('isesfs/employee/has_seen', id);
    },
    approveIsesfTask(params) {
        return MainApiService.post('isesfs/employee/approve ', params);
    },
    declineIsesfTask(params) {
        return MainApiService.post('/isesfs/employee/decline', params)
    },
    getIsesfPayedMonths() {
        return MainApiService.get('/isesfs/client/payed_months')
    },
    getIsEsfTaskBankAcc(params) {
        return MainApiService.get(`conditions/bank_account`, { params });
    },

    //employee curator
    getCounterpartiesByEmployee(employee) {
        return MainApiService.get(`users?employee_curator=${employee}`);
    },
    getCounterparty(id) {
        return MainApiService.get(`users/${id}`);
    },
    createCounterparty(data) {
        return MainApiService.post('users', data);
    },
    updateCounterparty(data) {
        return MainApiService.put(`users/${data.id}`, data);
    },
    getBtsByCounterparty(params) {
        return MainApiService.get(`conditions/over-counterparty`, { params })
    },
    getElectricityLossesByBts(params) {
        return MainApiService.get('electricity-losses/over-bts', { params })
    },
    getActiveConditions(params) {
        return MainApiService.get('conditions/active-over-name', { params })
    },
    updateElectricityLoss(params) {
        return MainApiService.put(`electricity-losses/${params.id}`, params);
    },
    createElectricityLoss(params) {
        return MainApiService.post('electricity-losses', params);
    },
}

export default EmployeeServices;