import Vue from 'vue'
import Vuex from 'vuex'

//modules
import electricity from "@/store/modules/electricity";
import registry from "@/store/modules/registry";
import auth from "@/store/modules/auth";
import tasks from "@/store/modules/tasks";
import profile from "@/store/modules/profile";
import documentsUpload from "@/store/modules/documents-upload";
import treaties from "@/store/modules/treaties";
import isesf from "@/store/modules/isesf";
import dashboard from "@/store/modules/dashboard";
import rents from "./modules/rents";

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    isMobile: false,
  },
  mutations: {
      SET_IS_MOBILE(state, payload) {
        state.isMobile = payload;
      },
  },
  actions: {},
  modules: {
    registry,
    electricity,
    auth,
    tasks,
    profile,
    documentsUpload,
    treaties,
    isesf,
    dashboard,
    rents,
  }
})
