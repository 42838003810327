<template>
  <form
      class="du-auth__form"
      @submit.prevent="sendIin"
  >
    <div class="du-auth__form-greeting">
      Уважаемый арендодатель!
    </div>

    <div class="du-auth__form-text">
      ТОО «Мобайл Телеком-Сервис» выражает Вам свое почтение и благодарит за сотрудничество!
      <br><br>Между Компанией и Вами заключен Договор аренды на объект, который используется для установки, монтажа и технического обслуживания, базовой станции принадлежащего Арендатору.
      <br><br>В настоящее время мы проводим работы по оптимизации оборудования.
      <br><br>Для реализации данных работ нам необходимо получение технических условий, на присоединение к сетям электроснабжения.
      <br><br>В связи с вышеизложенным, просим вас предоставить копии всех правоустанавливающих документов, это требование энергопередающей компании.
    </div>

    <oda-form-group
        v-model="iin"
        label="Введите ИИН / БИН"
        placeholder="Введите ИИН / БИН"
        required
        number
        integer
    />

    <button
        class="oda-button primary"
        :disabled="loading"
    >
      Найти
    </button>
  </form>
</template>

<script>
import OdaFormGroup from "../../../../components/OdaFormGroup";

export default {
  name: "DocumnetsUploadAuthForm",
  components: {
    OdaFormGroup,
  },

  data() {
    return {
      iin: null,
      loading: false,
    }
  },

  methods: {
    async sendIin() {
      try {
        this.loading = true;
        await this.$store.dispatch('documentsUpload/authIin', this.iin);
        this.$router.push('/du/form');
      } catch (e) {
        this.$store.commit('documentsUpload/SET_CLIENT_DETAILS', null);
        this.$emit('error', e.response.data);
      } finally {
        this.loading = false;
      }
    },
  },
}
</script>