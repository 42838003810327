<template>
  <div class="step2">
    <div class="send-registry__step-header">
      {{ electricity ? 'Электроэнергия' :'Аренда' }}
    </div>

    <div class="send-registry__footer mb-4">
      <router-link
          to="/payment"
          class="oda-button bordered danger oda-desktop-only"
      >
        Отменить отправку
      </router-link>

      <router-link
          to="/m/payment"
          class="oda-button bordered danger oda-mobile-only"
      >
        Отменить отправку
      </router-link>

      <button
          :disabled="loading"
          class="oda-button primary"
          @click="$emit('submit')"
      >
        {{ contract_type && contract_type.hasRent && electricity ? 'Дальше' :'Подписать и отправить' }}
      </button>
    </div>

    <b-tabs class="oda-tabs">
      <b-tab
          v-for="(item, i) in tabs"
          :key="`oda-tab-item-${item.name}`"
          :title="item.title"
          :active="i === 0"
          :lazy="item.lazy"
          :disabled="item.disabled"
          :title-item-class="item.icon"
      >
        <div class="oda-card send-registry__card">
          <div class="send-registry__card-title oda-desktop-only">
            <div class="send-registry__card-left">
              <span class="feather icon-file"></span>
              <h3>Договор на поставку</h3>
            </div>
          </div>
          <b-row>
            <b-col cols="12" md="8" order="2" order-sm="1">
              <div class="send-registry__card-title oda-mobile-only">
                <div class="send-registry__card-left">
                  <span class="feather icon-file"></span>
                  <h3>Договор на поставку</h3>
                </div>
              </div>
              <b-row>
                <b-col
                    v-for="(field) in getInputs(item.date)"
                    v-show="!electricity ? !field.electricity: true"
                    :key="field.name"
                    :cols="field.size"
                >
                  <oda-form-group
                      :value="field.value"
                      :label="field.label"
                      disabled
                  />
                </b-col>
              </b-row>
            </b-col>
            <b-col cols="12" md="4" order="1" order-sm="2" class="mb-2">
              <payment-pdf
                  :links="getPdfLink(i)"
              />
            </b-col>
          </b-row>
        </div>
      </b-tab>
    </b-tabs>

    <div class="send-registry__footer">
      <router-link
          to="/payment"
          class="oda-button bordered danger oda-desktop-only"
      >
        Отменить отправку
      </router-link>

      <router-link
          to="/m/payment"
          class="oda-button bordered danger oda-mobile-only"
      >
        Отменить отправку
      </router-link>

      <button
          :disabled="loading"
          class="oda-button primary"
          @click="$emit('submit')"
      >
        {{ contract_type && contract_type.hasRent && electricity ? 'Дальше' :'Подписать и отправить' }}
      </button>
    </div>
  </div>
</template>

<script>
import OdaFormGroup from "@/components/OdaFormGroup";
import PaymentPdf from "../PaymentItem/Pdf";
import {mapGetters, mapState} from "vuex";

export default {
  name: "RentalForm",
  components: {
    OdaFormGroup,
    PaymentPdf,
  },
  props: {
    bts: {
      default() {
        return {}
      }
    },
    electricity: Boolean,
    loading: Boolean,
  },

  computed: {
    ...mapGetters({
      averagePaymentSum: 'registry/clientAveragePaymentSum',
    }),
    ...mapState({
      active_contract: state => state.registry.active_contract,
      rental_sum: state => state.registry.rental_sum,
      pdf_elinks: state => state.registry.pdf_elinks,
      pdf_rlinks: state => state.registry.pdf_rlinks,
      contract_type: state => state.profile.contract_type,
      losses_allowed: (state) => state.registry.losses_allowed,
      isMobile: state => state.isMobile,
    }),
    tabs() {
      const tabs = [];
      this.payment_months.forEach((m) => {
        const params = {
          name: this.getPrettyMonth(m),
          title: this.getPrettyMonth(m),
          date: m,
        };
        tabs.push(params)
      })
      return tabs;
    },
    payment_months() {
      var start = this.bts.start_dt.split('-');
      var end = this.bts.end_dt.split('-');
      var startYear = parseInt(start[0]);
      var endYear = parseInt(end[0]);
      var dates = [];

      for (var i = startYear; i <= endYear; i++) {
        var endMonth = i !== endYear ? 11 : parseInt(end[1]) - 1;
        var startMonth = i === startYear ? parseInt(start[1]) - 1 : 0;
        for (var j = startMonth; j <= endMonth; j = j > 12 ? j % 12 || 11 : j + 1) {
          var month = j + 1;
          var displayMonth = month < 10 ? '0' + month : month;
          dates.push([i, displayMonth, '01'].join('-'));
        }
      }
      return dates;
    },
    sum() {
      return (this.bts.tariff_rate * (this.bts.counter_value - this.bts.counter_previous_value)).toFixed(2);
    },
    medium_for_months() {
      if (!this.bts.end_dt) return 0;
      let months = this.bts.end_dt.split('-')[1] - this.bts.start_dt.split('-')[1];
      if (months < 0) months = (12 - this.bts.start_dt.split('-')[1]) + Number(this.bts.end_dt.split('-')[1]);
      return this.sum / (months + 1);
    },
  },

  async mounted() {
    if (this.contract_type && this.contract_type.hasRent)
      await this.$store.dispatch('registry/getRentalSum', { contract_code: this.bts.contract_code});
  },
  methods: {
    getInputs(month) {
      const { isMobile } = this;
      console.log(this.isMobile);
      const month_index = this.tabs.findIndex(m => m.date === month),
            total_months = this.tabs.length,
            kwtUsed = Number(this.bts.counter_value) - Number(this.bts.counter_previous_value),
            remainder = kwtUsed % total_months,
            base = Math.trunc(kwtUsed / total_months),
            counter_previous_value = Number(this.bts.counter_previous_value) + month_index*base,
            counter_value = counter_previous_value + base + (month_index + 1 === total_months ? remainder : 0),
            average_electricity = Number(counter_value) - Number(counter_previous_value);
      let average_sum = (Number(counter_value) - Number(counter_previous_value)) * Number(this.bts.tariff_rate);

      if (this.losses_allowed.length && this.losses_allowed[0].is_percent) {
        average_sum += Number(average_sum) * Number(this.losses_allowed[0].percent) / 100;
      }
      if (this.losses_allowed.length && this.losses_allowed[0].constant) {
        average_sum += this.losses_allowed[0].constant;
      }
      // return this.monthsCount * this.lossInfo.constant;
      return [
        {
          name: 'contract',
          size: isMobile ? 12:8,
          value: this.bts.contract,
        },
        {
          name: 'bts_name',
          size: isMobile ? 12:4,
          value: this.bts.bts_name,
        },
        {
          name: 'client',
          size: isMobile ? 12:8,
          label: 'Контрагент',
          value: this.bts.client,
        },
        {
          name: 'type',
          value: this.electricity ? 'Электроэнергия' :'Аренда',
          label: 'Вид расхода',
          size: isMobile ? 12:4,
        },
        {
          name: 'period',
          label: 'Период оплаты',
          size: isMobile ? 12:8,
          value: this.getPrettyMonth(month),
        },
        {
          name: 'nds',
          label: 'Ставка НДС',
          size: isMobile ? 12:4,
          value: 'Без НДС',
        },
        {
          name: 'counter_previous_value',
          label: 'Предыдущее показание в кВт',
          size: isMobile ? 12:4,
          value: counter_previous_value.toFixed(2),
          electricity: true,
        },
        {
          name: 'counter_value',
          label: 'Текущее показание в кВТ',
          size: isMobile ? 12:4,
          value: counter_value.toFixed(2),
          electricity: true,
        },
        {
          name: 'used_electricity',
          label: 'Потреблено в кВт',
          size: isMobile ? 12:4,
          value: average_electricity.toFixed(2),
          electricity: true,
        },
        {
          name: 'tariff_rate',
          label: 'Тариф в тг',
          size: isMobile ? 12:4,
          value: this.bts.tariff_rate,
          electricity: true,
        },
        {
          name: 'total_sum',
          label: 'Сумма в тг',
          size: isMobile ? 12:4,
          value: this.prettyPrice(this.electricity ? average_sum: this.rental_sum),
        },
        {
          name: 'medium_for_months',
          value: this.averagePaymentSum,
          label: 'Средняя сумма потребления ЭЭ',
          size: isMobile ? 12:4,
          electricity: true,
        },
      ]
    },
    getPdfLink(i) {
      if (this.electricity) {
        const link = this.pdf_elinks[i];
        if (!link) return [];
        return [{
          title: this.currentUser.is_entity ? 'АВР' : 'АФИП',
          name: 'AVR',
          link
        }]
      }

      const link = this.pdf_rlinks[i];
      return [{
        title: 'АВР',
        name: 'AVR',
        link
      }]
    }
  },
}
</script>
