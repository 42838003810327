<template>
  <section class="oda-section electricity">
    <section-header section="Реестры" />
    <oda-tabs
        :tabs="tabs"
        :active-tab="activeTab"
    />
    <router-view></router-view>
  </section>
</template>

<script>
import SectionHeader from "@/components/SectionHeader";
import OdaTabs from "@/components/OdaTabs";

export default {
  name: "Registry",
  components: {
    SectionHeader,
    OdaTabs,
  },

  data() {
    return {
      tabs: [
        {
          name: 'electricities',
          title: 'Электроэнергия',
          link: '/registry',
        },
        {
          name: 'rents',
          title: 'Аренда',
          link: '/registry/rents',
        },
        {
          name: 'isesf',
          title: 'ИС ЕСФ',
          link: '/registry/isesf',
        },
      ]
    }
  },

  computed: {
    activeTab() {
      return this.tabs.findIndex(tab => tab.link === this.$route.path);
    },
  },
}
</script>
