<template>
  <div class="oda-card task-card task-status">
    <b-row>
      <b-col>
        <div class="task-status__item">
          <div class="task-status__item-key">
            <div class="task-status__item-icon feather icon-star warning"></div>
            <div class="task-status__item-title">Статус</div>
          </div>
          <div
              v-if="status"
              class="task-status__item-value"
          >
            <div
                :class="getReadingStatusClass(status)"
                class="oda-badge"
            >
              {{ getReadingStatus(status) }}
            </div>
          </div>
        </div>
      </b-col>
      <b-col>
        <div class="task-status__item">
          <div class="task-status__item-key">
            <div class="task-status__item-icon feather icon-calendar success"></div>
            <div class="task-status__item-title">{{ dateValue ? dateValue : 'Дата получения' }}</div>
          </div>
          <div class="task-status__item-value">
            {{ getDateFormat(date) }}
          </div>
        </div>
      </b-col>
    </b-row>
    <b-row v-if="decline_message">
      <b-col class="mt-4">
        <oda-form-group
            :value="decline_message"
            label="Причина отклонения"
            disabled
            textarea
        />
        <button
            class="oda-button primary"
            @click="$emit('resend')"
        >
          Переотправить показания счетчика
        </button>
      </b-col>
    </b-row>
  </div>
</template>

<script>
import OdaFormGroup from "../OdaFormGroup";

export default {
  name: "PaymentStatus",
  props: {
    status: String,
    date: [String, Date],
    dateValue: String,
    decline_message: String,
  },
  components: {
    OdaFormGroup,
  },
  methods: {},
}
</script>
