<template>
  <section class="oda-section">
    <section-header
        :breadcrumb="breadcrumb"
        section="Запрос"
        back-link="/docs-update"
    />

    <b-row>
      <b-col cols="3">
        <div class="oda-card du-item__client">
          <div class="du-item__client-header">Данные контрагнета</div>
          <div class="du-item__client-item">
            <div>
              <span class="feather icon-briefcase"></span>
              Контрагент
            </div>
            <div>
              {{ info && info.counterparty }}
            </div>
          </div>
          <div class="du-item__client-item">
            <div>
              <span class="feather icon-users"></span>
              ИИН / БИН
            </div>
            <div>
              {{ info && info.iin }}
            </div>
          </div>
        </div>
      </b-col>

      <b-col cols="9">
        <div class="oda-card">
          <form class="du-form" @submit.prevent>
            <div class="du-form__header">
              <div class="du-form__header-title">
                Запрос на обновление документов от {{ formTitle }}
              </div>
              <button
                  class="oda-button bordered primary du-form__header-button feather icon-download"
              ></button>
            </div>

            <b-row
                v-for="(input, i) in filteredInputs"
                :key="`form-input__${i}`"
            >
              <b-col cols="12">
                <div class="du-form__input-header">
                  {{ ++i }}
                  {{ input.title }}
                  <span v-if="input.description">
                {{ input.description }}
              </span>
                </div>
              </b-col>
              <b-col
                  v-if="form[input.name] && form[input.name][0]"
                  cols="12"
                  class="mb-3"
              >
                <b-row class="align-items-center">
                  <div
                      v-for="uploaded in form[input.name]"
                      :key="uploaded.id"
                  >
                    <div class="oda-card du-form__image-card">
                      <embed
                          v-if="uploaded.ext === '.pdf'"
                          :src="uploaded.url"
                          width="100%"
                          height="100%"
                          @click="openPhoto(uploaded.url)"
                      >
                      <img
                          v-else
                          :src="uploaded.url"
                          :alt="`uploaded-${uploaded.id}`"
                      >
                      <button
                          :disabled="loading"
                          class="du-form__image-card-delete oda-button primary feather icon-x"
                          @click="deletePhoto(uploaded.id, input.name)"
                      >
                      </button>
                      <button
                          :disabled="loading"
                          class="du-form__image-card-zoom oda-button primary feather icon-search"
                          @click="openPhoto(uploaded.url)"
                      >
                      </button>
                    </div>
                  </div>
                  <div
                      v-if="!isBuilder && !info.approved_by_employee"
                      :class="{ loading }"
                      class="du-form__image-add feather icon-plus"
                      @click="addMore(input.name)"
                  ></div>
                </b-row>
              </b-col>
              <b-col
                  v-if="form[input.name] && !form[input.name][0]"
                  cols="10"
              >
                <div class="du-form__input">
                  <oda-form-group
                      :ref="`file-upload-${input.name}`"
                      :disabled="loading || isBuilder || info.approved_by_employee"
                      placeholder="Прикрепить файл"
                      class="file-ecp"
                      file
                      accept="image/*, .pdf"
                      @input="file => sendFiles(file, input.name)"
                  />
                </div>
              </b-col>

              <b-col cols="12">
                <div class="du-form__add-button-empty"></div>
              </b-col>
            </b-row>
          </form>
        </div>
        <button
            v-if="info && !info.approved_by_employee"
            :disabled="loading"
            class="oda-button success mt-3"
            @click="approveSubmission"
        >
          Проверено
        </button>
        <button
            v-if="info && info.approved_by_employee"
            :disabled="loading"
            class="oda-button success mt-3"
            @click="declineSubmission"
        >
          Отменить проверку
        </button>
      </b-col>
    </b-row>
    <input
        style="display: none"
        type="file"
        ref="add-more-input"
        @input="addMoreSendFile"
    >
  </section>
</template>

<script>
import { mapState } from 'vuex';
import SectionHeader from '@/components/SectionHeader.vue';
import OdaFormGroup from '@/components/OdaFormGroup.vue';
import imageCompression from 'browser-image-compression';

export default {
  name: 'DocumentsUpdateItem',
  components: {
    SectionHeader,
    OdaFormGroup,
  },

  data() {
    return {
      form: {},
      loading: false,
      breadcrumb: [
        {
          name: 'Обновление документов',
          link: '/docs-update',
        },
        {
          name: 'Запрос',
        },
      ],
      types: [
        {
          name: 'phys',
          description: 'Физического лица',
          items: ['ИП', 'КХ', 'Физ.лицо', 'физ лицо'],
        },
        {
          name: 'legal',
          description: 'Юридического лица',
          items: ['ПТ', 'АО', 'КГУ', 'НАО', 'ОО', 'ПК', 'РГП', 'ТОО', 'Учреждение'],
        },
        {
          name: 'ksk',
          description: 'КСК/ОСИ',
          items: ['Кондоминиум', 'КСК', 'КСП', 'ОСИ'],
        },
      ],
      inputs: [
        {
          name: 'passport',
          title: 'Удостоверение личности',
          description: 'в хорошем качестве, с двух сторон',
          canIncrease: true,
          size: 5,
          amount: 2,
          type: ['phys', 'legal', 'ksk'],
        },
        {
          name: 'residents_meeting_minutes',
          title: 'Протокол собрания жильцов',
          description: 'в хорошем качестве, с двух сторон',
          canIncrease: true,
          size: 10,
          amount: 1,
          type: ['ksk'],
        },
        {
          name: 'gov_act',
          title: 'Гос Акт',
          description: 'в хорошем качестве, с двух сторон',
          canIncrease: true,
          size: 5,
          amount: 2,
          type: ['phys', 'legal'],
        },
        {
          name: 'tech_passport',
          title: 'Техплан/техпаспорт объекта',
          description: 'в хорошем качестве',
          canIncrease: true,
          size: 10,
          amount: 1,
          type: ['ksk'],
        },
        {
          name: 'contract_of_sale',
          title: 'Договор купли продажи либо другой документ на основании которого выдан Госакт',
          description: '',
          canIncrease: true,
          size: 10,
          amount: 1,
          type: ['phys', 'legal'],
        },
        {
          name: 'help_encumbrances',
          title: 'Справка Обременения',
          description: 'свежая, должна быть не более месячной давности, при условии, что месяц должен быть текущий (в ноябре за ноябрь, в декабре за декабрь итд.).',
          canIncrease: true,
          size: 10,
          amount: 1,
          type: ['phys', 'legal'],
        },
        {
          name: 'director_say_so',
          title: 'Приказ на директора',
          description: '',
          canIncrease: true,
          size: 10,
          amount: 1,
          type: ['legal'],
        },
        {
          name: 'state_registration_certificate',
          title: 'Свидетельство о гос. Регистрации',
          description: '',
          canIncrease: true,
          size: 10,
          amount: 1,
          type: ['legal'],
        },
        {
          name: 'lease_contract',
          title: 'Договор аренды',
          description: 'свежую или ДС на продление',
          canIncrease: true,
          size: 10,
          amount: 1,
          type: ['legal', 'ksk'],
        },
      ],
    };
  },

  computed: {
    ...mapState({
      info: (state) => state.documentsUpload.doc_submission,
    }),
    type() {
      const type = this.types.filter((x) => x.items.includes((this.info || {}).type));
      return type[0] && type[0].name;
    },
    filteredInputs() {
      return this.inputs.filter((input) => input.type.includes(this.type));
    },
    formTitle() {
      const type = this.types.filter((x) => x.items.includes((this.info || {}).type));
      return type[0] && type[0].description;
    },
    isBuilder() {
      return this.userRole === 'employee_builder';
    },
  },

  async mounted() {
    const { id } = this.$route.params;
    await this.$store.dispatch('documentsUpload/getDocSubmission', id);
    const filtered = this.inputs.filter((x) => x.type.includes(this.type));
    filtered.forEach((f) => {
      if (this.info[f.name] && this.info[f.name].length) {
        this.form[f.name] = this.info[f.name];
        return;
      }
      const value = [];
      for (let i = 0; i < f.amount; i += 1) {
        value.push(null);
      }
      this.form[f.name] = value;
    });
    this.reRenderForm();
  },

  methods: {
    async deletePhoto(id, apptype) {
      const params = {
        id,
        apptype,
        iin: this.info.iin,
      };
      try {
        this.loading = true;
        await this.$store.dispatch('documentsUpload/deatachFile', params);
        this.$set(this.form, apptype, this.info[apptype]);
        this.reRenderForm();
      } catch (e) {
        const message = e.response.data;
        this.toastNotification('error', message);
      } finally {
        this.loading = false;
      }
    },

    async sendFiles(file, apptype) {
      if (!file) return;
      const formData = new FormData();
      const compressedItem = await this.compress(file);
      formData.append('applications', compressedItem);
      formData.append('iin', this.info.iin);
      const payload = {
        type: apptype,
        formData,
      };

      try {
        this.loading = true;
        await this.$store.dispatch('documentsUpload/sendFiles', payload);
        this.$set(this.form, apptype, this.info[apptype]);
        this.reRenderForm();
        // this.clearInput(apptype);
      } catch (e) {
        console.error(e);
      } finally {
        this.loading = false;
      }
    },

    async compress(image) {
      try {
        const options = {
          maxSizeMB: 1,
          maxWidthOrHeight: 1920,
          useWebWorker: true,
        };
        if (image.type === 'application/pdf') return image;
        const compressedFile = await imageCompression(image, options);
        console.log(
          'compressedFile instanceof Blob',
          compressedFile instanceof Blob,
        ); // true
        // eslint-disable-next-line
        console.log(`compressedFile size ${compressedFile.size / 1024 / 1024} MB`); // smaller than maxSizeMB
        return compressedFile;
      } catch (error) {
        console.log('Error while compressing');
        console.log(error);
        return image;
      }
    },
    async approveSubmission() {
      if (this.isBuilder) {
        await this.approveByBuilder(this.info);
        return;
      }
      try {
        this.loading = true;
        await this.$store.dispatch('documentsUpload/approveSubmission', this.info.iin);
        this.toastNotification('success', 'Успешно проверено');
      } catch (e) {
        console.error(e);
        const message = e.response.data;
        this.toastNotification('error', message);
      } finally {
        this.loading = false;
      }
    },

    async declineSubmission() {
      try {
        this.loading = true;
        if (this.isBuilder) await this.$store.dispatch('documentsUpload/rollbackSubmissionBuilder', this.info.iin);
        else await this.$store.dispatch('documentsUpload/rollbackSubmissionEmployee', this.info.iin);
      } catch (e) {
        const message = e.response.data;
        this.toastNotification('error', message);
      } finally {
        this.loading = false;
      }
    },

    async approveByBuilder({ id, iin }) {
      try {
        this.loading = true;
        await this.$store.dispatch('documentsUpload/builderApprove', { id, iin });
        this.toastNotification('success', 'Успешно проверено');
      } catch (e) {
        console.error(e);
        const message = e.response.data;
        this.toastNotification('error', message);
      } finally {
        this.loading = false;
      }
    },

    reRenderForm() {
      const { inputs } = this;
      inputs.push({});
      inputs.pop();
      this.$set(this, 'inputs', inputs);
    },
    clearInput(apptype) {
      if (!this.$refs[`file-upload-${apptype}`]) return;
      this.$refs[`file-upload-${apptype}`][0].removeFile();
    },
    openPhoto(url) {
      window.open(url);
    },
    downloadFile(link) {
      this.$axios({
        method: 'get',
        url: link,
        responseType: 'arraybuffer',
      }).then((response) => {
        this.forceFileDownload(response);
      }).catch(() => console.log('error occured'));
    },
    forceFileDownload(response) {
      const url = window.URL.createObjectURL(new Blob([response.data]));
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute('download', 'file.png'); // or any other extension
      document.body.appendChild(link);
      link.click();
    },
    addMore(ref) {
      if (this.loading) return;
      this.addMoreInput = ref;
      this.$refs['add-more-input'].click();
    },
    async addMoreSendFile(e) {
      const file = e.target.files[0];
      try {
        await this.sendFiles(file, this.addMoreInput);
        this.addMoreInput = '';
      } catch (err) {
        console.error(err);
      }
    },
  },
};
</script>
